import React, { useState } from 'react';
import { KeyedMutator } from 'swr';
import { datadogRum } from '@datadog/browser-rum';
import Box from '@mui/system/Box';
import Typography from '@mui/material/Typography';
import SaveButtonGroup from './SaveButtonGroup';
import usePopup from 'hooks/usePopup';
import type { Device, Devices } from '../index.page';
import { createDevice, updateDevice } from '../services/devices';
import TableWithCustomCell from './TableWithCustomCell';
import generateDeviceRows from '../utils/generateDeviceRows';
import { makeRequestBodyForCreate, makeRequestBodyForUpdate, validateDeviceForm } from '../utils';
import type { Option } from './SelectBase';
import { useRecoilValue } from 'recoil';
import { warehouseAtom } from '../../../../../store/outbound/warehouse.recoil';
import { AxiosError } from 'axios';

const modalWidth = 480;
export const defaultDeviceFormId = -1;

type ModalForm =
  | 'id'
  | 'warehouseId'
  | 'deviceType'
  | 'jobType'
  | 'registrationNumber'
  | 'isDeviceAvailable'
  | 'isForInventoryInvestigation'
  | 'deviceNumber';

export type DeviceForm = Pick<Device, ModalForm>;

export const defaultDeviceForm: DeviceForm = {
  id: defaultDeviceFormId,
  warehouseId: 0,
  deviceType: 'ALL',
  jobType: 'OUTBOUND',
  registrationNumber: '',
  isDeviceAvailable: false,
  isForInventoryInvestigation: false,
  deviceNumber: '',
};

type DevicesFormProps = {
  selectedDevice: DeviceForm | undefined;
  mutate: KeyedMutator<Devices>;
  onClose: () => void;
  options: Record<string, Option[]>;
};

const createdCode = 201;
// const updatedCode = 200;

const DevicesForm = (props: DevicesFormProps) => {
  const { selectedDevice = defaultDeviceForm, mutate, onClose, options } = props;
  const { showErrorDialog, showSnackbar } = usePopup();
  const globalWarehouse = useRecoilValue(warehouseAtom);
  const [device, setDevice] = useState<DeviceForm>(selectedDevice);
  const deviceRows = generateDeviceRows(device, options);
  const isCreateMode = device.id === defaultDeviceForm.id;

  const handleCreate = async () => {
    const validationMessage = validateDeviceForm(device);
    if (validationMessage) {
      showErrorDialog({
        title: '작업기기 등록 실패',
        errorMessage: validationMessage,
        buttons: [
          {
            text: '확인',
          },
        ],
      });
      return;
    }

    const requestBody = {
      ...makeRequestBodyForCreate(device),
      warehouseId: Number(globalWarehouse),
    };

    try {
      const { status } = await createDevice(requestBody);
      if (status === createdCode) {
        showSnackbar({ message: '작업기기 정보가 생성 완료되었습니다.', severity: 'success' });
        await mutate();
        onClose();
        datadogRum.addAction(`작업기기 등록 성공: ${device}`);
      }
    } catch (error) {
      const axiosError = error as AxiosError<string>;
      const errorMessage = axiosError?.response?.data ?? '';
      datadogRum.addError(`작업기기 등록 실패: ${errorMessage}`);
      showErrorDialog({
        title: '작업기기 등록 실패',
        errorMessage,
        buttons: [
          {
            text: '확인',
          },
        ],
      });
    }
  };

  const handleUpdate = async () => {
    const validationMessage = validateDeviceForm(device);
    if (validationMessage) {
      showErrorDialog({
        title: '작업기기 수정 실패',
        errorMessage: validationMessage,
        buttons: [
          {
            text: '확인',
          },
        ],
      });
      return;
    }

    const requestBody = makeRequestBodyForUpdate(device);
    const response = await updateDevice(device.id, requestBody);
    if (response?.status === 200) {
      showSnackbar({ message: '작업기기 정보가 수정 완료되었습니다.', severity: 'success' });
      await mutate();
      onClose();
      datadogRum.addAction(`작업기기 수정 성공: ${device}`);
    } else {
      showErrorDialog({
        title: '작업기기 수정 실패',
        errorMessage: response?.data?.errorMessage,
        buttons: [{ text: '확인' }],
      });
    }
  };

  return (
    <Box width={modalWidth}>
      <Box>
        <Typography sx={styles.modalSubTitle}>작업기기 기본정보</Typography>
      </Box>
      <TableWithCustomCell rows={deviceRows.basics} setDevice={setDevice} />
      <Box sx={{ mt: 4 }}>
        <Typography sx={styles.modalSubTitle}>작업기기 상세정보</Typography>
      </Box>
      <TableWithCustomCell rows={deviceRows.details} setDevice={setDevice} />
      <SaveButtonGroup
        onSaveClick={isCreateMode ? handleCreate : handleUpdate}
        onCloseClick={onClose}
      />
    </Box>
  );
};

export default DevicesForm;

const styles = {
  modalSubTitle: { fontSize: '20px', mb: 1.5 },
};
