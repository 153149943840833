import { useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { datadogRum } from '@datadog/browser-rum';
import type { AxiosError } from 'axios';

import { isFetchingPackingAtom, stepAtom } from 'store/outbound/packing.recoil';
import { inspectionAtom, prevInspectionAtom } from 'store/outbound/inspection.recoil';
import fetcher, { axiosV2 } from 'libs/common/fetcher';
import { INSPECTION } from 'consts/outbound/messages';
import { afterProgressTitle, inProgressTitle } from 'styles/timelineItemTitle';
import { convertCarrierId } from '../../../../../libs/common/convertCarrier';
import { carriersAtom } from '../../../../../store/outbound/carrier.recoil';

import BarcodeInput from '../../components/BarcodeInput';
import TimelineItem from '../../components/TimelineItem';
import Table, { TableCell, TableRow } from '../../../components/Table';
import useBarcode from '../../hooks/useBarcode';

const firstStep = 0;

const ToteCodeLine = () => {
  const [inspection, setInspection] = useRecoilState(inspectionAtom);
  const [prevInspection, setPrevInspection] = useRecoilState(prevInspectionAtom);
  const [selectedStep, setSelectedStep] = useRecoilState(stepAtom);
  const [isLoading, setIsLoading] = useRecoilState(isFetchingPackingAtom);
  const { barcode, setBarcode, isBarcodeValid, handleError } = useBarcode();
  const carriers = useRecoilValue(carriersAtom);

  useEffect(() => {
    if (selectedStep === firstStep) {
      setBarcode('');
    }
  }, [selectedStep, setBarcode]);

  const handleScanBarcode = async () => {
    if (!isBarcodeValid()) {
      return;
    }

    datadogRum.addAction(`Scanned Tote Barcode: ${barcode}`, { barcode });
    await handleFetchInspectionInfo();
  };

  const handleFetchInspectionInfo = async () => {
    setIsLoading(true);
    try {
      const inspection = await fetcher(`/inspections/${barcode}`);
      await axiosV2.post(`/inspections/${barcode}`);
      setInspection(inspection);
      setPrevInspection(inspection);
      setSelectedStep(step => step + 1);
    } catch (error) {
      handleError(error as AxiosError);
    } finally {
      setIsLoading(false);
    }
  };

  const makeLabelSx = (length: number) => {
    if (length < 3) {
      return { height: '56px', fontSize: '16px' };
    }

    return { height: `${56 + length * (8 + length)}px`, fontSize: '16px' };
  };

  const makeTableRowHeight = (length: number) => {
    if (length < 3) {
      return '56px';
    }
    return `${56 + length * (8 + length)}px`;
  };

  return (
    <>
      <TimelineItem
        step={0}
        inProgressComponent={
          <>
            <Typography sx={inProgressTitle}>{INSPECTION.scanTote}</Typography>
            <BarcodeInput
              value={barcode}
              onSubmit={handleScanBarcode}
              onChange={e => setBarcode(e.target.value)}
              label="바코드"
              isLoading={isLoading}
            />
          </>
        }
        afterProgressComponent={
          <>
            <Box sx={styles.titleContainer}>
              <Typography sx={afterProgressTitle}>{INSPECTION.scanTote}</Typography>
            </Box>
            <Table>
              <TableRow height={makeTableRowHeight(inspection?.toteBarcodes?.length)}>
                <TableCell
                  label="토트"
                  labelSx={makeLabelSx(inspection?.toteBarcodes?.length)}
                  value={inspection?.toteBarcodes.map((barcode, index) => (
                    <Box key={index} sx={styles.barcode}>
                      {barcode}
                    </Box>
                  ))}
                />
                <TableCell
                  labelSx={makeLabelSx(inspection?.toteBarcodes?.length)}
                  label="출고ID"
                  value={inspection?.shippingSerialNumber}
                />
              </TableRow>
              <TableRow height={makeTableRowHeight(inspection?.toteBarcodes?.length)}>
                <TableCell
                  label="배송방식"
                  labelSx={makeLabelSx(inspection?.toteBarcodes?.length)}
                  value={convertCarrierId(inspection?.carrierId, carriers)}
                />
                <TableCell
                  labelSx={makeLabelSx(inspection?.toteBarcodes?.length)}
                  label="최대무게제한(g)"
                  value={inspection?.maxWeight?.toLocaleString()}
                />
              </TableRow>
              <TableRow height={makeTableRowHeight(inspection?.toteBarcodes?.length)}>
                <TableCell
                  label="작업시지서"
                  labelSx={makeLabelSx(inspection?.toteBarcodes?.length)}
                  value={inspection?.adminComment}
                />
              </TableRow>
            </Table>
          </>
        }
      />
    </>
  );
};

export default ToteCodeLine;

const styles = {
  titleContainer: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' },
  button: { height: '40px', fontSize: '17px' },
  barcode: { fontSize: '16px' },
  abcd: { height: '200px' },
};
