import _ from 'lodash';
import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import useSWR from 'swr';
import { CustomOrders } from '../../consts/outbound/optionOrder';
import fetcher from '../../libs/common/fetcher';
import {
  Carrier,
  CarrierOption,
  carrierOptionsAtom,
  carriersAtom,
} from '../../store/outbound/carrier.recoil';
import {
  RefCode,
  RefCodeOptions,
  refCodeOptionsAtom,
  RefCodeOptionsByField,
} from '../../store/outbound/refCode.recoil';
import { allWarehouseOptionsAtom } from '../../store/outbound/warehouse.recoil';
import { PackingBox, packingBoxOptionsAtom } from '../../store/outbound/packings.recoil';
import {
  Stocktaking,
  StocktakingOption,
  stocktakingOptionsAtom,
  stocktakingOptionsWithOutAppliedAtom,
} from '../../store/outbound/stocktaking.recoil';
import { useCenter } from 'queries/useCenter';
import { useSupplier } from 'queries/useSupplier';

type Warehouse = {
  id: number;
  code: string;
  fullCode: string;
  name: string;
  isReflectInventory: boolean;
};

const createWarehouseFullCodeOptions = (warehouses: Warehouse[] | undefined) => {
  const warehousesCodes = getValidResponse(warehouses);
  return warehousesCodes.map((v: Warehouse) => ({
    displayName: v.name,
    id: v.id,
    value: v.id,
    fullCode: v.fullCode,
    isReflectInventory: v.isReflectInventory,
  }));
};

const createStocktakingOptions = (stocktakings: Stocktaking[] | undefined): StocktakingOption[] => {
  const stocktakingOptions = getValidResponse(stocktakings);
  return stocktakingOptions
    .map((v: Stocktaking) => ({
      displayName: v.name,
      value: v.id,
      id: v.id,
    }))
    .sort((a: StocktakingOption, b: StocktakingOption) => b.id - a.id);
};

const createStocktakingOptionsWithOutApplied = (
  stocktakings: Stocktaking[] | undefined
): StocktakingOption[] => {
  const stocktakingOptions = getValidResponse(stocktakings);
  return stocktakingOptions
    .filter((v: Stocktaking) => v.status !== 'APPLIED')
    .map((v: Stocktaking) => ({
      displayName: v.name,
      value: v.id,
      id: v.id,
    }))
    .sort((a: StocktakingOption, b: StocktakingOption) => b.id - a.id);
};

const createCarrierOptions = (carriers: Carrier[] | undefined) => {
  const validCarriers = getValidResponse(carriers);
  const carrierOptions = validCarriers
    ?.map(
      (carrier: Carrier) =>
        ({
          displayName: carrier.name,
          value: carrier.id.toString(),
          order: carrier.displayOrder,
        } as CarrierOption)
    )
    .sort((a: CarrierOption, b: CarrierOption) => a.order - b.order);

  return { carrierOptions };
};

const createPackingBoxOptions = (packingBoxes: PackingBox[]) => {
  const validPackingBoxes = getValidResponse(packingBoxes);
  const packingBoxOptions = validPackingBoxes.map((packingBox: PackingBox) => ({
    displayName: packingBox.pGoodsName,
    value: packingBox.pGoodsName,
    order: 1,
  }));

  return { packingBoxOptions };
};

const createRefCodes = (refCodesData: RefCode[] | undefined) => {
  const validRefCodesData = getValidResponse(refCodesData);
  const refCodeOptions = validRefCodesData.reduce((acc: RefCodeOptionsByField, curr: RefCode) => {
    const { groupCode } = curr;
    const key: string = groupCode.charAt(0).toLowerCase() + groupCode.slice(1);
    const value: RefCodeOptions = {
      displayName: curr.value,
      value: curr.code,
    };
    acc[key] = (acc[key] || []).concat(value);
    return acc;
  }, {});

  Object.entries(refCodeOptions).forEach(([key, value]) => {
    if (key in CustomOrders && Array.isArray(CustomOrders[key])) {
      refCodeOptions[key] = value.sort(
        (a: RefCodeOptions, b: RefCodeOptions) =>
          CustomOrders[key].indexOf(a.value) - CustomOrders[key].indexOf(b.value)
      );
    } else {
      const sort = CustomOrders[key] ? CustomOrders[key] : 'asc';
      refCodeOptions[key] = _.orderBy(value, ['displayName'], [sort]);
    }
  });
  return refCodeOptions;
};

const InitGmsData = () => {
  const setWarehouses = useSetRecoilState(allWarehouseOptionsAtom);
  const setStocktakings = useSetRecoilState(stocktakingOptionsAtom);
  const setCarriers = useSetRecoilState(carriersAtom);
  const setCarrierOptions = useSetRecoilState(carrierOptionsAtom);
  const setPackingBoxOptions = useSetRecoilState(packingBoxOptionsAtom);
  const setRefCodeOptions = useSetRecoilState(refCodeOptionsAtom);
  const setStocktakingsWithOutApplied = useSetRecoilState(stocktakingOptionsWithOutAppliedAtom);

  const { data: warehouses } = useSWR<Warehouse[]>([`/warehouses`, { sort: 'id,desc' }], fetcher);
  const { data: carriers } = useSWR([`/carriers`, { sort: 'id,desc' }], fetcher);
  const { data: packingBoxes } = useSWR([`/packings/boxes`], fetcher);
  const { data: refCodes } = useSWR<RefCode[]>(['/commons/ref-codes'], fetcher);
  const { data: stocktakings } = useSWR<[]>(
    [`/inventories/stocktakings`, { sort: 'id,desc' }],
    fetcher
  );
  useCenter();
  useSupplier();

  const stocktakingOptionsWithOutApplied = createStocktakingOptionsWithOutApplied(stocktakings);
  const { carrierOptions } = createCarrierOptions(carriers);
  const { packingBoxOptions } = createPackingBoxOptions(packingBoxes);
  const stocktakingOptions = createStocktakingOptions(stocktakings);
  const warehouseFullCodeOptions = createWarehouseFullCodeOptions(warehouses);
  const refCodeOptions = createRefCodes(refCodes);

  useEffect(() => {
    setWarehouses(warehouseFullCodeOptions);
    setStocktakings(stocktakingOptions);
    setStocktakingsWithOutApplied(stocktakingOptionsWithOutApplied);
    setCarriers(carriers);
    setCarrierOptions(carrierOptions);
    setPackingBoxOptions(packingBoxOptions);
    setRefCodeOptions(refCodeOptions);
  }, [
    warehouseFullCodeOptions,
    stocktakingOptions,
    carriers,
    carrierOptions,
    packingBoxOptions,
    refCodeOptions,
  ]);

  return <></>;
};

export default InitGmsData;

function getValidResponse<T>(data: T[] | undefined): T[] {
  return data !== undefined && Array.isArray(data) ? data : [];
}
