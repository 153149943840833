import { useState } from 'react';
import { KeyedMutator } from 'swr';
import { datadogRum } from '@datadog/browser-rum';
import Box from '@mui/system/Box';
import Typography from '@mui/material/Typography';
import usePopup from 'hooks/usePopup';
import TableWithCustomCell from './TableWithCustomCell';
import generateDeviceRows from '../utils/generateDeviceRows';
import { makeRequestBodyForUpdate } from '../utils';
import { DeviceResponse, Devices } from '../../../outbound/devices-works/index.page';
import SaveButtonGroup from '../../../info/devices/components/SaveButtonGroup';
import { updateDevice } from '../services/devices';

const modalWidth = 480;
export const defaultDeviceFormId = -1;

type ModalForm =
  | 'id'
  | 'deviceNumber'
  | 'deviceType'
  | 'workType'
  | 'isWorkAvailable'
  | 'isWorkAssigned'
  | 'warehouseId';

export type DeviceForm = Pick<Devices, ModalForm>;

export const defaultDeviceForm: DeviceForm = {
  id: defaultDeviceFormId,
  deviceNumber: '',
  deviceType: '',
  workType: '',
  isWorkAvailable: false,
  isWorkAssigned: false,
  warehouseId: '',
};

type DevicesFormProps = {
  selectedDevice: DeviceForm | undefined;
  mutate: KeyedMutator<DeviceResponse>;
  onClose: () => void;
};

const updatedCode = 200;

const DevicesForm = (props: DevicesFormProps) => {
  const { selectedDevice = defaultDeviceForm, mutate, onClose } = props;
  const { showErrorDialog, showSnackbar } = usePopup();
  const [device, setDevice] = useState<DeviceForm>(selectedDevice);
  const deviceRows = generateDeviceRows(device);
  const handleUpdate = async () => {
    const requestBody = makeRequestBodyForUpdate(device);
    const response = await updateDevice(device.id, requestBody);
    if (response?.status === updatedCode) {
      showSnackbar({ message: '작업기기 정보가 수정 완료되었습니다.', severity: 'success' });
      await mutate();
      onClose();
      datadogRum.addAction(`작업기기 수정 성공: ${device}`);
    } else {
      datadogRum.addError(`작업기기 수정 실패: ${response?.data}`);
      showErrorDialog({
        title: '작업기기 수정 실패',
        errorMessage: response?.data?.errorMessage,
        buttons: [
          {
            text: '확인',
          },
        ],
      });
    }
  };

  return (
    <Box width={modalWidth}>
      <Box>
        <Typography sx={styles.modalSubTitle}>작업기기 기본정보</Typography>
      </Box>
      <TableWithCustomCell rows={deviceRows.basics} setDevice={setDevice} />
      <Box sx={{ mt: 4 }}>
        <Typography sx={styles.modalSubTitle}>작업기기 상세정보</Typography>
      </Box>
      <TableWithCustomCell rows={deviceRows.details} setDevice={setDevice} />
      <SaveButtonGroup onSaveClick={handleUpdate} onCloseClick={onClose} />
    </Box>
  );
};

export default DevicesForm;

const styles = {
  modalSubTitle: { fontSize: '20px', mb: 1.5 },
};
