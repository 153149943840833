import { Dispatch, SetStateAction } from 'react';
import Box from '@mui/system/Box';
import AutoContainImage from 'components/common/Image/AutoContainImage';
import { GiftDetails } from '../../ItemListLine';
import { separateArrow } from '../../ItemListLine/utils';
import type { Column, Value } from '../../../../components/ListTable';
import { getFinalUrl } from '../../../../../../../consts/outbound/imageUrl';
import ImageZoomModal from '../../../../../../../components/ImageZoomModal';

export const makeColumns = (
  selectedImageUrl: string,
  setSelectedImageUrl: Dispatch<SetStateAction<string>>
) =>
  [
    {
      headerName: '바코드',
      field: 'barcode',
      width: 80,
    },
    {
      headerName: '아이템코드',
      field: 'itemCode',
      width: 100,
    },
    {
      headerName: '이미지',
      field: 'imageUrl',
      width: 80,
      renderCell: (url: Value) => {
        if (!url) {
          return;
        }
        const finalUrl = getFinalUrl(url as string);

        return (
          <>
            <Box
              sx={{ display: 'flex', alignItems: 'center', padding: 0.5, justifyContent: 'center' }}
              onClick={() => setSelectedImageUrl(finalUrl.toString())}
            >
              <AutoContainImage src={finalUrl.toString()} imageContainerSize={50} />
            </Box>
            <ImageZoomModal imageUrl={selectedImageUrl} onClose={() => setSelectedImageUrl('')} />
          </>
        );
      },
    },
    {
      headerName: '아이템명',
      field: 'itemName',
      width: 380,
      renderCell: (itemName: Value) => {
        if (!itemName) {
          return;
        }

        const [item, remark] = (itemName as string).split(separateArrow);

        return (
          <p style={{ textAlign: 'start' }}>
            <span>{item}</span>
            <span style={{ color: 'red' }}>{remark ? separateArrow + remark : ''}</span>
          </p>
        );
      },
    },
    {
      headerName: '아이템수량',
      field: 'orderQuantity',
      width: 100,
    },
  ] as Column[];

export const message = '다음 아이템은 수량자동입력으로 진행됩니다.';

export const getTotalRemainingQuantity = (giftDetails: GiftDetails) => {
  return giftDetails.items.reduce((total, preValue) => total + preValue.remainingQuantity, 0);
};
