import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import useSWR from 'swr';
import InfoListSection from 'components/common/typography/InfoListSection';
import TightDataGridPro from 'components/common/TightDataGridPro';
import { ROWS_PER_PAGE_OPTIONS } from 'consts/common/dataGrid';
import { DEFAULT_PAGE, DEFAULT_SIZE } from 'consts/common/pageAndSize';
import gridColumns from './gridColDef';
import fetcher from 'libs/common/fetcher';
import convertWarehouseFullCode from '../../../../../../libs/warehouse/convertWarehouseFullCode';
import { useRecoilValue } from 'recoil';
import { warehouseOptionsAtom } from '../../../../../../store/outbound/warehouse.recoil';
import { ShippingInShippingOrder, type SummarizedShippingOrder } from 'types/outbound';
import { makeRowIndex } from '../../../../../../libs/common/makeRowIndex';

type Props = {
  shippingOrder: SummarizedShippingOrder;
  selectedShippingOrderId: number;
};

type Shippings = ShippingInShippingOrder & {
  shippingSerialNumber: string;
  isStock: boolean;
  packingErrorCode: string;
  trackingNumber: string;
};

type ShippingResponse = {
  content: Shippings[];
  totalElements: number;
  size: number;
  number: number;
};

type SearchQuery = {
  page?: number;
  size?: number;
  shippingOrderId?: number;
};
const ShippingDataGrid = ({ selectedShippingOrderId, shippingOrder }: Props) => {
  const [queryParams, setQueryParams] = useState<SearchQuery>({
    page: DEFAULT_PAGE,
    size: DEFAULT_SIZE,
  });
  const { data, error } = useSWR<ShippingResponse>(
    [`/shippings`, { ...queryParams, shippingOrderId: selectedShippingOrderId, sort: 'id,desc' }],
    fetcher
  );
  const warehouseOption = useRecoilValue(warehouseOptionsAtom);
  const warehouseName = convertWarehouseFullCode(
    shippingOrder?.warehouseId as number,
    warehouseOption
  );
  const infoList = [
    { name: '창고', value: warehouseName[0] },
    { name: '출고지시ID', value: shippingOrder.serialNumber },
  ];

  return (
    <>
      <Box>
        <Typography variant="h6" sx={{ color: 'text.primary' }}>
          출고 목록
        </Typography>
      </Box>
      <InfoListSection infoList={infoList} hideValue={!data || error} />
      <Box sx={{ height: '460px' }}>
        <TightDataGridPro
          rows={data?.content ? makeRowIndex(data) : []}
          paginationMode="server"
          rowCount={data?.totalElements ?? 0}
          columns={gridColumns()}
          loading={!data && !error}
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          onPageChange={page => setQueryParams(params => ({ ...params, page }))}
          pageSize={queryParams.size ?? DEFAULT_SIZE}
          onPageSizeChange={size => setQueryParams(params => ({ ...params, size }))}
        />
      </Box>
    </>
  );
};

export default ShippingDataGrid;
