import { useRecoilValue } from 'recoil';
import type { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { numberFormatter } from '../../../../libs/common/unitConverters';
import refCodeOptionsAtom from '../../../../store/outbound/refCode.recoil';
import { warehouseOptionsAtom } from '../../../../store/outbound/warehouse.recoil';

const makeGridColDef = () => {
  const refCodeOptions = useRecoilValue(refCodeOptionsAtom);
  const receivingStatusOptions = refCodeOptions?.receivingStatus || [];
  const warehouseOption = useRecoilValue(warehouseOptionsAtom);

  return [
    {
      field: 'rowIndex',
      headerName: 'NO',
      minWidth: 50,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'receivedDate',
      headerName: '입고일자',
      minWidth: 60,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'name',
      headerName: '입고명세서명',
      minWidth: 200,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'supplierName',
      headerName: '공급업체',
      minWidth: 120,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'warehouseId',
      headerName: '창고',
      minWidth: 120,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: (params: GridRenderCellParams) => {
        return warehouseOption.find(option => option.value === params.value)?.displayName;
      },
    },
    {
      field: 'sku',
      headerName: '입고품목 수량',
      minWidth: 80,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) => numberFormatter(value),
    },
    {
      field: 'quantity',
      headerName: '입고수량',
      minWidth: 80,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) => numberFormatter(value),
    },
    {
      field: 'totalCost',
      headerName: '입고금액',
      minWidth: 80,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) => numberFormatter(value),
    },
    {
      field: 'status',
      headerName: '입고상태',
      minWidth: 90,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: (params: GridRenderCellParams) => {
        return receivingStatusOptions.find(option => option.value === params.value)?.displayName;
      },
    },
    {
      field: 'rejectReason',
      headerName: '재검수사유',
      minWidth: 90,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
  ] as GridColDef[];
};

export default makeGridColDef;
