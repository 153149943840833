import { Dispatch, SetStateAction } from 'react';
import Box from '@mui/system/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { COLORS } from 'consts/common/colors';
import ListTable from '../../../components/ListTable';
import { GiftDetails } from '../ItemListLine';
import { makeColumns, getTotalRemainingQuantity, message } from './utils';

const modalWidth = 800;

type Props = {
  giftDetails: GiftDetails;
  onClose: () => void;
  giftInspection: () => void;
  selectedImageUrl: string;
  setSelectedImageUrl: Dispatch<SetStateAction<string>>;
};

const GiftModal = (props: Props) => {
  const { giftDetails, onClose, giftInspection, selectedImageUrl, setSelectedImageUrl } = props;

  return (
    <Box width={modalWidth}>
      <Box>
        <Box sx={styles.information}>
          <Typography sx={styles.informationText}>{message}</Typography>
        </Box>
        <ListTable
          rows={giftDetails.items.map(item => ({ ...item, id: item.shippingItemId }))}
          columns={makeColumns(selectedImageUrl, setSelectedImageUrl)}
          hideFooter={giftDetails.type !== 'GIFT'}
          sx={styles.listTable}
          total={getTotalRemainingQuantity(giftDetails)}
        />
        <Box sx={styles.buttonContainer}>
          <Button sx={styles.button} onClick={onClose} variant="outlined">
            닫기
          </Button>
          <Button sx={styles.button} onClick={giftInspection} variant="contained">
            수량자동입력확인
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default GiftModal;

const styles = {
  faultyInfoContainer: { border: `1px solid ${COLORS.lightLine}`, my: 2 },
  faultyRadioContainer: { width: '640px' },
  faultyRadio: { display: 'flex', flexDirection: 'row' },
  faultyRemarkContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '640px',
  },
  faultyRemark: { my: 1, width: '620px' },
  listTable: { maxHeight: '400px', mb: 3 },
  information: {
    m: 1,
  },
  informationText: {
    fontSize: '20px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    mb: 1,
  },
  button: {
    m: 1,
  },
};
