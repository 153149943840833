import React, { KeyboardEvent, useEffect, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { PACKING } from 'consts/outbound/messages';
import { boxAtom, boxCodeAtom, stepAtom, stopModalOpenAtom } from 'store/outbound/packing.recoil';
import { afterProgressTitle, beforeProgressTitle, inProgressTitle } from 'styles/timelineItemTitle';
import usePopup from 'hooks/usePopup';
import Table, { TableCell, TableRow } from '../../../components/Table';
import InvisibleInput from '../../components/InvisibleInput';
import TimelineItem from '../../components/TimelineItem';
import { BARCODE_SCAN_ACTION } from 'consts/outbound/barcodeScanAction';
import { datadogRum } from '@datadog/browser-rum';
import FormModal from '../../../../../components/common/FormModal';
import BoxSizeForm from './BoxSizeForm';
import { axiosV2 } from 'libs/common/fetcher';

export const BOX_STEP = 1;

const BoxLine = () => {
  const [boxCode, setBoxCode] = useRecoilState(boxCodeAtom);
  const [selectedStep, setSelectedStep] = useRecoilState(stepAtom);
  const [isAutoFocus, setIsAutoFocus] = useState<boolean>(true);
  const setStopModalOpen = useSetRecoilState(stopModalOpenAtom);
  const [box, setBox] = useRecoilState(boxAtom);
  const [isBoxSize, setIsBoxSize] = useState<boolean>(false);
  const { showAlert } = usePopup();

  useEffect(() => {
    const firstStep = 0;
    if (selectedStep === firstStep) {
      // 송장 출력이 끝난 후 패킹 페이지가 초기화 될 때 바코드 삭제
      setBoxCode('');
    }
  }, [selectedStep]);

  const fetchBoxByBoxCode = async () => {
    axiosV2
      .get(`/packings/boxes/${boxCode}`)
      .then(res => {
        const box = res.data;
        if (box.barcode === 'Cartonwrap' || box.barcode === 'Genesys') {
          setIsAutoFocus(false);
          setBox(undefined);
          setBoxCode('');
          showAlert({
            message: `${PACKING.wrongBoxCode}\n입력코드: ${boxCode}`,
            onClick: () => {
              setIsAutoFocus(true);
            },
          });
          return;
        }
        setBox(box);
        setIsBoxSize(true);
        setBoxCode('');
      })
      .catch(err => {
        setIsAutoFocus(false);
        setBox(undefined);
        setBoxCode('');
        showAlert({
          message: `${PACKING.wrongBoxCode}\n입력코드: ${boxCode}`,
          onClick: () => {
            setIsAutoFocus(true);
          },
        });
        datadogRum.addAction(PACKING.wrongBoxCode, { boxCode, err });
      });
  };

  const handleKeyDown = async (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      // 포장중지 바코드 버튼을 스캔하면 박스 코드 input에 값이 입력됨
      if (boxCode.toLowerCase() === BARCODE_SCAN_ACTION.stopPacking) {
        setStopModalOpen(true);
        setBoxCode('');
        datadogRum.addAction('포장 중지(박스 라인)');
        return;
      }

      await fetchBoxByBoxCode();
    }
  };

  const handleRevert = () => {
    setSelectedStep(BOX_STEP);
    setBoxCode('');
    datadogRum.addAction('박스 변경');
  };

  const handleCloseModal = () => {
    setIsBoxSize(false);
  };

  return (
    <TimelineItem
      step={BOX_STEP}
      beforeProgressComponent={
        <Typography sx={beforeProgressTitle}>{PACKING.boxCodeScan}</Typography>
      }
      inProgressComponent={
        <>
          <Typography sx={inProgressTitle}>{PACKING.boxCodeScan}</Typography>
          <InvisibleInput
            onChange={e => setBoxCode(e.target.value)}
            onKeyDown={handleKeyDown}
            value={boxCode}
            data-testid="박스 코드"
            isAutoFocus={!isBoxSize && isAutoFocus}
          />
          {isBoxSize && (
            <FormModal open={isBoxSize} title={'박스 사이즈 입력'} onClose={handleCloseModal}>
              <BoxSizeForm onClose={handleCloseModal} boxBarcode={box?.barcode} />
            </FormModal>
          )}
        </>
      }
      afterProgressComponent={
        <>
          <Box sx={styles.titleContainer}>
            <Typography sx={afterProgressTitle}>{PACKING.boxCodeScan}</Typography>
            <Box sx={styles.barcodeButtonContainer}>
              <Button onClick={handleRevert} size="large" variant="contained" sx={styles.button}>
                박스변경
              </Button>
            </Box>
          </Box>
          {box && (
            <Table>
              <TableRow>
                <TableCell sx={styles.tableCell} label="박스명" value={box.name} />
              </TableRow>
            </Table>
          )}
        </>
      }
    />
  );
};

export default BoxLine;

const styles = {
  titleContainer: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' },
  barcodeButtonContainer: { display: 'flex', alignItems: 'center', gap: '8px' },
  button: { height: '40px', fontSize: '17px' },
  tableCell: { fontSize: '16px' },
};
