import Button from '@mui/material/Button';
import { useState } from 'react';
import _ from 'lodash';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Page from 'components/common/Layout/Page';
import Filter, { Form } from 'components/common/Filter';
import TightDataGridPro from 'components/common/TightDataGridPro';
import { ROWS_PER_PAGE_OPTIONS } from 'consts/common/dataGrid';
import fetcher from 'libs/common/fetcher';
import pageTitle from 'styles/pageTitle';
import { listBoardTheme } from 'styles/customedMuiTheme';
import { useRecoilValue } from 'recoil';
import FormModal from '../../../../components/common/FormModal';
import convertUser from '../../../../libs/common/convertUser';
import { warehouseAtom } from '../../../../store/outbound/warehouse.recoil';
import { COLORS } from '../../../../consts/common/colors';
import ItemOptionForm from './components/ItemOptionForm';
import gridColumns from './gridColDef';
import { Options } from '../../../../types/form';
import useConditionalSWR from '../../components/useConditionalSwr';
import { getFinalUrl } from '../../../../consts/outbound/imageUrl';
import ImageZoomModal from '../../../../components/ImageZoomModal';

const defaultOption = { displayName: '전체', value: 'ALL' };

type ItemOption = {
  id: number;
  itemCode: string;
  itemName: string;
  offlineBarcode: string;
  barcode: string;
  imageUrl: string;
  weight: number;
  width: number;
  height: number;
  length: number;
  updatedUsername: string;
  updatedUserLoginId: string;
  updatedAt: string;
};

type ItemOptionResponses = {
  content: ItemOption[];
  totalElements: number;
  size: number;
  number: number;
};

type SearchQuery = {
  page?: number;
  size?: number;
  warehouseId?: number;
};

const searchKeywordOptions: Options = [
  {
    displayName: '아이템코드',
    field: 'itemCode',
  },
  {
    displayName: '아이템명',
    field: 'itemName',
  },
  {
    displayName: '바코드',
    field: 'barcode',
  },
];

const gridTemplateColumns = [
  '30px',
  'minmax(45px, 0.6fr)',
  'minmax(45px, 0.6fr)',
  '60px',
  'minmax(55px, 2fr)',
  'minmax(55px, 1.4fr)',
  '36px',
  'minmax(45px, 0.6fr)',
  'minmax(45px, 0.6fr)',
  '36px',
  'minmax(55px, 1.4fr)',
  'minmax(55px, 1.4fr)',
].reduce((prevValue, labelWidth) => prevValue + labelWidth + ' ', '');

const defaultSize = 100;
const defaultPage = 0;
const ItemOptions = () => {
  const [hasSearched, setHasSearched] = useState(false);
  const [imageUrl, setImageUrl] = useState<string>('');
  const globalWarehouse = useRecoilValue(warehouseAtom);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedId, setSelectedId] = useState<number>(-1);
  const [queryParams, setQueryParams] = useState<SearchQuery>({
    page: defaultPage,
    size: defaultSize,
    warehouseId: Number(globalWarehouse),
  });

  const { data, mutate, isValidating } = useConditionalSWR<ItemOptionResponses>(
    [`/item-options/search`, { ...queryParams, sort: 'id,desc' }],
    fetcher,
    hasSearched
  );

  const makeRowIndex = (data: ItemOptionResponses) => {
    const { content, totalElements, size, number } = data;

    return content.map((item: ItemOption, index: number) => ({
      ...item,
      rowIndex: totalElements - size * number - index,
      updatedUser: convertUser(item.updatedUsername, item.updatedUserLoginId),
    }));
  };

  const handleInitClick = async () => {
    setQueryParams({
      page: defaultPage,
      size: defaultSize,
      warehouseId: Number(globalWarehouse),
    });
    await mutate();
  };

  const handleExcelDownload = async () => {
    setIsModalOpen(true);
    setSelectedId(-1);
  };

  const handleSearchClick = async (form: Form) => {
    const updatedForm = _.omitBy(form, o => o === defaultOption.value);
    delete updatedForm[`dateType`];

    setQueryParams(({ size }) => ({ ...updatedForm, size, page: defaultPage }));
    !hasSearched && setHasSearched(true);
    await mutate();
  };

  // const handleRowClick: GridEventListener<GridEvents.rowClick> = params => {
  //   params.id && setIsModalOpen(true);
  //   setSelectedId(params.id as number);
  // };

  const onCloseModal = () => {
    setIsModalOpen(false);
    setSelectedId(-1);
  };

  return (
    <Page>
      <Typography variant="h2" sx={pageTitle}>
        아이템옵션 목록
      </Typography>
      <Filter gridTemplateColumns={gridTemplateColumns}>
        <Filter.SearchWithSelect
          label="검색어"
          searchOptions={searchKeywordOptions}
          labelGridColumn="1/3"
          selectGridColumn="3/5"
          inputGridColumn="5/7"
        />
        <Divider sx={styles.divider} />
        <Filter.DefaultButtonGroup
          gridColumn="1/-1"
          gridRow={5}
          onInitClick={handleInitClick}
          onLookupClick={handleSearchClick}
          sx={{ display: 'flex', justifyContent: 'center' }}
        />
      </Filter>
      <Stack spacing={1} sx={listBoardTheme.container}>
        <Box sx={listBoardTheme.header}>
          <Typography variant="h6" sx={{ color: 'text.primary' }}>
            아이템옵션 목록
          </Typography>
          <Button variant="outlined" onClick={handleExcelDownload}>
            아이템옵션 등록
          </Button>
        </Box>
        <TightDataGridPro
          rows={hasSearched && data?.content ? makeRowIndex(data) : []}
          columns={gridColumns()}
          paginationMode="server"
          rowCount={hasSearched ? data?.totalElements ?? 0 : 0}
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          onPageChange={page => setQueryParams(params => ({ ...params, page }))}
          pageSize={queryParams.size ?? defaultSize}
          onPageSizeChange={size => setQueryParams(params => ({ ...params, size }))}
          loading={isValidating}
          hasSearched={hasSearched}
          onCellClick={({ field, row }) => {
            if (field === 'imageUrl') setImageUrl(getFinalUrl(row.imageUrl));
            else {
              row.id && setIsModalOpen(true);
              setSelectedId(row.id as number);
            }
          }}
        />
        <ImageZoomModal imageUrl={imageUrl} onClose={() => setImageUrl('')} />
      </Stack>
      <FormModal
        open={isModalOpen}
        title={selectedId !== -1 ? '아이템옵션 수정' : '아이템옵션 등록'}
        onClose={onCloseModal}
      >
        <ItemOptionForm itemOptionId={selectedId} onClose={onCloseModal} onMutate={mutate} />
      </FormModal>
    </Page>
  );
};

export default ItemOptions;

const styles = {
  divider: {
    gridRow: 4,
    gridColumn: '1/-1',
    pb: 1,
    mb: 1,
  },
  paperProps: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '520px',
    height: '460px',
  },
  backdropProps: {
    backgroundColor: COLORS.faintBlack,
  },
  dialogTitle: {
    paddingTop: '32px',
    textAlign: 'center',
    fontSize: '24px',
    fontWeight: '700',
    fontStyle: 'normal',
    lineHeight: '32px',
    letterSpacing: '-0.3px',
  },
  dialogSteps: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '76%',
  },
  otherStep: {
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '-0.4px',
  },
  currentStep: {
    color: COLORS.ktown4u,
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '-0.4px',
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-even',
    alignItems: 'center',
    width: '92%',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '-0.4px',
  },
  scaledWeightWrapper: {
    width: '100%',
    height: '24%',
    border: `1px solid ${COLORS.darkLine}`,
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    fontSize: 20,
    marginTop: 1,
  },
  scaledWeightTitle: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    fontSize: 20,
    pr: 2,
  },
  scaledWeightMessage: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    fontSize: 20,
    fontWeight: 'bold',
  },
};
