import type { GridColDef } from '@mui/x-data-grid-pro';
import { numberFormatter } from '../../libs/common/unitConverters';

const makeGridColDef = () => {
  return [
    {
      field: 'shippingOrderCreatedAt',
      headerName: '출고지시 생성일',
      minWidth: 100,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'shippingCount',
      headerName: '출고지시 출고건수',
      minWidth: 100,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) => numberFormatter(value),
    },
    {
      field: 'openCount',
      headerName: '출고대기',
      minWidth: 60,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) => numberFormatter(value),
    },
    {
      field: 'pickingGroupCount',
      headerName: '집품그룹 생성완료',
      minWidth: 60,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) => numberFormatter(value),
    },
    {
      field: 'pickingCount',
      headerName: '집품',
      minWidth: 60,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) => numberFormatter(value),
    },
    {
      field: 'inspectionCount',
      headerName: '검수',
      minWidth: 60,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) => numberFormatter(value),
    },
    {
      field: 'packingCount',
      headerName: '포장',
      minWidth: 60,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) => numberFormatter(value),
    },
    {
      field: 'shippedCount',
      headerName: '출고완료',
      minWidth: 60,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) => numberFormatter(value),
    },
    {
      field: 'completionRate',
      headerName: '출고완료율(%)',
      minWidth: 60,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
  ] as GridColDef[];
};

export default makeGridColDef;
