import type { GridColDef } from '@mui/x-data-grid-pro';
import convertWarehouseFullCode from '../../../../libs/warehouse/convertWarehouseFullCode';
import { warehouseOptionsAtom } from '../../../../store/outbound/warehouse.recoil';
import { useRecoilValue } from 'recoil';
import refCodeOptionsAtom from '../../../../store/outbound/refCode.recoil';

const gridColumns = () => {
  const refCodeOptions = useRecoilValue(refCodeOptionsAtom);
  const pickingGroupTypeOptions = refCodeOptions?.pickingGroupType || [];
  const pickingStatusOptions = refCodeOptions?.pickingStatus || [];
  const warehouseOption = useRecoilValue(warehouseOptionsAtom);
  const columns: GridColDef[] = [
    {
      field: 'rowIndex',
      headerName: 'NO',
      minWidth: 20,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'warehouseId',
      headerName: '창고',
      minWidth: 20,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) => {
        return convertWarehouseFullCode(value, warehouseOption);
      },
    },
    {
      field: 'status',
      headerName: '집품상태',
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) =>
        pickingStatusOptions?.find(v => v.value === value)?.displayName,
    },
    {
      field: 'type',
      headerName: '집품구분',
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) =>
        pickingGroupTypeOptions?.find(v => v.value === value)?.displayName,
    },
    {
      field: 'pickingGroupSerialNumber',
      headerName: '집품그룹ID',
      minWidth: 40,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'shippingSeq',
      headerName: '출고ID',
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'deliverySeq',
      headerName: '배송ID',
      minWidth: 240,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'deliveryId',
      headerName: '배송번호',
      minWidth: 24,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'pda',
      headerName: 'PDA',
      minWidth: 160,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'tote', //TODO: or toteBarcode
      headerName: '토트',
      minWidth: 160,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'barcode',
      headerName: '바코드',
      minWidth: 120,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'offlineBarcode',
      headerName: '오프라인바코드',
      minWidth: 140,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'itemCode',
      headerName: '아이템코드',
      minWidth: 100,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'itemName',
      headerName: '아이템명',
      minWidth: 100,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'locationCode',
      headerName: '로케이션',
      minWidth: 170,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'editedTotalQuantity',
      headerName: '아이템수량',
      minWidth: 20,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'pickedQuantity', //TODO: api 확인요망
      headerName: '집품수량',
      minWidth: 20,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'updatedUser',
      headerName: '작업자',
      minWidth: 160,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'pickingStartAt',
      headerName: '집품시작일시',
      minWidth: 160,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'pickingCompletedAt',
      headerName: '집품완료일시',
      minWidth: 160,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
  ];
  return columns;
};

export default gridColumns;
