import React, { useRef, useState } from 'react';
import _ from 'lodash';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Page from 'components/common/Layout/Page';
import Filter, { Form } from 'components/common/Filter';
import TightDataGridPro from 'components/common/TightDataGridPro';
import { ROWS_PER_PAGE_OPTIONS } from 'consts/common/dataGrid';
import fetcher from 'libs/common/fetcher';
import { listBoardTheme } from 'styles/customedMuiTheme';
import { useRecoilValue } from 'recoil';
import { warehouseAtom, warehouseOptionsAtom } from '../../../../store/outbound/warehouse.recoil';
import { COLORS } from '../../../../consts/common/colors';
import AdjustQuantityForm from './components/AdjustQuantityForm';
import gridColumns from './gridColDef';
import { DEFAULT_PAGE, DEFAULT_SIZE } from '../../../../consts/common/pageAndSize';
import { Options } from '../../../../types/form';
import pageTitle from '../../../../styles/pageTitle';
import Button from '@mui/material/Button';
import FormModal from '../../../../components/common/FormModal';
import AddInventoryForm from './components/AddInventoryForm';
import refCodeOptionsAtom from '../../../../store/outbound/refCode.recoil';
import MoveQuantityBetweenItemForm from './components/MoveQuantityBetweenItemForml';
import usePopup from '../../../../hooks/usePopup';
import useConditionalSWR from '../../components/useConditionalSwr';
import LoadingButton from '@mui/lab/LoadingButton';
import { generateExcelDownload } from './services/excelDownload';
import CreateInventoryItemForm from './components/CreateInvetoryItemForm';
import ImageZoomModal from '../../../../components/ImageZoomModal';
import { getFinalUrl } from '../../../../consts/outbound/imageUrl';

const defaultOption = { displayName: '전체', value: 'ALL' };

export type Inventory = {
  id: number;
  warehouseId: number;
  itemCode: string;
  imageUrl: string;
  itemName: string;
  barcode: string;
  quantity: number;
  pendingQuantity: number;
  status: string;
  locationCode: string;
};

type InventoriesResponse = {
  content: Inventory[];
  totalElements: number;
  size: number;
  number: number;
};

type SearchQuery = {
  page?: number;
  size?: number;
  warehouseId?: number;
};

const searchKeywordOptions: Options = [
  {
    displayName: '아이템코드',
    field: 'itemCode',
  },
  {
    displayName: '아이템명',
    field: 'itemName',
  },
  {
    displayName: '바코드',
    field: 'barcode',
  },
];

const gridTemplateColumns = [
  '30px',
  'minmax(45px, 0.6fr)',
  'minmax(45px, 0.6fr)',
  '80px',
  'minmax(55px, 2fr)',
  'minmax(55px, 1.4fr)',
  '36px',
  'minmax(45px, 0.6fr)',
  'minmax(45px, 0.6fr)',
  '36px',
  'minmax(55px, 1.4fr)',
  'minmax(55px, 1.4fr)',
].reduce((prevValue, labelWidth) => prevValue + labelWidth + ' ', '');

const InventoryLocations = () => {
  const { showErrorDialog, showSnackbar } = usePopup();
  const multipleBinTypeSelectRef = useRef<{ initialValues: () => void }>(null);
  const [isExcelDownload, setIsExcelDownload] = useState(false);
  const [hasSearched, setHasSearched] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState<string>('');
  const [isMoveQuantityBetweenItemModalOpens, setIsMoveQuantityBetweenItemModalOpens] =
    useState(false);
  const [isCreateInventoryItemModalOpens, setIsCreateInventoryItemModalOpens] = useState(false);
  const [isAdjustQuantityModalOpen, setIsAdjustQuantityModalOpen] = useState(false);
  const [selectedInventoryLpn, setSelectedInventoryLpn] = useState<number>();
  const [inventoryLpn, setInventoryLpn] = useState<Inventory | undefined>();
  const globalWarehouse = useRecoilValue(warehouseAtom);
  const warehouseOption = useRecoilValue(warehouseOptionsAtom);
  const refCodeOptions = useRecoilValue(refCodeOptionsAtom);
  const statusOptions = refCodeOptions?.inventoryLpnStatus || [];
  const binTypes = refCodeOptions?.binType || [];
  const [queryParams, setQueryParams] = useState<SearchQuery>({
    page: DEFAULT_PAGE,
    size: DEFAULT_SIZE,
    warehouseId: Number(globalWarehouse),
  });

  const { data, mutate, isValidating } = useConditionalSWR<InventoriesResponse>(
    [`/inventories/locations`, { ...queryParams }],
    fetcher,
    hasSearched
  );

  const makeRowIndex = (data: InventoriesResponse) => {
    const { content, totalElements, size, number } = data;

    return content.map((item: Inventory, index: number) => ({
      ...item,
      rowIndex: totalElements - size * number - index,
    }));
  };

  const handleInitClick = async () => {
    multipleBinTypeSelectRef.current?.initialValues();

    setQueryParams({
      page: DEFAULT_PAGE,
      size: DEFAULT_SIZE,
      warehouseId: Number(globalWarehouse),
    });
    await mutate();
  };

  const handleSearchClick = async (form: Form) => {
    const updatedForm = _.omitBy(form, o => o === defaultOption.value);
    delete updatedForm[`dateType`];

    setQueryParams(({ size }) => ({ ...updatedForm, size, page: DEFAULT_PAGE }));
    !hasSearched && setHasSearched(true);
    await mutate();
  };

  const handleAddInventory = async () => {
    setIsModalOpen(true);
  };

  const handleAdjustedQuantity = async () => {
    if (!selectedInventoryLpn) {
      showErrorDialog({
        title: '재고수량 조정 실패',
        errorMessage: '재고수량 조정 할 로케이션을 선택해 주세요.',
        buttons: [
          {
            text: '확인',
          },
        ],
      });
      return;
    }

    const foundInventoryLpn = data?.content.find(item => item.id === selectedInventoryLpn);
    setInventoryLpn(foundInventoryLpn);
    setIsAdjustQuantityModalOpen(true);
  };

  const handleMoveQuantityBetweenItems = async () => {
    if (!selectedInventoryLpn) {
      showErrorDialog({
        title: '재고 보정 실패',
        errorMessage: '재고보정 할 로케이션을 선택해 주세요.',
        buttons: [
          {
            text: '확인',
          },
        ],
      });
      return;
    }

    const foundInventoryLpn = data?.content.find(item => item.id === selectedInventoryLpn);
    setInventoryLpn(foundInventoryLpn);
    setIsMoveQuantityBetweenItemModalOpens(true);
  };

  const handleCreateInventoryItem = async () => {
    setIsCreateInventoryItemModalOpens(true);
  };

  const handleExcelDownload = async () => {
    setIsExcelDownload(true);
    const response = await generateExcelDownload(queryParams);
    if (response?.status === 200) {
      showSnackbar({ message: '엑셀 다운로드가 완료되었습니다.', severity: 'success' });
    } else {
      showErrorDialog({
        title: '엑셀 다운로드 실패',
        errorMessage: response?.errorMessage,
        buttons: [{ text: '확인' }],
      });
    }
    setIsExcelDownload(false);
  };

  const handleCloseMoveQuantityBetweenItemModal = () => {
    setIsMoveQuantityBetweenItemModalOpens(false);
    setSelectedInventoryLpn(undefined);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleCloseAdjustInventoryModal = () => {
    setIsAdjustQuantityModalOpen(false);
    setSelectedInventoryLpn(undefined);
  };

  const handleCloseCreateInventoryItemModal = async () => {
    setIsCreateInventoryItemModalOpens(false);
  };

  return (
    <Page>
      <Typography variant="h2" sx={pageTitle}>
        재고조회(로케이션)
      </Typography>
      <Filter gridTemplateColumns={gridTemplateColumns}>
        <Filter.Select
          label="창고"
          field="warehouseId"
          options={warehouseOption}
          labelGridColumn="1/2"
          selectGridColumn="2/4"
          defaultValue={globalWarehouse}
        />
        <Filter.Search
          label="로케이션"
          field="locationCode"
          labelGridColumn="4/5"
          inputGridColumn="5/8"
        />
        <Filter.SearchWithSelect
          label="검색어"
          searchOptions={searchKeywordOptions}
          labelGridColumn="8/9"
          selectGridColumn="9/11"
          inputGridColumn="11/13"
        />
        <Filter.Select
          label="상태"
          field="status"
          gridRow={2}
          options={[defaultOption, ...statusOptions]}
          labelGridColumn="1/2"
          selectGridColumn="2/4"
        />
        <Filter.MultipleSelectPlaceholder
          ref={multipleBinTypeSelectRef}
          label="로케이션구분"
          field="binTypes"
          options={[defaultOption, ...binTypes]}
          labelGridColumn="4/5"
          selectGridColumn="5/8"
        />
        <Divider sx={styles.divider} />
        <Filter.DefaultButtonGroup
          gridColumn="1/-1"
          gridRow={5}
          onInitClick={handleInitClick}
          onLookupClick={handleSearchClick}
          sx={{ display: 'flex', justifyContent: 'center' }}
        />
      </Filter>
      <Stack spacing={1} sx={listBoardTheme.container}>
        <Box sx={listBoardTheme.header}>
          <Typography variant="h6" sx={{ color: 'text.primary' }}>
            재고 목록
          </Typography>
          <Box>
            <Button
              sx={listBoardTheme.headerButton}
              variant="outlined"
              suppressHydrationWarning
              onClick={handleAdjustedQuantity}
            >
              재고수량 조정
            </Button>
            <Button
              sx={listBoardTheme.headerButton}
              variant="outlined"
              suppressHydrationWarning
              onClick={handleMoveQuantityBetweenItems}
            >
              재고 버전이동
            </Button>
            <Button
              sx={listBoardTheme.headerButton}
              variant="outlined"
              suppressHydrationWarning
              onClick={handleCreateInventoryItem}
            >
              아이템재고 추가
            </Button>
            <LoadingButton
              sx={listBoardTheme.headerButton}
              variant="outlined"
              onClick={handleExcelDownload}
              loading={isExcelDownload}
            >
              엑셀다운로드
            </LoadingButton>
            <Button
              sx={listBoardTheme.headerButton}
              style={{ display: 'none' }}
              variant="contained"
              suppressHydrationWarning
              onClick={handleAddInventory}
            >
              재고 추가
            </Button>
          </Box>
        </Box>
        <TightDataGridPro
          rows={hasSearched && data?.content ? makeRowIndex(data) : []}
          columns={gridColumns(warehouseOption, selectedInventoryLpn, setSelectedInventoryLpn)}
          paginationMode="server"
          rowCount={hasSearched ? data?.totalElements ?? 0 : 0}
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          onPageChange={page => setQueryParams(params => ({ ...params, page }))}
          pageSize={queryParams.size ?? DEFAULT_SIZE}
          onPageSizeChange={size => setQueryParams(params => ({ ...params, size }))}
          onCellClick={({ field, row }) => {
            if (field === 'imageUrl') setImageUrl(getFinalUrl(row.imageUrl));
          }}
          loading={isValidating}
          hasSearched={hasSearched}
        />
        <ImageZoomModal imageUrl={imageUrl} onClose={() => setImageUrl('')} />
      </Stack>
      <FormModal open={isModalOpen} title={'재고 추가'} onClose={handleCloseModal}>
        <AddInventoryForm onClose={handleCloseModal} />
      </FormModal>
      <FormModal
        open={isMoveQuantityBetweenItemModalOpens}
        title={'재고 버전이동'}
        onClose={handleCloseMoveQuantityBetweenItemModal}
      >
        <MoveQuantityBetweenItemForm
          inventoryLpn={inventoryLpn}
          onClose={handleCloseMoveQuantityBetweenItemModal}
          mutate={mutate}
        />
      </FormModal>
      <FormModal
        open={isAdjustQuantityModalOpen}
        title={'재고수량 조정'}
        onClose={handleCloseAdjustInventoryModal}
      >
        <AdjustQuantityForm
          inventoryLpn={inventoryLpn}
          onClose={handleCloseAdjustInventoryModal}
          mutate={mutate}
        />
      </FormModal>
      <FormModal
        open={isCreateInventoryItemModalOpens}
        title={'아이템재고 추가'}
        onClose={handleCloseCreateInventoryItemModal}
      >
        <CreateInventoryItemForm onClose={handleCloseCreateInventoryItemModal} mutate={mutate} />
      </FormModal>
    </Page>
  );
};

export default InventoryLocations;

const styles = {
  divider: {
    gridRow: 4,
    gridColumn: '1/-1',
    pb: 1,
    mb: 1,
  },
  paperProps: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '520px',
    height: '460px',
  },
  backdropProps: {
    backgroundColor: COLORS.faintBlack,
  },
  dialogTitle: {
    paddingTop: '32px',
    textAlign: 'center',
    fontSize: '24px',
    fontWeight: '700',
    fontStyle: 'normal',
    lineHeight: '32px',
    letterSpacing: '-0.3px',
  },
  dialogSteps: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '76%',
  },
  otherStep: {
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '-0.4px',
  },
  currentStep: {
    color: COLORS.ktown4u,
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '-0.4px',
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-even',
    alignItems: 'center',
    width: '92%',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '-0.4px',
  },
  scaledWeightWrapper: {
    width: '100%',
    height: '24%',
    border: `1px solid ${COLORS.darkLine}`,
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    fontSize: 20,
    marginTop: 1,
  },
  scaledWeightTitle: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    fontSize: 20,
    pr: 2,
  },
  scaledWeightMessage: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    fontSize: 20,
    fontWeight: 'bold',
  },
};
