import React from 'react';
import type { SxProps } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import { FONT_SIZE } from 'consts/common/typography';
import Table, { TableCell, TableRow } from '../../../components/Table';
import type { Option } from './SelectBase';
import SelectBase from './SelectBase';
import { CarrierForm } from './CarriersForm';

const modalFontSize = FONT_SIZE.medium;

type RowSelect = {
  name: string;
  options: Option[];
  selected: string;
};

type RowInput = {
  name: string;
  value: string | number;
  type?: string;
};

type RowValue = {
  type: string;
  typography?: string | string[];
  select?: RowSelect;
  input?: RowInput;
};

type Row = {
  label: string;
  value: RowValue;
  shouldHide?: boolean;
  required?: boolean;
  isCreateMode?: boolean;
};

type Props = {
  rows: Row[];
  height?: string;
  labelSx?: SxProps;
  setCarrier: React.Dispatch<React.SetStateAction<CarrierForm>>;
};

const TableWithCustomCell = (props: Props) => {
  const { rows, height = '36px', labelSx = styles.label, setCarrier } = props;

  return (
    <Table>
      {rows.map((row: Row) => {
        if (row.shouldHide) {
          return null;
        }

        return (
          <TableRow key={row.label} height={height}>
            <TableCell
              label={row.label}
              value={makeCellValue(row.value, setCarrier)}
              labelSx={labelSx}
              required={row.required}
              isCreateMode={row.isCreateMode}
            />
          </TableRow>
        );
      })}
    </Table>
  );
};

export default TableWithCustomCell;

const makeCellValue = (
  cell: RowValue,
  setCarrier: React.Dispatch<React.SetStateAction<CarrierForm>>
) => {
  switch (cell.type) {
    case 'typography':
      return (
        <Typography fontSize={modalFontSize} fontWeight={'bold'}>
          {cell.typography}
        </Typography>
      );
    case 'select':
      return (
        cell.select && (
          <SelectBase
            name={cell.select.name}
            options={cell.select.options}
            selected={cell.select.selected}
            sx={styles.select}
            onChange={event => {
              setCarrier(device =>
                cell.select?.name
                  ? {
                      ...device,
                      [cell.select.name]: event.target.value,
                    }
                  : device
              );
            }}
          />
        )
      );
    case 'input':
      return (
        cell.input && (
          <FormControl fullWidth>
            <OutlinedInput
              value={cell.input.value}
              onChange={event =>
                setCarrier(carrier =>
                  cell.input?.name ? { ...carrier, [cell.input.name]: event.target.value } : carrier
                )
              }
              sx={styles.outlinedInput}
              type={cell.input.type || 'text'}
            />
          </FormControl>
        )
      );
  }
};

const styles = {
  select: { width: '344px', ml: -1.5 },
  label: { width: '128px', fontSize: modalFontSize },
  outlinedInput: { height: '28px', width: '344px', ml: -1.5 },
};
