import React, { useRef, useState } from 'react';
import _ from 'lodash';
import { useRecoilValue } from 'recoil';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { GridEventListener, GridEvents } from '@mui/x-data-grid-pro';
import Page from '../../../../components/common/Layout/Page';
import Filter, { Form } from '../../../../components/common/Filter';
import TightDataGridPro from '../../../../components/common/TightDataGridPro';
import refCodeOptionsAtom from '../../../../store/outbound/refCode.recoil';
import { warehouseAtom } from '../../../../store/outbound/warehouse.recoil';
import { ROWS_PER_PAGE_OPTIONS } from '../../../../consts/common/dataGrid';
import { DEFAULT_PAGE, DEFAULT_SIZE } from '../../../../consts/common/pageAndSize';
import fetcher from '../../../../libs/common/fetcher';
import pageTitle from '../../../../styles/pageTitle';
import { listBoardTheme } from '../../../../styles/customedMuiTheme';
import { Options } from '../../../../types/form';
import TopBoard from '../../components/TopBoard';
import useConditionalSWR from '../../components/useConditionalSwr';
import { defaultOption } from '../../info/devices/index.page';
import makeGridColDef from './gridColDef';

const dateRangeTypeOptions: Options = [{ displayName: '입고예정일', field: 'receivingETA' }];

const searchKeywordOptions: Options = [
  { displayName: '공급업체', field: 'supplierName' },
  { displayName: '발주서명', field: 'purchaseOrderName' },
];

const gridTemplateColumns = [
  '75px',
  'minmax(45px, 0.5fr)',
  'minmax(45px, 0.5fr)',
  '50px',
  'minmax(55px, 2fr)',
  'minmax(55px, 1.4fr)',
  '70px',
  'minmax(55px, 0.6fr)',
  'minmax(55px, 0.6fr)',
  '50px',
  'minmax(55px, 1.0fr)',
  'minmax(55px, 1.0fr)',
].reduce((prevValue, labelWidth) => prevValue + labelWidth + ' ', '');

type SearchQuery = {
  page?: number;
  size?: number;
  status?: string;
};

type InboundExpectedReceivingContent = {
  receivingEta: string;
  inventoryTransferId: number;
  status: string;
  shippingStatus: string;
  supplierName: string;
  totalPrice: number;
  totalQuantity: number;
  sku: number;
};

type InboundExpectedReceiving = {
  content: InboundExpectedReceivingContent[];
  totalElements: number;
  size: number;
  number: number;
};

const ExpectedInventoryTransferReceiving = () => {
  const globalWarehouse = useRecoilValue(warehouseAtom);
  const initButtonRef = useRef<{ handleClick: () => void }>();
  const [hasSearched, setHasSearched] = useState(false);
  const [queryParams, setQueryParams] = useState<SearchQuery>({
    page: DEFAULT_PAGE,
    size: DEFAULT_SIZE,
  });
  const refCodeOptions = useRecoilValue(refCodeOptionsAtom);
  const purchaseOrderStatusOptions = refCodeOptions?.gmsRegistedStatus || [];
  const shippingStatusOptions = refCodeOptions?.shippingStatus || [];

  const navigate = useNavigate();

  const { data, isValidating, mutate } = useConditionalSWR<InboundExpectedReceiving>(
    [`/inventory-transfers/receivings`, { ...queryParams, sort: 'id,desc' }],
    fetcher,
    hasSearched
  );

  const topBoard = [
    {
      title: '입고대기',
      value: 0,
      field: '',
    },
    {
      title: '입고중',
      value: 0,
      field: '',
    },
  ];

  const makeRowIndex = (data: InboundExpectedReceiving) => {
    const { content, totalElements, size, number } = data;

    return content.map((item: InboundExpectedReceivingContent, index: number) => ({
      ...item,
      id: item.inventoryTransferId,
      rowIndex: totalElements - size * number - index,
    }));
  };

  const handleInitClick = async () => {
    setQueryParams({
      page: DEFAULT_PAGE,
      size: DEFAULT_SIZE,
    });
    await pageMutate();
  };

  const handleSearchClick = async (form: Form) => {
    const updatedForm = _.omitBy(form, o => o === defaultOption.value);
    delete updatedForm[`dateType`];

    setQueryParams(({ size }) => ({
      ...updatedForm,
      warehouseId: Number(globalWarehouse),
      size,
      page: DEFAULT_PAGE,
    }));
    !hasSearched && setHasSearched(true);
    await pageMutate();
  };

  const pageMutate = async () => {
    await mutate();
    // await countMutate();
  };

  const handleClickTopBoardCount = async () => {
    initButtonRef.current && initButtonRef.current?.handleClick();
    !hasSearched && setHasSearched(true);

    setQueryParams({
      page: DEFAULT_PAGE,
      size: DEFAULT_SIZE,
    });
  };

  const handleRowClick: GridEventListener<GridEvents.rowClick> = params => {
    navigate(`/gms/inbound/expect-inventory-transfer-receiving/details`, {
      state: { inventoryTransferId: params.row.inventoryTransferId },
    });
  };

  return (
    <Page>
      <Typography variant="h2" sx={pageTitle}>
        대체입고예정 관리
      </Typography>
      <TopBoard data={topBoard} onClick={handleClickTopBoardCount} />
      <Filter gridTemplateColumns={gridTemplateColumns}>
        <Filter.Select
          label="발주서상태"
          field="status"
          options={[defaultOption, ...purchaseOrderStatusOptions]}
          labelGridColumn="1/2"
          selectGridColumn="2/4"
          defaultValue={
            queryParams.status ??
            purchaseOrderStatusOptions.find(option => option.value === 'CREATED')?.value
          }
        />
        <Filter.Select
          label="출고상태"
          field="shippingStatus"
          options={[defaultOption, ...shippingStatusOptions]}
          labelGridColumn="4/5"
          selectGridColumn="5/6"
        />
        <Filter.Select
          label="출고상태"
          field="shippingStatus"
          options={[defaultOption, ...shippingStatusOptions]}
          labelGridColumn="4/5"
          selectGridColumn="5/6"
        />
        <Filter.DateRangePickerWithSelect
          label="기간"
          rangeTypeOptions={dateRangeTypeOptions}
          gridRow={2}
          labelGridColumn="1/2"
          selectGridColumn="2/4"
          dateRangePickerGridColumn="4/6"
          rangeAmountSelectGridColumn="6/7"
        />
        <Filter.SearchWithSelect
          label="검색어"
          gridRow={2}
          searchOptions={searchKeywordOptions}
          labelGridColumn="7/8"
          selectGridColumn="8/10"
          inputGridColumn="10/13"
        />
        <Divider sx={styles.divider} />
        <Filter.DefaultButtonGroup
          initRef={initButtonRef}
          gridColumn="1/-1"
          gridRow={5}
          onInitClick={handleInitClick}
          onLookupClick={handleSearchClick}
          sx={{ display: 'flex', justifyContent: 'center' }}
        />
      </Filter>
      <Stack spacing={1} sx={listBoardTheme.container}>
        <Box sx={listBoardTheme.header}>
          <Typography variant="h6" sx={{ color: 'text.primary' }}>
            입고예정 목록
          </Typography>
        </Box>
        <TightDataGridPro
          rows={hasSearched && data?.content ? makeRowIndex(data) : []}
          columns={makeGridColDef()}
          paginationMode="server"
          rowCount={hasSearched ? data?.totalElements ?? 0 : 0}
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          onRowClick={handleRowClick}
          onPageChange={page => setQueryParams(params => ({ ...params, page }))}
          pageSize={queryParams.size ?? DEFAULT_SIZE}
          onPageSizeChange={size => setQueryParams(params => ({ ...params, size }))}
          loading={isValidating}
          hasSearched={hasSearched}
        />
      </Stack>
    </Page>
  );
};

export default ExpectedInventoryTransferReceiving;

const styles = {
  divider: {
    gridRow: 4,
    gridColumn: '1/-1',
    pb: 1,
    mb: 1,
  },
  gridBorderTopButton: {
    marginRight: 1,
  },
};
