import React, { useState } from 'react';
import _ from 'lodash';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Page from 'components/common/Layout/Page';
import Filter, { Form } from 'components/common/Filter';
import TightDataGridPro from 'components/common/TightDataGridPro';
import { ROWS_PER_PAGE_OPTIONS } from 'consts/common/dataGrid';
import fetcher from 'libs/common/fetcher';
import pageTitle from 'styles/pageTitle';
import { listBoardTheme } from 'styles/customedMuiTheme';
import { Options } from 'types/form';
import { useRecoilValue } from 'recoil';
import { warehouseAtom, warehouseOptionsAtom } from '../../../../store/outbound/warehouse.recoil';
import gridColumns from './gridColDef';
import convertUser from '../../../../libs/common/convertUser';
import { DEFAULT_PAGE, DEFAULT_SIZE } from '../../../../consts/common/pageAndSize';
import refCodeOptionsAtom from '../../../../store/outbound/refCode.recoil';
import { GridEventListener, GridEvents } from '@mui/x-data-grid-pro';
import useConditionalSWR from '../../components/useConditionalSwr';

const dateRangeTypeOptions: Options = [
  {
    displayName: '검수완료일',
    field: 'bulkInspectionCompletedAt',
  },
  {
    displayName: '집품완료일',
    field: 'pickingGroupCompletedAt',
  },
];

const searchKeywordOptions: Options = [
  {
    displayName: '토트',
    field: 'toteBarcode',
  },
];

const defaultOption = { displayName: '전체', value: 'ALL' };

type BulkInspection = {
  id: number;
  warehouseId: number;
  status: string;
  pickingGroupSerialNumber: string;
  pickingGroupCompletedAt: string;
  bulkInspectionCompletedAt: string;
  toteBarcodes: string;
  shippingCount: number;
  estimatedWeight: number;
  updatedUsername: string;
  updatedLoginId: string;
  updatedAt: string;
};

type BulkInspectionResponse = {
  content: BulkInspection[];
  totalElements: number;
  size: number;
  number: number;
};

type SearchQuery = {
  page?: number;
  size?: number;
  warehouseId?: number;
};

const gridTemplateColumns = [
  '30px',
  'minmax(45px, 0.6fr)',
  'minmax(45px, 0.6fr)',
  '50px',
  'minmax(55px, 1.9fr)',
  'minmax(55px, 1.2fr)',
  '72px',
  'minmax(45px, 0.6fr)',
  'minmax(45px, 0.6fr)',
  '36px',
  'minmax(55px, 1fr)',
  'minmax(55px, 1fr)',
].reduce((prevValue, labelWidth) => prevValue + labelWidth + ' ', '');

const BulkInspections = () => {
  const globalWarehouse = useRecoilValue(warehouseAtom);
  const warehouseOption = useRecoilValue(warehouseOptionsAtom);
  const [queryParams, setQueryParams] = useState<SearchQuery>({
    page: DEFAULT_PAGE,
    size: DEFAULT_SIZE,
    warehouseId: Number(globalWarehouse),
  });
  const [hasSearched, setHasSearched] = useState(false);
  const refCodeOptions = useRecoilValue(refCodeOptionsAtom);
  const [selectedBulkInspection, setSelectedBulkInspection] = useState<number[]>();

  const { data, mutate, isValidating } = useConditionalSWR<BulkInspectionResponse>(
    [`/bulk-inspections`, { ...queryParams, sort: 'id,desc' }],
    fetcher,
    hasSearched
  );
  const statusOptions = refCodeOptions?.bulkInspectionStatus || [];
  const pickingGroupTypeOptions = refCodeOptions?.pickingGroupType || [];
  const pickingTypeOptions = pickingGroupTypeOptions.filter(
    option => option.value === 'DONATION' || option.value === 'BULK'
  );

  const makeRowIndex = (data: BulkInspectionResponse) => {
    const { content, totalElements, size, number } = data;

    return content.map((item: BulkInspection, index: number) => ({
      ...item,
      rowIndex: totalElements - size * number - index,
      updatedUser:
        item.status != 'OPEN' ? convertUser(item.updatedUsername, item.updatedLoginId) : '',
      updatedAt: item.status != 'OPEN' ? item.updatedAt : '',
    }));
  };

  const handleInitClick = async () => {
    setQueryParams({
      page: DEFAULT_PAGE,
      size: DEFAULT_SIZE,
      warehouseId: Number(globalWarehouse),
    });
    await mutate();
  };

  const handleSearchClick = async (form: Form) => {
    const updatedForm = _.omitBy(form, o => o === defaultOption.value);
    delete updatedForm[`dateType`];

    setQueryParams(({ size }) => ({ ...updatedForm, size, page: DEFAULT_PAGE }));
    !hasSearched && setHasSearched(true);
    await mutate();
  };

  const handleRowClick: GridEventListener<GridEvents.rowClick> = params => {
    setSelectedBulkInspection(params.row);
  };

  return (
    <Page>
      <Typography variant="h2" sx={pageTitle}>
        대량/기증 집품검수현황 조회
      </Typography>
      <Filter gridTemplateColumns={gridTemplateColumns}>
        <Filter.Select
          label="창고"
          field="warehouseId"
          options={[defaultOption, ...warehouseOption]}
          labelGridColumn="1/2"
          selectGridColumn="2/4"
          defaultValue={globalWarehouse}
        />
        <Filter.Select
          label="집품구분"
          field="pickingGroupType"
          options={[defaultOption, ...pickingTypeOptions]}
          labelGridColumn="4/5"
          selectGridColumn="5/6"
        />
        <Filter.Select
          label="대량검수상태"
          field="status"
          options={[defaultOption, ...statusOptions]}
          labelGridColumn="6/7"
          selectGridColumn="7/9"
        />
        <Filter.Search
          label="집품그룹ID"
          field="pickingGroupSerialNumber"
          labelGridColumn="9/11"
          inputGridColumn="11/13"
        />
        <Filter.DateRangePickerWithSelect
          label="기간"
          rangeTypeOptions={dateRangeTypeOptions}
          gridRow={2}
          labelGridColumn="1/2"
          selectGridColumn="2/4"
          dateRangePickerGridColumn="4/6"
          rangeAmountSelectGridColumn="6/7"
        />
        <Filter.SearchWithSelect
          label="검색어"
          gridRow={2}
          searchOptions={searchKeywordOptions}
          labelGridColumn="7/8"
          selectGridColumn="8/10"
          inputGridColumn="10/13"
        />
        <Divider sx={styles.divider} />
        <Filter.DefaultButtonGroup
          gridColumn="1/-1"
          gridRow={5}
          onInitClick={handleInitClick}
          onLookupClick={handleSearchClick}
          sx={{ display: 'flex', justifyContent: 'center' }}
        />
      </Filter>
      <Stack spacing={1} sx={listBoardTheme.container}>
        <Box sx={listBoardTheme.header}>
          <Typography variant="h6" sx={{ color: 'text.primary' }}>
            대량검수 목록
          </Typography>
        </Box>
        <TightDataGridPro
          rows={hasSearched ? (data?.content ? makeRowIndex(data) : []) : []}
          columns={gridColumns()}
          paginationMode="server"
          rowCount={hasSearched ? data?.totalElements ?? 0 : 0}
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          onRowClick={handleRowClick}
          onPageChange={page => setQueryParams(params => ({ ...params, page }))}
          pageSize={queryParams.size ?? DEFAULT_SIZE}
          disableSelectionOnClick
          selectionModel={selectedBulkInspection}
          loading={isValidating}
          hasSearched={hasSearched}
        />
      </Stack>
    </Page>
  );
};

export default BulkInspections;

const styles = {
  divider: {
    gridRow: 4,
    gridColumn: '1/-1',
    pb: 1,
    mb: 1,
  },
};
