import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useSearchParams } from 'react-router-dom';
import _ from 'lodash';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { GridEventListener, GridEvents } from '@mui/x-data-grid-pro';
import Page from '../../../../../components/common/Layout/Page';
import Filter, { Form } from '../../../../../components/common/Filter';
import TightDataGridPro from '../../../../../components/common/TightDataGridPro';
import fetcher from '../../../../../libs/common/fetcher';
import convertUser from '../../../../../libs/common/convertUser';
import refCodeOptionsAtom from '../../../../../store/outbound/refCode.recoil';
import {
  warehouseAtom,
  warehouseOptionsAtom,
} from '../../../../../store/outbound/warehouse.recoil';
import { ROWS_PER_PAGE_OPTIONS } from '../../../../../consts/common/dataGrid';
import { DEFAULT_PAGE, DEFAULT_SIZE } from '../../../../../consts/common/pageAndSize';
import pageTitle from '../../../../../styles/pageTitle';
import { listBoardTheme } from '../../../../../styles/customedMuiTheme';
import usePopup from '../../../../../hooks/usePopup';
import useConditionalSWR from '../../../components/useConditionalSwr';
import LoadingModal from '../../../outbound/components/LoadingModal';
import ChevronDouble from '../../../outbound/components/ChevronDouble';
import ItemDataGrid from './components/ItemDataGrid';
import gridColumns from './gridColDef';
import { cancelTransfer, generateTransfer } from './services';

type TransferContent = {
  id: number;
  fromWarehouseId: number;
  toWarehouseId: number;
  inventoryTransferStatus: 'REQUEST' | 'PROCESSING' | 'CANCELED';
  shippingStatus: string;
  inventoryTransferType: string;
  inventoryTransferSerialNumber: string;
  shippingSerialNumber: string;
  desiredAt: string;
  createdAt: string;
  completedAt: string;
  skuCount: number;
  totalQuantity: number;
  weight: number;
  note: string;
  createdUserName: string;
  createdLoginId: string;
  delivery: string; //TODO: 필드값 추가되면 확인요망
  totalPrice: string; //TODO: 필드값 추가되면 확인요망
  registeredAt: string; //TODO: 필드값 추가되면 확인요망
};

type Transfer = {
  content: TransferContent[];
  totalElements: number;
  size: number;
  number: number;
};

export type SelectedTransferContent = TransferContent & {
  rowIndex: number;
  createdUser: string;
};

type SearchQuery = {
  page?: number;
  size?: number;
  fromWarehouseId?: number;
  inventoryTransferId?: string;
};

const gridTemplateColumns = [
  '50px',
  'minmax(45px, 0.6fr)',
  'minmax(45px, 0.7fr)',
  '50px',
  'minmax(45px, 1.7fr)',
  'minmax(45px, 1.1fr)',
  '50px',
  'minmax(45px, 0.7fr)',
  'minmax(45px, 0.7fr)',
  '50px',
  'minmax(45px, 0.9fr)',
  'minmax(45px, 0.9fr)',
].reduce((prevValue, labelWidth) => prevValue + labelWidth + ' ', '');

const defaultOption = { displayName: '전체', value: 'ALL' };
const dateRangeTypeOptions = [
  { displayName: '출고지시 생성일', field: 'inventoryTransferCreatedAt' },
  { displayName: '출고완료일', field: 'shippingCompletedAt' },
];

const searchKeywordOptions = [
  { displayName: '출고ID ', field: 'shippingSerialNumber' },
  { displayName: '대체출고지시ID', field: 'inventoryTransferId' },
  { displayName: '등록자', field: 'createdLoginId' },
];

const TransferSearches = () => {
  const [searchParams] = useSearchParams();
  const serialNumber = searchParams.get('serialNumber');

  const globalWarehouse = useRecoilValue(warehouseAtom);
  const warehouseOption = useRecoilValue(warehouseOptionsAtom);
  const refCodeOptions = useRecoilValue(refCodeOptionsAtom);
  const shippingStatusOptions = refCodeOptions?.shippingStatus || [];
  const InventoryTransferStatusOptions = refCodeOptions?.inventoryTransferStatus || [];

  const [selectedTransfer, setSelectedTransfer] = useState<SelectedTransferContent>();
  const [hasSearched, setHasSearched] = useState(false);
  const [serialNumberSettingStep, setSerialNumberSettingStep] = useState(0);
  const [selectedRowId, setSelectedRowId] = useState<number>(-1);
  const [queryParams, setQueryParams] = useState<SearchQuery>({
    page: DEFAULT_PAGE,
    size: DEFAULT_SIZE,
    fromWarehouseId: Number(globalWarehouse),
  });

  const { showDialog, showSnackbar, showAlert, showErrorDialog } = usePopup();

  const searchWithSelectRef = useRef<{
    inputText: (text: string) => void;
    selectOption: (option: string) => void;
  }>(null);

  useEffect(() => {
    if (serialNumber && serialNumberSettingStep === 0) {
      searchWithSelectRef.current?.selectOption('inventoryTransferId');
      setSerialNumberSettingStep(1);
      return;
    }

    if (serialNumber && serialNumberSettingStep === 1) {
      searchWithSelectRef.current?.inputText(serialNumber);
      setSerialNumberSettingStep(2);
      return;
    }

    if (serialNumber && serialNumberSettingStep === 2) {
      setHasSearched(true);
      setQueryParams(queryParams => ({ ...queryParams, inventoryTransferId: serialNumber }));
    }
  }, [serialNumber, serialNumberSettingStep]);

  const { data, mutate, isValidating } = useConditionalSWR<Transfer>(
    [`/inventory-transfers`, { ...queryParams, sort: 'id,desc' }],
    fetcher,
    hasSearched
  );

  const handleInitClick = async () => {
    setQueryParams({
      page: DEFAULT_PAGE,
      size: DEFAULT_SIZE,
      fromWarehouseId: Number(globalWarehouse),
    });
    await mutate();
  };

  const handleSearchClick = async (form: Form) => {
    const updatedForm = _.omitBy(form, o => o === defaultOption.value);
    delete updatedForm[`dateType`];

    setQueryParams(({ size }) => ({ ...updatedForm, size, page: DEFAULT_PAGE }));
    setSelectedTransfer(undefined);
    !hasSearched && setHasSearched(true);
    await mutate();
  };

  const handleRowClick: GridEventListener<GridEvents.rowClick> = params => {
    setSelectedTransfer(item => {
      return item?.id === params.row.id ? undefined : params.row;
    });
  };

  const generateRowData = (data: Transfer) => {
    const { content, totalElements, size, number } = data;
    return content.map((item: TransferContent, index: number) => ({
      ...item,
      rowIndex: totalElements - size * number - index,
      createdUser: convertUser(item.createdUserName, item.createdLoginId),
    }));
  };

  const changeSelectedRowId = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedRowId(Number(event.target.value));
  };

  const handleCancelTransfer = () => {
    if (selectedRowId < 0) {
      showAlert({ message: '취소할 항목을 선택해 주세요.' });
      return;
    }

    const selectedRow = data?.content.find(row => row.id === selectedRowId);

    showDialog({
      message: '대체출고지시를 취소하시겠습니까?',
      buttons: [
        {
          text: '취소',
        },
        {
          text: '확인',
          marked: true,
          onClick: async () => {
            if (!selectedRow) return;

            const response = await cancelTransfer(selectedRow.id);
            if (response?.status === 200) {
              showSnackbar({
                message: '대체출고지시서가 취소완료되었습니다.',
                severity: 'success',
              });
              setSelectedRowId(-1);
              await mutate();
            } else {
              showErrorDialog({
                title: '대체출고지시 취소 실패',
                errorMessage: response?.data?.errorMessage,
                buttons: [{ text: '확인' }],
              });
            }
            showSnackbar({ message: '대체출고지시가 취소되었습니다.', severity: 'success' });
          },
        },
      ],
    });
  };

  const handleGenerateTransfer = () => {
    if (selectedRowId < 0) {
      showAlert({ message: '생성할 항목을 선택해 주세요.' });
      return;
    }

    const selectedRow = data?.content.find(row => row.id === selectedRowId);
    if (selectedRow && new Date(selectedRow.desiredAt.split(' ')[0] + ' 00:00:00') > new Date()) {
      showAlert({ message: '대체출고 요청일자가 도래한 대체출고지시건을 선택해 주세요.' });
      return;
    }
    if (selectedRow && selectedRow.inventoryTransferStatus === 'CANCELED') {
      showAlert({ message: '취소된 대체출고지시건은 대체출고지시서를 생성할 수 없습니다.' });
      return;
    }

    showDialog({
      message: '대체출고지시서를 생성하시겠습니까?',
      buttons: [
        {
          text: '취소',
        },
        {
          text: '확인',
          marked: true,
          onClick: async () => {
            if (!selectedRow) return;

            const response = await generateTransfer(selectedRow.id);
            if (response?.status === 200) {
              showSnackbar({
                message: '대체출고지시서가 생성완료되었습니다.',
                severity: 'success',
              });
              setSelectedRowId(-1);
              await mutate();
            } else {
              showErrorDialog({
                title: '대체출고지시서 생성 실패',
                errorMessage: response?.data?.errorMessage,
                buttons: [{ text: '확인' }],
              });
              setSelectedRowId(-1);
            }
          },
        },
      ],
    });
  };

  return (
    <Page>
      <Typography variant="h2" sx={pageTitle}>
        대체출고지시서 관리
      </Typography>
      <Filter gridTemplateColumns={gridTemplateColumns}>
        <Filter.Select
          label="출고창고"
          field="fromWarehouseId"
          options={[defaultOption, ...warehouseOption]}
          labelGridColumn="1/2"
          selectGridColumn="2/4"
          defaultValue={globalWarehouse}
        />
        <Filter.Select
          label="입고창고"
          field="toWarehouseId"
          options={[defaultOption, ...warehouseOption]}
          labelGridColumn="4/5"
          selectGridColumn="5/7"
        />
        <Filter.Select
          label="지시상태"
          field="inventoryTransferStatus"
          options={[defaultOption, ...InventoryTransferStatusOptions]}
          labelGridColumn="7/8"
          selectGridColumn="8/10"
        />
        <Filter.Select
          label="출고상태"
          field="shippingStatus"
          options={[defaultOption, ...shippingStatusOptions]}
          labelGridColumn="10/11"
          selectGridColumn="11/13"
        />
        <Filter.DateRangePickerWithSelect
          label="기간"
          rangeTypeOptions={dateRangeTypeOptions}
          gridRow={2}
          labelGridColumn="1/2"
          selectGridColumn="2/4"
          dateRangePickerGridColumn="4/6"
          rangeAmountSelectGridColumn="6/7"
        />
        <Filter.SearchWithSelect
          ref={searchWithSelectRef}
          label="검색어"
          gridRow={2}
          searchOptions={searchKeywordOptions}
          labelGridColumn="7/8"
          selectGridColumn="8/10"
          inputGridColumn="10/13"
        />
        <Divider sx={styles.divider} />
        <Filter.DefaultButtonGroup
          gridColumn="1/-1"
          gridRow={4}
          onInitClick={handleInitClick}
          onLookupClick={handleSearchClick}
          sx={{ display: 'flex', justifyContent: 'center' }}
        />
      </Filter>
      <Stack spacing={1} sx={listBoardTheme.container}>
        <Box sx={listBoardTheme.header}>
          <Typography variant="h6" sx={{ color: 'text.primary' }}>
            대체출고지시서 목록
          </Typography>
          <Box>
            <Button
              sx={listBoardTheme.headerButton}
              variant="outlined"
              onClick={handleCancelTransfer}
            >
              {`대체출고지시 취소`}
            </Button>
            <Button
              sx={listBoardTheme.headerButton}
              variant="contained"
              onClick={handleGenerateTransfer}
            >
              {`대체출고지시서 생성`}
            </Button>
          </Box>
        </Box>
        <Box sx={{ height: '280px' }}>
          <TightDataGridPro
            rows={hasSearched && data?.content ? generateRowData(data) : []}
            columns={gridColumns(changeSelectedRowId, selectedRowId)}
            paginationMode="server"
            rowCount={hasSearched ? data?.totalElements ?? 0 : 0}
            rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            onRowClick={handleRowClick}
            onPageChange={page => setQueryParams(params => ({ ...params, page }))}
            pageSize={queryParams.size ?? DEFAULT_SIZE}
            onPageSizeChange={size => setQueryParams(params => ({ ...params, size }))}
            loading={isValidating}
            hasSearched={hasSearched}
          />
        </Box>
        <LoadingModal isLoading={false} message="출고지시서 생성중..." />
        {selectedTransfer && (
          <>
            <Box sx={{ display: 'flex', justifyContent: 'center', padding: 1 }}>
              <ChevronDouble />
            </Box>
            <ItemDataGrid selectedContent={selectedTransfer} warehouseOption={warehouseOption} />
          </>
        )}
      </Stack>
    </Page>
  );
};

export default TransferSearches;

const styles = {
  divider: {
    gridRow: 3,
    gridColumn: '1/-1',
    pb: 1,
    mb: 1,
  },
};
