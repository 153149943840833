import { useRecoilValue } from 'recoil';
import type { GridColDef } from '@mui/x-data-grid-pro';
import refCodeOptionsAtom from '../../../../../store/outbound/refCode.recoil';
import { warehouseOptionsAtom } from '../../../../../store/outbound/warehouse.recoil';
import { useCenter } from '../../../../../queries/useCenter';

const gridColumns = () => {
  const refCodeOptions = useRecoilValue(refCodeOptionsAtom);
  const warehouseOptions = useRecoilValue(warehouseOptionsAtom);
  const { data: centerOptions = [] } = useCenter();
  const isActiveOptions = refCodeOptions?.activeType;
  console.log(warehouseOptions);
  const columns: GridColDef[] = [
    {
      field: 'rowIndex',
      headerName: 'NO',
      minWidth: 20,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'centerId',
      headerName: '센터명',
      flex: 1,
      minWidth: 90,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) => {
        return centerOptions.find(option => option.id === value)?.name;
      },
    },
    {
      field: 'name',
      headerName: '창고명',
      flex: 1,
      minWidth: 90,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'locationCode',
      headerName: '로케이션코드',
      flex: 1,
      minWidth: 100,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'code',
      headerName: '창고코드',
      flex: 1,
      minWidth: 100,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'isActive',
      headerName: '사용여부',
      flex: 1,
      minWidth: 80,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) => {
        return isActiveOptions?.find(option => option.value === value)?.displayName;
      },
    },
    {
      field: 'updatedUser',
      headerName: '수정자',
      flex: 1,
      minWidth: 120,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'updatedAt',
      headerName: '수정일시',
      flex: 1,
      minWidth: 160,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
  ];
  return columns;
};

export default gridColumns;
