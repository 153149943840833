import React, { Fragment, ReactNode, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import StarOutlineRoundedIcon from '@mui/icons-material/StarOutlineRounded';
import ListItemText from '@mui/material/ListItemText';
import LaunchRoundedIcon from '@mui/icons-material/LaunchRounded';
import List from '@mui/material/List';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Divider from '@mui/material/Divider';
import Collapse from '@mui/material/Collapse';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import Box from '@mui/material/Box';
import { SIDE_BAR_WIDTH as drawerWidth } from 'consts/common/layout';
import { REACT_APP_BASE_PATH, REACT_APP_STAGE } from 'consts/common/environment';
import { COLORS } from 'consts/common/colors';
import { favoriteMenusAtom } from 'store/common/menu.recoil';
import { GmsMenuItem } from './menus';

export type MenuTab = 'menu' | 'fav';
type MenuPanelProps = {
  menuList: GmsMenuItem[];
  highlightText: string;
  isShowAllMenu: boolean | undefined;
};

const openSubMenusStorageKey = 'openSubMenus';

const MenuPanel = (props: MenuPanelProps) => {
  const { menuList, highlightText, isShowAllMenu } = props;
  const loginUser =
    typeof window !== 'undefined' ? (localStorage.getItem('loginUser') as string) : '';
  const [openSubMenus, setOpenSubMenus] = useState<string[]>(
    REACT_APP_STAGE === 'dev' ? [] : ['출고']
  );
  const [favoriteMenus, setFavoriteMenus] = useRecoilState(favoriteMenusAtom);
  const { pathname } = useLocation();

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem(openSubMenusStorageKey) as string) ?? [];
    if (items) {
      setOpenSubMenus([...items]);
    }
  }, []);

  useEffect(() => {
    if (isShowAllMenu === undefined) return;

    if (isShowAllMenu) {
      setOpenSubMenus(menuList.map(menu => menu.title));
    } else {
      setOpenSubMenus([]);
    }
  }, [isShowAllMenu, menuList]);

  useEffect(() => {
    const menu = JSON.parse(localStorage.getItem(`favoriteMenus_${loginUser}`) as string) ?? [];
    if (menu) {
      setFavoriteMenus(menu);
    }
  }, [loginUser]);

  if (!menuList) {
    return <List component="nav" sx={{ width: drawerWidth }}></List>;
  }

  const handleFavoriteClick = (menu: GmsMenuItem) => {
    if (favoriteMenus.find(m => m.title === menu.title)) {
      removeMenuFromFavorites(menu);
    } else {
      addMenuToFavorites(menu);
    }
  };

  const handleOpenNewTab = (route?: string) => {
    route && window.open(REACT_APP_BASE_PATH + route);
  };

  const addMenuToFavorites = ({ title, route }: GmsMenuItem) => {
    setFavoriteMenus(prev => [...prev, { title, route }]);
    //TODO: localStorage 대신 백엔드 API를 활용할 수 있도록 업데이트 필요(사용자 별 즐겨찾기 메뉴 저장 등)
    localStorage.setItem(
      `favoriteMenus_${loginUser}`,
      JSON.stringify([...favoriteMenus, { title, route }])
    );
  };

  const removeMenuFromFavorites = (menu: GmsMenuItem) => {
    const filteredFavMenus = favoriteMenus.filter(m => m.title !== menu.title);
    setFavoriteMenus(filteredFavMenus);
    localStorage.setItem(`favoriteMenus_${loginUser}`, JSON.stringify(filteredFavMenus));
  };

  const expandShrinkSubmenu = (menuItem: GmsMenuItem) => {
    const openMenu =
      openSubMenus.length === 0 || !openSubMenus.includes(menuItem.title)
        ? [...openSubMenus, menuItem.title]
        : openSubMenus.filter(menu => menu !== menuItem.title);
    localStorage.setItem(openSubMenusStorageKey, JSON.stringify(openMenu));
    setOpenSubMenus(openMenu);
  };

  const renderSubMenus = (depth: number, subMenus?: GmsMenuItem[]): ReactNode => {
    if (!subMenus || subMenus.length === 0) return null;

    return subMenus.map(menu => {
      const isLeafMenu = !menu.subMenus || menu.subMenus.length === 0;
      const isFavMenu = favoriteMenus.some(fav => fav.title === menu.title);
      const isSelected = isLeafMenu && pathname === menu.route;

      if (!menu.shouldShow) return null;

      if (!isLeafMenu) {
        return (
          <Box
            key={menu.title}
            sx={{
              py: 0,
              bgcolor: isSelected ? COLORS.veryDarkGrey : undefined,
            }}
          >
            {renderMenuName(menu.title, isLeafMenu, depth)}
            <List sx={{ pl: 0, pt: 0, pb: 1 }}>{renderSubMenus(depth + 1, menu?.subMenus)}</List>
          </Box>
        );
      }

      return (
        <Fragment key={menu.title}>
          <ListItemButton
            sx={{ pl: depth * 0.5, py: 0, bgcolor: isSelected ? COLORS.veryDarkGrey : undefined }}
            disabled={isLeafMenu && !menu.route}
            onClick={() => {
              isSelected && window.location.reload();
            }}
          >
            {renderFavoriteButton(menu, isFavMenu, depth)}
            <Link to={menu?.route ?? '/404'}>{renderMenuName(menu.title, isLeafMenu, 0)}</Link>
            {menu.route ? renderLaunchButton(menu.route) : null}
          </ListItemButton>
        </Fragment>
      );
    });
  };

  const renderFavoriteButton = (menu: GmsMenuItem, isFavMenu: boolean, depth: number) => (
    <ListItemIcon
      sx={{ ...styles.favButtonStyle, pl: (depth + 2) * 0.5 }}
      onClick={() => handleFavoriteClick(menu)}
    >
      {isFavMenu ? (
        <StarRoundedIcon sx={{ color: COLORS.ktown4uLight }} />
      ) : (
        <StarOutlineRoundedIcon sx={{ color: COLORS.veryLightGrey }} />
      )}
    </ListItemIcon>
  );

  const renderLaunchButton = (route: string) => (
    <ListItemIcon sx={styles.launchButtonStyle} onClick={() => handleOpenNewTab(route)}>
      <LaunchRoundedIcon fontSize="small" sx={styles.launchIconStyle} />
    </ListItemIcon>
  );

  const renderMenuName = (title: string, isLeafMenu: boolean, depth: number) => (
    <ListItemText
      primary={highlightTextInMenu(title, highlightText, depth)}
      sx={{
        m: 0,
        pl: isLeafMenu ? 0 : (depth + 4) * 0.5,
        py: 0.5,
      }}
      primaryTypographyProps={{
        sx: {
          fontWeight: isLeafMenu ? 'regular' : 'bold',
        },
      }}
    />
  );

  const highlightTextInMenu = (text: string, highlightText: string, depth: number) => {
    if (!highlightText) return text;

    const parts = text.split(new RegExp(`(${highlightText})`, 'gi'));

    return parts.map((part, index) =>
      part.toLowerCase() === highlightText.toLowerCase() && depth === 0 ? (
        <span key={index} style={{ color: COLORS.ktown4uLight }}>
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  return (
    <List component="nav" sx={{ width: drawerWidth }}>
      {menuList.map(menuItem => {
        if (!menuItem.shouldShow) {
          return null;
        }

        const arrowIcon = openSubMenus.includes(menuItem.title) ? <ExpandLess /> : <ExpandMore />;

        return (
          <div key={menuItem.title}>
            <ListItemButton onClick={() => expandShrinkSubmenu(menuItem)}>
              <ListItemText
                primary={menuItem.title}
                primaryTypographyProps={{ sx: { fontWeight: 'bold' } }}
              />
              {menuItem.subMenus ? arrowIcon : null}
            </ListItemButton>
            <MenuDivider />
            <Collapse in={openSubMenus.includes(menuItem.title)}>
              <List>{renderSubMenus(1, menuItem.subMenus)}</List>
              <MenuDivider />
            </Collapse>
          </div>
        );
      })}
    </List>
  );
};

export default MenuPanel;

export const FavoritePanel = () => {
  const [favoriteMenus, setFavoriteMenus] = useRecoilState<GmsMenuItem[]>(favoriteMenusAtom);
  const loginUser =
    typeof window !== 'undefined' ? (localStorage.getItem('loginUser') as string) : '';
  const { pathname } = useLocation();

  const removeFromFavorites = (menu: GmsMenuItem) => {
    const filteredFavMenus = favoriteMenus.filter(m => m.title !== menu.title);
    setFavoriteMenus(filteredFavMenus);
    localStorage.setItem(`favoriteMenus_${loginUser}`, JSON.stringify(filteredFavMenus));
  };

  const handleOpenNewTab = (route?: string) => {
    route && window.open(REACT_APP_BASE_PATH + route);
  };

  return (
    <List sx={{ width: drawerWidth }}>
      {favoriteMenus.map(menu => (
        <div key={menu.title}>
          <ListItemButton
            sx={{ py: 0, bgcolor: pathname === menu.route ? COLORS.veryDarkGrey : undefined }}
          >
            <ListItemIcon sx={styles.favButtonStyle} onClick={() => removeFromFavorites(menu)}>
              <StarRoundedIcon sx={{ color: COLORS.ktown4uLight }} />
            </ListItemIcon>
            <Link to={menu.route ?? '/404'}>
              <ListItemText primary={menu.title} />
            </Link>
            <ListItemIcon
              sx={styles.launchButtonStyle}
              onClick={() => handleOpenNewTab(menu.route)}
            >
              <LaunchRoundedIcon fontSize="small" sx={styles.launchIconStyle} />
            </ListItemIcon>
          </ListItemButton>
          <MenuDivider />
        </div>
      ))}
    </List>
  );
};

const MenuDivider = () => <Divider variant="middle" light sx={{ borderColor: COLORS.lightGrey }} />;

const styles = {
  favButtonStyle: { minWidth: 24 },
  launchIconStyle: { color: COLORS.darkLine },
  launchButtonStyle: {
    minWidth: 20,
    ml: 0.5,
    justifyContent: 'flex-end',
  },
};
