import { useEffect } from 'react';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import { packingAtom, stepAtom } from 'store/outbound/packing.recoil';
import borderedBox from 'styles/borderedBox';
import pageTitle from 'styles/pageTitle';
import { barcodeAtom } from '../../../../store/common/barcode.recoil';
import CompletionNotice from './components/CompletionNotice';
import ToteCodeLine from './components/ToteCodeLine';
import ChangeToteLine from './components/ChangeToteLine';
import Page from 'components/common/Layout/Page';
import Timeline from '../components/Timeline';
import FullScreenButton from '../components/FullScreenButton';

export const LAST_STEP = 1;

const PackingChangeTote = () => {
  const step = useRecoilValue(stepAtom);
  const packing = useRecoilValue(packingAtom);
  const resetPacking = useResetRecoilState(packingAtom);
  const resetBarcode = useResetRecoilState(barcodeAtom);
  const resetStep = useResetRecoilState(stepAtom);

  useEffect(() => {
    if (!packing) {
      return;
    }
    resetPacking();
    resetBarcode();
    resetStep();
  }, [resetPacking, resetBarcode, resetStep]);

  return (
    <>
      <Page>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h2" sx={pageTitle}>
            토트이관 관리
          </Typography>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <FullScreenButton />
          </Box>
        </Box>
        <Box
          sx={{
            ...borderedBox,
            flex: 1,
            height: '90vh',
            padding: '32px',
          }}
        >
          <Timeline currentStep={step} sx={{ padding: 0, margin: 0 }}>
            <ToteCodeLine />
            <ChangeToteLine />
          </Timeline>
          {step === LAST_STEP + 1 && <CompletionNotice />}
        </Box>
      </Page>
    </>
  );
};

export default PackingChangeTote;
