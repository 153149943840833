import { axiosV2 } from 'libs/common/fetcher';
import { AxiosError } from 'axios';
import { datadogRum } from '@datadog/browser-rum';
import { BulkPacking } from '../../../../../store/outbound/bulkPacking.recoil';
import { errorType } from '../../../../../types/common';
import { WaybillRequest } from '../index.page';

export const getBulkPacking = async (barcode: string) => {
  try {
    return await axiosV2.get<BulkPacking>(`/bulk-packings/picking-groups/${barcode}`);
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    datadogRum.addAction(`출고지시서 생성 실패 : ${error1.response?.data}`);
    return error1.response;
  }
};

type Params = { width: number; length: number; height: number; volumetricDivisor: number };

export const getEstimatedDeliveryPriceOfBulk = async (params: Params) => {
  try {
    return await axiosV2.get(`/packings/estimated-delivery-price/bulk`, { params });
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    datadogRum.addAction(`예상배송비 조회 실패 : ${error1.response?.data}`);
    return error1.response;
  }
};

export const updateBulkPackingStatus = async (bulkPackingId: number | undefined) => {
  try {
    return await axiosV2.post(`/bulk-packings/${bulkPackingId}/scan`);
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    return error1.response;
  }
};

export const requestWaybills = async (
  bulkPackingId: number | undefined,
  waybillRequest: {
    boxLength: number | undefined;
    gramWeight: string;
    checkWeight: boolean;
    boxWidth: number | undefined;
    boxHeight: number | undefined;
    boxCode: string | undefined;
    boxId: number | string | undefined;
  }
) => {
  try {
    return await axiosV2.post(`/bulk-packings/${bulkPackingId}/request-waybills`, waybillRequest);
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    return error1.response;
  }
};

export const getPackingIdsToRequestWaybill = async (bulkPackingId: number | undefined) => {
  try {
    return await axiosV2.get(`/bulk-packings/${bulkPackingId}/request-waybills/packing-ids`);
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    datadogRum.addAction(`운송장 출력 실패 : ${error1.response?.data}`);
    return error1.response;
  }
};

export const updateBulkPackingStatusToRegistering = async (
  bulkPackingId: number,
  waybillRequest: WaybillRequest
) => {
  try {
    return await axiosV2.post(
      `/bulk-packings/${bulkPackingId}/request-waybills/status`,
      waybillRequest
    );
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    return error1.response;
  }
};

export const requestWaybill = async (
  bulkPackingId: number | undefined,
  packingId: number | undefined,
  waybillRequest: WaybillRequest
) => {
  try {
    return await axiosV2.post(
      `/bulk-packings/${bulkPackingId}/request-waybills/${packingId}`,
      waybillRequest
    );
  } catch (error) {
    if ((error as AxiosError).response?.status === 404) {
      return { status: 404 };
    }

    return { status: (error as AxiosError).response?.status };
  }
};
