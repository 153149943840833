import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import BackspaceIcon from '@mui/icons-material/Backspace';
import { COLORS } from '../../../consts/common/colors';

const NumberKeyboard = ({ handleButtonClick }: { handleButtonClick: (input: string) => void }) => {
  const keys = [
    ['7', '8', '9'],
    ['4', '5', '6'],
    ['1', '2', '3'],
    ['del', '0', 'enter'],
  ];

  return (
    <Stack sx={styles.numberKeyContainer}>
      {keys.map((row, rowIndex) => (
        <Stack key={`row-${rowIndex}`} flexDirection="row">
          {row.map(key => (
            <Button key={key} sx={styles.numberKey} onClick={() => handleButtonClick(key)}>
              {key === 'del' ? <BackspaceIcon /> : key === 'enter' ? '확인' : key}
            </Button>
          ))}
        </Stack>
      ))}
    </Stack>
  );
};

export default NumberKeyboard;

const styles = {
  numberKeyContainer: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    zIndex: 100,
    backgroundColor: COLORS.veryLightGrey,
    width: 260,
    height: 260,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 1,
    border: `1px solid ${COLORS.lightLine}`,
  },
  numberKey: {
    width: 80,
    height: 60,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '4px',
    border: `1px solid ${COLORS.lightLine}`,
    backgroundColor: COLORS.white,
  },
};
