import { AxiosError } from 'axios';
import { datadogRum } from '@datadog/browser-rum';
import { axiosV2 } from '../../../../../../libs/common/fetcher';
import { errorType } from '../../../../../../types/common';

export const getExpectedReceivingDetails = async (inventoryTransferId: number | undefined) => {
  try {
    return await axiosV2.get(`/inventory-transfers/${inventoryTransferId}/receivings`);
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    datadogRum.addAction(`입고확정 상세 조회 실패 : ${error1.response?.data}`);
    return error1.response;
  }
};

type itemToReceive = {
  itemId: number;
  quantity: number;
  cost: number;
};

export type RequestBodyForCreateReceiving = {
  receivingType: string;
  inventoryTransferId: number;
  supplierId: number;
  receivedDate: string;
  warehouseId: number;
  itemsToReceive: itemToReceive[];
};

export const createReceiving = async (
  requestBody: RequestBodyForCreateReceiving,
  inventoryTransferId: number
) => {
  try {
    return await axiosV2.post(
      `/inventory-transfers/${inventoryTransferId}/receivings/create`,
      requestBody
    );
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    datadogRum.addAction(`입고등록 상세 저장 실패 : ${error1.response?.data}`);
    return error1.response;
  }
};
