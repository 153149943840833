import React, { ChangeEvent, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { GridSelectionModel } from '@mui/x-data-grid-pro';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import type { SelectChangeEvent } from '@mui/material';
import TightDataGridPro from '../../../../../../components/common/TightDataGridPro';
import useConditionalSWR from '../../../../components/useConditionalSwr';
import Table, { TableCell, TableRow } from '../../../../components/Table';
import usePopup from '../../../../../../hooks/usePopup';
import useShouldMountAppBarAndDrawer from '../../../../../../hooks/useShouldMountAppBarAndDrawer';
import fetcher from '../../../../../../libs/common/fetcher';
import { getFinalUrl } from '../../../../../../consts/outbound/imageUrl';
import { buttonTheme, inputTheme, selectTheme } from '../../../../../../styles/customedMuiTheme';
import gridColDef from './gridColDef';
import { SearchedItem } from '../../../../../../types/common';
import ImageZoomModal from '../../../../../../components/ImageZoomModal';
import { useRecoilValue } from 'recoil';
import { warehouseOptionsAtom } from '../../../../../../store/outbound/warehouse.recoil';

type SelectOption = {
  id: number;
  value: string;
  displayName: string;
};

const searchTypeOptions: SelectOption[] = [
  { id: 0, value: 'itemName', displayName: '아이템명' },
  { id: 1, value: 'itemCode', displayName: '아이템코드' },
  { id: 2, value: 'barCode', displayName: '바코드' },
];

const SearchItem = () => {
  useShouldMountAppBarAndDrawer();
  const [searchParams] = useSearchParams();
  const globalWarehouse = searchParams.get('warehouseId');

  const { showAlert } = usePopup();

  const warehouseOption = useRecoilValue(warehouseOptionsAtom);
  const [items, setItems] = useState<SearchedItem[]>([]);
  const [searchType, setSearchType] = useState<SelectOption | undefined>(searchTypeOptions[0]);
  const [searchText, setSearchText] = useState('');
  const [hasSearched, setHasSearched] = useState(false);
  const [selectedItemIds, setSelectedItemIds] = useState<number[]>([]);
  const [imageUrl, setImageUrl] = useState<string>('');

  const { data, isValidating } = useConditionalSWR<SearchedItem[]>(
    [
      `/inventory-transfers/goods/search`,
      { warehouseId: globalWarehouse, [searchType?.value ?? '']: searchText, sort: 'id,desc' },
    ],
    fetcher,
    hasSearched
  );

  useEffect(() => {
    !!data && setItems(data);
    setHasSearched(false);
  }, [data]);

  const handleSelectChange = async (e: SelectChangeEvent) => {
    const selectedOption = searchTypeOptions.find(option => option.value === e.target.value);
    setSearchText('');
    setSearchType(selectedOption);
  };

  const handleTextFieldChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const handleDefaultButton = () => {
    defaultStates();
  };

  const defaultStates = () => {
    setSearchType(searchTypeOptions[0]);
    setSearchText('');
    setHasSearched(false);
  };

  const handleSearchButton = () => {
    submitSearch();
  };

  const handleTextFieldKeyDown = () => {
    submitSearch();
  };

  const submitSearch = () => {
    if (isEmpty(searchText.trim())) {
      showAlert({ message: '검색어를 입력해주세요.' });
      return;
    }
    setHasSearched(true);
  };

  const handleSelectionModelChange = (ids: GridSelectionModel) => {
    const selectedIds = ids as number[];
    const selectedItems = gatherSelectedItemsByIds(items, selectedIds);

    if (!selectedItems.every(item => item?.availableQuantity && item.availableQuantity > 0)) {
      showAlert({ message: '창고가용수량이 1 이상인 아이템을 선택해 주세요.' });
      return;
    }

    setSelectedItemIds(selectedIds as number[]);
  };

  const handleSelectionComplete = async () => {
    if (selectedItemIds.length === 0) {
      showAlert({ message: '아이템을 선택해 주세요.' });
      return;
    }

    const selectedItems = gatherSelectedItemsByIds(items, selectedItemIds);

    window.opener.postMessage(selectedItems, window.location.origin);
    window.close();
  };

  const makeRows = (items: SearchedItem[]) => {
    return items?.length > 0
      ? items.map((item, index) => ({
          ...item,
          rowNo: index + 1,
          id: item.goodsId,
        }))
      : [];
  };

  return (
    <>
      <Stack
        spacing={3}
        sx={{
          minHeight: '100vh',
          padding: '32px',
          backgroundColor: 'white',
          justifyContent: 'space-between',
        }}
      >
        <Stack spacing={1.5}>
          <Typography variant="h5" sx={{ fontSize: '28px', fontWeight: 700 }}>
            {`[${
              warehouseOption.find(option => option.id === Number(globalWarehouse))?.displayName ??
              ''
            }] 아이템 검색`}
          </Typography>
          <Table>
            <TableRow height="36px">
              <TableCell
                label="검색어"
                valueSx={{ width: '100%' }}
                value={
                  <Stack direction="row">
                    <FormControl sx={{ minWidth: 120 }}>
                      <Select
                        sx={styles.select}
                        value={searchType?.value ?? ''}
                        onChange={handleSelectChange}
                      >
                        {searchTypeOptions.map(option => (
                          <MenuItem key={option.id} value={option.value}>
                            <Typography sx={{ fontSize: 13 }}>{option.displayName}</Typography>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl sx={{ minWidth: 120, ...inputTheme.medium, ml: 1, flex: 1 }}>
                      <TextField
                        hiddenLabel
                        variant="outlined"
                        fullWidth
                        autoFocus
                        value={searchText}
                        onChange={handleTextFieldChange}
                        onKeyDown={e => {
                          if (e.key === 'Enter') {
                            handleTextFieldKeyDown();
                          }
                        }}
                      />
                    </FormControl>
                  </Stack>
                }
              />
              <TableCell
                label={''}
                labelSx={{ display: 'none' }}
                valueSx={{ width: '100%' }}
                value={
                  <Stack direction="row" justifyContent="flex-end">
                    <Stack direction="row" sx={{ gap: 1 }} justifySelf={'flex-end'}>
                      <Button
                        variant="outlined"
                        onClick={handleDefaultButton}
                        sx={buttonTheme.medium}
                      >
                        초기화
                      </Button>
                      <Button
                        sx={buttonTheme.medium}
                        variant="contained"
                        onClick={handleSearchButton}
                      >
                        조회
                      </Button>
                    </Stack>
                  </Stack>
                }
              />
            </TableRow>
          </Table>
        </Stack>
        <Stack spacing={1.5} flexGrow={1}>
          <Typography variant="h6" sx={{ fontSize: '20px', fontWeight: 700 }}>
            아이템 목록
          </Typography>
          <Box sx={{ height: 520 }}>
            <TightDataGridPro
              rows={makeRows(items)}
              columns={gridColDef}
              loading={isValidating}
              hideFooter
              checkboxSelection
              disableSelectionOnClick
              selectionModel={selectedItemIds}
              onSelectionModelChange={handleSelectionModelChange}
              onCellClick={({ field, row }) => {
                if (field === 'itemImageUrl') setImageUrl(getFinalUrl(row.itemImageUrl));
              }}
            />
          </Box>
        </Stack>
        <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
          <Button size="small" variant="outlined" onClick={() => window.close()}>
            취소
          </Button>
          <Button size="small" variant="contained" onClick={handleSelectionComplete}>
            선택
          </Button>
        </Box>
      </Stack>
      <ImageZoomModal imageUrl={imageUrl} onClose={() => setImageUrl('')} />
    </>
  );
};

export default SearchItem;

const isEmpty = (item: string) => !item;

const gatherSelectedItemsByIds = (items: SearchedItem[], ids: number[]) => {
  return ids.map(id => {
    return items.find(item => item.goodsId === id);
  });
};

const styles = {
  select: {
    '& .MuiSelect-select': {
      padding: 1,
    },
    ...selectTheme.medium,
  },
};
