import { ChangeEvent, KeyboardEvent, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import type { SxProps } from '@mui/material/styles';
import { bulkPackingIsLoadingAtom } from '../../../../store/outbound/bulkPacking.recoil';
import useScale from '../packing/hooks/useScale';
import LoadingButton from '@mui/lab/LoadingButton';
import { useRecoilValue } from 'recoil';
import Box from '@mui/material/Box';
import KeyboardIcon from '@mui/icons-material/Keyboard';
import InputAdornment from '@mui/material/InputAdornment';
import { COLORS } from '../../../../consts/common/colors';
import NumberKeyboard from '../../components/NumberKeyboard';

type Props = {
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onSubmit: (weight: string) => void;
  onUnmount?: () => void;
  inputSxProps?: SxProps;
};

const WeightInput = (props: Props) => {
  const isLoading = useRecoilValue(bulkPackingIsLoadingAtom);
  const { value, onChange, onSubmit, onUnmount, inputSxProps } = props;
  const [showKeyboard, setShowKeyboard] = useState(false);

  const handleEnterPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onSubmit(value);
    }
  };

  useScale(onSubmit, console.error);

  useEffect(() => {
    if (onUnmount) {
      return () => onUnmount();
    }
  }, []);

  const handleNumberKeyboard = (input: string) => {
    if (input === 'enter') {
      setShowKeyboard(false);
      return;
    }

    const newValue = input === 'del' ? value.slice(0, -1) : value + input;
    onChange({ target: { value: newValue } } as ChangeEvent<HTMLInputElement>);
  };

  return (
    <>
      <TextField
        id="weight-input"
        value={value}
        onChange={onChange}
        onKeyDown={handleEnterPress}
        fullWidth
        inputProps={{ sx: { padding: '4px', fontSize: 14, ...inputSxProps } }}
        focused
        autoFocus
        onFocus={() => setShowKeyboard(false)}
        InputProps={{
          sx: { height: 40 },
          endAdornment: (
            <InputAdornment position="start" onClick={() => setShowKeyboard(true)}>
              <KeyboardIcon fontSize={'large'} sx={{ color: COLORS.black }} />
            </InputAdornment>
          ),
        }}
      />
      {showKeyboard && (
        <Box sx={{ position: 'relative', left: 68, top: -26 }}>
          <NumberKeyboard handleButtonClick={handleNumberKeyboard} />
        </Box>
      )}
      <LoadingButton
        loading={isLoading}
        variant="contained"
        sx={{ height: 41, fontWeight: 700 }}
        onClick={() => onSubmit(value)}
      >
        확인
      </LoadingButton>
    </>
  );
};

export default WeightInput;
