import { AxiosError } from 'axios';
import { axiosV2 } from '../../../../../../../libs/common/fetcher';
import { errorType } from '../../../../../../../types/common';

export const getReceiptsConfirmDetails = async (receivingId: number | undefined) => {
  try {
    return await axiosV2.get(`/receivings/returns/${receivingId}/complete`);
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    return error1.response;
  }
};

export const receivingReInspect = async (receivingId: number, rejectionReason: string) => {
  try {
    return await axiosV2.post(`/receivings/${receivingId}/reinspect`, { rejectionReason });
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    return error1.response;
  }
};

export const completeReturnsReceiving = async (receivingId: number) => {
  try {
    return await axiosV2.post(`/receivings/returns/${receivingId}/completed`);
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    return error1.response;
  }
};
