import Layout from 'components/common/Layout';
import { REACT_APP_BASE_PATH } from 'consts/common/environment';
import React from 'react';
import { createBrowserRouter, redirect } from 'react-router-dom';
import { axiosV2 } from '../libs/common/fetcher';
import { Auth } from '../store/auth.recoil';
import CsShippings from './gms/cs/shippings/index.page';
import ExpectedReceivingDetails from './gms/inbound/expect-receiving/details/index.page';
import SpecificationOfCompletedReceiving from './gms/inbound/completed-specification-receiving/index.page';
import ExpectedReceivingDetailsSearchItems from './gms/inbound/expect-receiving/details/search-items/index.page';
import ExpectedReceiving from './gms/inbound/expect-receiving/index.page';
import ReceivingInspectDetails from './gms/inbound/inspection-receiving/details/index.page';
import InspectionOfReceiving from './gms/inbound/inspection-receiving/index.page';
import MovementOfReceiving from './gms/inbound/movement-receiving/index.page';
import Receivings from './gms/inbound/receivings/index.page';
import RegisteredReceiving from './gms/inbound/registered-receiving/index.page';
import ReceivingSpecification from './gms/components/SpecificationForPrint';
import ReturnReceiptsConfirmDetails from './gms/inbound/return/confirms-receiving/details/index.page';
import ReturnReceiptsConfirms from './gms/inbound/return/confirms-receiving/index.page';
import ExpectedReturnReceivingDetails from './gms/inbound/return/expect-receiving/details/index.page';
import ExpectedReturnReceiving from './gms/inbound/return/expect-receiving/index.page';
import ReturnReceivingInspectDetails from './gms/inbound/return/inspection-receiving/details/index.page';
import InspectionOfReturnReceiving from './gms/inbound/return/inspection-receiving/index.page';
import GMSHome from './gms/index.page';
import InventoryItems from './gms/info/inventory-items/index.page';
import Aisles from './gms/info/locations/aisles/index.page';
import Areas from './gms/info/locations/areas/index.page';
import Bays from './gms/info/locations/bays/index.page';
import Centers from './gms/info/locations/centers/index.page';
import CarrierChangeForFailed from './gms/outbound/bulk-packing/carrier-change-for-failed/index.page';
import BulkPacking from './gms/outbound/bulk-packing/index.page';
import DevicesWorks from './gms/outbound/devices-works/index.page';
import Inspection from './gms/outbound/inspection/index.page';
import Inspections from './gms/outbound/inspections/index.page';
import Packing from './gms/outbound/packing/index.page';
import Packings from './gms/outbound/packings/index.page';
import PickingGroup from './gms/outbound/picking-group/index.page';
import PickingGroups from './gms/outbound/picking-groups/index.page';
import Pickings from './gms/outbound/pickings/index.page';
import PackingErrors from './gms/outbound/packings/[id]/errors/index.page';
import ShippingOrders from './gms/outbound/shipping-orders/index.page';
import Shippings from './gms/outbound/shippings/index.page';
import ShippingConveyorWorkflows from './gms/outbound/shipping-conveyor-workflows/index.page';
import Signin from './auth/signin.page';
import Home from './index.page';
import Cookies from 'js-cookie';
import Custom403 from './403.page';
import AggregatedSales from './aggregated-sales.page';
import getFingerprint from 'libs/info/getFingerprint';
import CarrierChange from './gms/outbound/bulk-packing/carrier-change/index.page';
import PickingGroupItems from './gms/outbound/picking-group/[id]/items/index.page';
import Devices from './gms/info/devices/index.page';
import InventoryLocations from './gms/info/inventory-locations/index.page';
import Carriers from './gms/info/carriers/index.page';
import PackingChangeTote from './gms/outbound/packing-change-tote/index.page';
import Users from './gms/info/users/index.page';
import ConveyorPaths from './gms/info/conveyor-paths/index.page';
import Stocktakings from './gms/info/stock-takings/index.page';
import BulkPackings from './gms/outbound/bulk-packings/index.page';
import BulkInspections from './gms/outbound/bulk-inspections/index.page';
import StocktakingLocationItems from './gms/info/stock-takings-bins-items/index.page';
import StocktakingProgress from './gms/info/stock-takings-progress/index.page';
import StocktakingCompare from './gms/info/stock-takings-compare/index.page';
import InventoryHistories from './gms/info/inventory-histories/index.page';
import ItemOptions from './gms/info/item-options/index.page';
import PickingGroupOfInventoryTransfer from './gms/outbound/picking-group-of-inventory-transfer/index.page';
import Bins from './gms/info/location-bins/index.page';
import PickingItems from './gms/outbound/picking-items';
import TransferGenerates from './gms/transfer/orders/generates/index.page';
import TransferGeneratesSearchItem from './gms/transfer/orders/generates/search-item/index.page';
import TransferSearches from './gms/transfer/orders/searches/index.page';
import ReceiptsConfirms from './gms/inbound/receipts/confirms/index.page';
import ReceiptsConfirmDetails from './gms/inbound/receipts/confirm-details/index.page';
import ShippingTask from './gms/status/shipping-task/index.page';
import PickingGroupOfDonation from './gms/outbound/picking-group-of-donation/index.page';
import SpecificationOfCompletedReceivingStatus from './gms/inbound/completed-specification-receiving-status/index.page';
import ExpectedInventoryTransferReceiving from './gms/inbound/expect-inventory-transfer-receiving/index.page';
import ExpectedInventoryTransferReceivingDetails from './gms/inbound/expect-inventory-transfer-receiving/details/index.page';
import EachExpectedReceivingDetails from './gms/inbound/expect-receiving/details-each/index.page';
import Companies from './gms/info/locations/companies/index.page';
import Warehouses from './gms/info/locations/warehouses/index.page';
import StockMovements from './gms/info/stock-movements/index.page';
import Lpns from './gms/info/lpns/index.page';
import StockMovementHistories from './gms/info/stock-movement-histories/index.page';
import Conveyors from './gms/info/conveyors/index.page';
import ChangePassword from './auth/change-password.page';

export const checkAuthLoader = async () => {
  const accessToken = Cookies.get('accessToken');

  if (!accessToken) {
    return redirect('/auth/signin');
  }

  try {
    const axiosResponse = await axiosV2.get<Auth>(`/auth/me`);
    const data = axiosResponse.data;
    if (data.changePasswordRequired) {
      return redirect('/auth/change-password');
    }
    return data;
  } catch (error) {
    console.error('checkAuth error: ', JSON.stringify(error));
    return redirect('/auth/signin');
  }
};

const routes = createBrowserRouter(
  [
    {
      path: '/',
      children: [
        { path: '*', element: <Custom403 /> },
        { path: '', loader: () => redirect('gms'), element: <Home /> },
        {
          path: 'auth/signin',
          element: <Signin />,
        },
        {
          path: 'auth/change-password',
          element: <ChangePassword />,
        },
        { path: 'aggregated-sales', element: <AggregatedSales /> },
        {
          path: 'gms',
          element: <Layout />,
          loader: checkAuthLoader,
          shouldRevalidate: ({ currentUrl, nextUrl }) => {
            return currentUrl.pathname !== nextUrl.pathname;
          },
          children: [
            { path: '*', element: <Custom403 /> },
            {
              path: '',
              element: <GMSHome />,
            },
            {
              path: 'auth/change-password',
              element: <ChangePassword />,
            },
            {
              path: 'info',
              children: [
                {
                  path: 'devices',
                  element: <Devices />,
                },
                {
                  path: 'users',
                  element: <Users />,
                },
                {
                  path: 'conveyors',
                  element: <Conveyors />,
                },
                {
                  path: 'conveyors/path',
                  element: <ConveyorPaths />,
                },
                {
                  path: 'inventory-items/items',
                  element: <InventoryItems />,
                },
                {
                  path: 'inventory-items/locations',
                  element: <InventoryLocations />,
                },
                {
                  path: 'inventories/adjusted-inventory-histories',
                  element: <InventoryHistories />,
                },
                {
                  path: 'inventory-items/stock-movements',
                  element: <StockMovements />,
                },
                {
                  path: 'inventory-items/stock-movement-histories',
                  element: <StockMovementHistories />,
                },
                {
                  path: 'inventory-items/stock-takings',
                  element: <Stocktakings />,
                },
                {
                  path: 'inventory-items/stock-takings/inventory-items/locations/items',
                  element: <StocktakingLocationItems />,
                },
                {
                  path: 'inventory-items/stock-takings/progress',
                  element: <StocktakingProgress />,
                },
                {
                  path: 'inventory-items/stock-takings/compare',
                  element: <StocktakingCompare />,
                },
                {
                  path: 'carriers',
                  element: <Carriers />,
                },
                {
                  path: 'item-options',
                  children: [
                    {
                      path: '',
                      element: <ItemOptions />,
                    },
                    {
                      path: 'lpns',
                      element: <Lpns />,
                    },
                  ],
                },
                {
                  path: 'locations',
                  children: [
                    {
                      path: 'companies',
                      element: <Companies />,
                    },
                    {
                      path: 'centers',
                      element: <Centers />,
                    },
                    {
                      path: 'warehouses',
                      element: <Warehouses />,
                    },
                    {
                      path: 'areas',
                      element: <Areas />,
                    },
                    {
                      path: 'aisles',
                      element: <Aisles />,
                    },
                    {
                      path: 'bays',
                      element: <Bays />,
                    },
                    {
                      path: 'bins',
                      element: <Bins />,
                    },
                  ],
                },
              ],
            },
            {
              path: 'status',
              children: [
                {
                  path: 'shipping-task',
                  element: <ShippingTask />,
                },
              ],
            },
            {
              path: 'cs',
              children: [
                {
                  path: 'shippings',
                  element: <CsShippings />,
                },
              ],
            },
            {
              path: 'outbound',
              children: [
                {
                  path: 'bulk-packing',
                  children: [
                    {
                      path: '',
                      element: <BulkPacking />,
                    },
                    {
                      path: 'carrier-change-for-failed',
                      element: <CarrierChangeForFailed />,
                    },
                    {
                      path: 'carrier-change',
                      element: <CarrierChange />,
                    },
                  ],
                },
                {
                  path: 'bulk-packings',
                  children: [
                    {
                      path: '',
                      element: <BulkPackings />,
                    },
                    // {
                    //   path: ':serialNumber',
                    //   element: <BulkPackings />,
                    // },
                  ],
                },
                {
                  path: 'devices-works',
                  element: <DevicesWorks />,
                },
                {
                  path: 'inspection',
                  loader: async () => await getFingerprint(),
                  element: <Inspection />,
                },
                {
                  path: 'inspections',
                  element: <Inspections />,
                },
                {
                  path: 'bulk-inspections',
                  element: <BulkInspections />,
                },
                {
                  path: 'packing',
                  loader: async () => await getFingerprint(),
                  element: <Packing />,
                },
                {
                  path: 'packing/change/tote',
                  element: <PackingChangeTote />,
                },
                {
                  path: 'packings',
                  children: [
                    {
                      path: '',
                      element: <Packings />,
                    },
                    {
                      path: ':id/errors',
                      element: <PackingErrors />,
                    },
                  ],
                },
                {
                  path: 'shipping-orders',
                  element: <ShippingOrders />,
                },
                {
                  path: 'shippings',
                  children: [
                    {
                      path: '',
                      element: <Shippings />,
                    },
                    {
                      path: ':id/items',
                      element: <PickingGroupItems />,
                    },
                  ],
                },
                {
                  path: 'shipping-conveyor-workflows',
                  element: <ShippingConveyorWorkflows />,
                },
                {
                  path: 'picking-group',
                  children: [
                    {
                      path: '',
                      element: <PickingGroup />,
                    },
                    {
                      path: 'donation',
                      element: <PickingGroupOfDonation />,
                    },
                    {
                      path: 'inventory-transfer',
                      element: <PickingGroupOfInventoryTransfer />,
                    },
                  ],
                },
                {
                  path: 'picking-groups',
                  element: <PickingGroups />,
                },
                {
                  path: 'pickings',
                  element: <Pickings />,
                },
                {
                  path: 'picking-items',
                  element: <PickingItems />,
                },
              ],
            },
            {
              path: 'transfer',
              children: [
                {
                  path: 'orders',
                  children: [
                    {
                      path: 'generates',
                      children: [
                        {
                          path: '',
                          element: <TransferGenerates />,
                        },
                        {
                          path: 'search-item',
                          element: <TransferGeneratesSearchItem />,
                        },
                      ],
                    },
                    {
                      path: 'searches',
                      element: <TransferSearches />,
                    },
                  ],
                },
              ],
            },
            {
              path: 'inbound',
              children: [
                {
                  path: 'receipts',
                  children: [
                    {
                      path: 'confirms',
                      element: <ReceiptsConfirms />,
                    },
                    {
                      path: 'confirm-details',
                      element: <ReceiptsConfirmDetails />,
                    },
                  ],
                },
                {
                  path: 'return',
                  children: [
                    {
                      path: 'expect-receiving',
                      children: [
                        {
                          path: '',
                          element: <ExpectedReturnReceiving />,
                        },
                        {
                          path: 'details',
                          children: [
                            {
                              path: '',
                              element: <ExpectedReturnReceivingDetails />,
                            },
                          ],
                        },
                      ],
                    },
                    {
                      path: 'inspection-receiving',
                      children: [
                        {
                          path: '',
                          element: <InspectionOfReturnReceiving />,
                        },
                        {
                          path: 'inspect',
                          children: [
                            {
                              path: '',
                              element: <ReturnReceivingInspectDetails />,
                            },
                          ],
                        },
                      ],
                    },
                    {
                      path: 'confirm-receiving',
                      children: [
                        {
                          path: '',
                          element: <ReturnReceiptsConfirms />,
                        },
                        {
                          path: 'confirm',
                          children: [
                            {
                              path: '',
                              element: <ReturnReceiptsConfirmDetails />,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  path: 'expect-inventory-transfer-receiving',
                  children: [
                    {
                      path: '',
                      element: <ExpectedInventoryTransferReceiving />,
                    },
                    {
                      path: 'details',
                      children: [
                        {
                          path: '',
                          element: <ExpectedInventoryTransferReceivingDetails />,
                        },
                        {
                          path: 'search-items',
                          element: <ExpectedReceivingDetailsSearchItems />,
                        },
                      ],
                    },
                  ],
                },
                {
                  path: 'expect-receiving',
                  children: [
                    {
                      path: '',
                      element: <ExpectedReceiving />,
                    },
                    {
                      path: 'details',
                      children: [
                        {
                          path: '',
                          element: <ExpectedReceivingDetails />,
                        },
                        {
                          path: 'search-items',
                          element: <ExpectedReceivingDetailsSearchItems />,
                        },
                      ],
                    },
                    {
                      path: 'details-each',
                      children: [
                        {
                          path: '',
                          element: <EachExpectedReceivingDetails />,
                        },
                      ],
                    },
                  ],
                },
                {
                  path: 'registered-receiving',
                  children: [
                    {
                      path: '',
                      element: <RegisteredReceiving />,
                    },
                    {
                      path: ':id/specification',
                      element: <ReceivingSpecification />,
                    },
                  ],
                },
                {
                  path: 'inspection-receiving',
                  children: [
                    {
                      path: '',
                      element: <InspectionOfReceiving />,
                    },
                    {
                      path: 'inspect',
                      children: [
                        {
                          path: '',
                          element: <ReceivingInspectDetails />,
                        },
                      ],
                    },
                  ],
                },
                {
                  path: 'specification-completed-receiving',
                  children: [
                    {
                      path: '',
                      element: <SpecificationOfCompletedReceiving />,
                    },
                  ],
                },
                {
                  path: 'specification-completed-receiving-status',
                  children: [
                    {
                      path: '',
                      element: <SpecificationOfCompletedReceivingStatus />,
                    },
                  ],
                },
                {
                  path: 'movement-receiving',
                  children: [
                    {
                      path: '',
                      element: <MovementOfReceiving />,
                    },
                  ],
                },
                {
                  path: 'receivings',
                  children: [
                    {
                      path: '',
                      element: <Receivings />,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
  { basename: REACT_APP_BASE_PATH }
);

export default routes;
