import useSWR from 'swr';
import { datadogRum } from '@datadog/browser-rum';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import { GridEventListener, GridEvents, GridSelectionModel } from '@mui/x-data-grid-pro';
import Filter, { Form } from 'components/common/Filter';
import Page from 'components/common/Layout/Page';
import TightDataGridPro from 'components/common/TightDataGridPro';
import { ROWS_PER_PAGE_OPTIONS } from 'consts/common/dataGrid';
import fetcher from 'libs/common/fetcher';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { listBoardTheme } from 'styles/customedMuiTheme';
import pageTitle from 'styles/pageTitle';
import { Options } from 'types/form';
import FormModal from '../../../../components/common/FormModal';
import { COLORS } from '../../../../consts/common/colors';
import { DEFAULT_PAGE, DEFAULT_SIZE } from '../../../../consts/common/pageAndSize';
import usePopup from '../../../../hooks/usePopup';
import convertUser from '../../../../libs/common/convertUser';
import checkPrinterConnection from '../../../../libs/outbound/checkPrinterConnection';
import { printerAtom } from '../../../../store/common/printer.recoil';
import refCodeOptionsAtom from '../../../../store/outbound/refCode.recoil';
import { warehouseAtom, warehouseOptionsAtom } from '../../../../store/outbound/warehouse.recoil';
import useConditionalSWR from '../../components/useConditionalSwr';
import ChevronDouble from '../components/ChevronDouble';
import LoadingModal from '../components/LoadingModal';
import DiagnosisDialogContent from '../packing/components/DiagnosisDialogContent';
import diagnosisGuides from '../packing/diagnosisGuides';
import CompletedPackingResultModal from './components/CompletedPackingResultModal';
import LinearWithValueLabel from './components/LinearWithValueLabel';
import ShippingDataGrid from './components/ShippingDataGrid';
import gridColumns from './gridColDef';
import {
  completeBulkPacking,
  completePackingOfBulkPacking,
  exceptErrorPackings,
  getPackingIdsToCompleted,
  getPackingIdsToPrintWaybill,
  getUnmatchedBulkPackingItems,
  printWaybill,
  updatePackingToPrinted,
} from './services/bulkPackings';
import pLimit from 'p-limit';
import DonationBoxCountModal from './components/DonationBoxCountModal';
import MatchingResultModal from './components/MatchingResultModal';

const dateRangeTypeOptions: Options = [
  {
    displayName: '검수완료일',
    field: 'inspectionCompletedAt',
  },
  {
    displayName: '송장발행일',
    field: 'registerCompletedAt',
  },
  {
    displayName: '송장출력일',
    field: 'printCompletedAt',
  },
  {
    displayName: '포장완료일',
    field: 'packingCompletedAt',
  },
];

const defaultOption = { displayName: '전체', value: 'ALL' };

type CompletedPackingResult = {
  status: number;
  data: {
    total: number;
    successCount: number;
    failCount: number;
  };
};

const defaultCompletedPackingResult = {
  status: 0,
  data: {
    total: 0,
    successCount: 0,
    failCount: 0,
  },
};

type bulkPackingPrintResponse = {
  packingId: number;
  base64Image: string;
};

type BulkPackingsPrinting = {
  id: number;
  warehouseId: number;
  bulkPackingStatus: string;
  pickingGroupType: string;
  pickingGroupSerialNumber: string;
  bulkPackingCompletedAt: string;
  gramWeight: number;
  shippingCount: number;
  bulkPackingPrintedAt: string;
  bulkInspectionCompletedAt: string;
  widthInMillimeters: number;
  heightInMillimeters: number;
  lengthInMillimeters: number;
  updatedUsername: string;
  updatedLoginId: string;
  workedAt: number;
};

type BulkPackingsPrintingResponse = {
  content: BulkPackingsPrinting[];
  totalElements: number;
  size: number;
  number: number;
};

type SearchQuery = {
  page?: number;
  size?: number;
  warehouseId?: number;
  pickingGroupSerialNumber?: string;
};

type BulkShipping = {
  id: number;
  status: 'READY' | 'PROCESSING' | 'COMPLETED' | 'ERROR' | 'REGISTERED' | 'PRINTED' | 'PRINTING';
  shippingBaseAt: string;
  shippingSerialNumber: string;
  deliverySeq: string;
  deliveryId: number;
  totalSku: number;
  totalQuantity: number;
  carrierId: number;
  countryCode: string;

  //TODO: 아래 필드들은 필요한지 확인이 필요함.
  boxName: string;
  completedAt: string;
  errorCode: null;
  trackingNumber: string;
  updatedAt: string;
  updatedUserLoginId: string;
  updatedUserName: string;
  warehouseId: number;
  weight: number;
};

type BulkShippingResponse = {
  content: BulkShipping[];
  totalElements: number;
};

const gridTemplateColumns = [
  '30px',
  'minmax(45px, 0.6fr)',
  'minmax(45px, 0.6fr)',
  '60px',
  'minmax(55px, 2fr)',
  'minmax(55px, 1.4fr)',
  '36px',
  'minmax(45px, 0.6fr)',
  'minmax(45px, 0.6fr)',
  '36px',
  'minmax(55px, 1.4fr)',
  'minmax(55px, 1.4fr)',
].reduce((prevValue, labelWidth) => prevValue + labelWidth + ' ', '');

export type DiffQtyItem = { itemCode: string; itemName: string; diffQty: number };
export type MatchingResult = {
  isMatched: true;
  toteBarcode: string[];
  diffQtyItems: DiffQtyItem[];
};

const BulkPackings = () => {
  const [completedPackingResult, setCompletedPackingResult] = useState<CompletedPackingResult>(
    defaultCompletedPackingResult
  );
  const [isPrinterConnected, setIsPrinterConnected] = useState(true);
  const [selectedPackingId, setSelectedPackingId] = useState<number | undefined>();
  const printer = useRecoilValue(printerAtom);
  const [isPrinting, setIsPrinting] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [notMatchedItems, setNotMatchedItems] = useState<MatchingResult>();
  const [isPrintingDonationModalOpen, setIsPrintingDonationModalOpen] = useState(false);
  const { showAlert, showSnackbar, showErrorDialog } = usePopup();
  const [hasSearched, setHasSearched] = useState(false);
  const [selectedBulkPacking, setSelectedBulkPacking] = useState({});
  const [selectedBulkPackingId, setSelectedBulkPackingId] = useState(null);
  const [isExceptingPackings, setIsExceptingPackings] = useState(false);
  const globalWarehouse = useRecoilValue(warehouseAtom);
  const warehouseOption = useRecoilValue(warehouseOptionsAtom);
  const location = useLocation();
  const [queryParams, setQueryParams] = useState<SearchQuery>({
    page: DEFAULT_PAGE,
    size: DEFAULT_SIZE,
    warehouseId: Number(globalWarehouse),
    pickingGroupSerialNumber: location?.state?.pickingGroupSerialNumber ?? '',
  });
  const errorWaybillOptions = [
    { displayName: '있음', value: 'Y', order: 1 },
    { displayName: '없음', value: 'N', order: 2 },
  ];
  const refCodeOptions = useRecoilValue(refCodeOptionsAtom);
  const [totalCount, setTotalCount] = useState(0);
  const [requestCount, setRequestCount] = useState(0);
  const [progress, setProgress] = React.useState(0);
  const [donationBoxCount, setDonationBoxCount] = useState<number | undefined>(1);

  const pickingGroupSerialNumber = location?.state?.pickingGroupSerialNumber ?? undefined;
  useEffect(() => {
    if (!pickingGroupSerialNumber) return;
    handleSearchClick(queryParams);
  }, []);

  useEffect(() => {
    if (!isCompleted) return;
    setProgress(prevProgress => (prevProgress >= 100 ? 0 : (requestCount / totalCount) * 100));
  }, [isCompleted, requestCount]);

  const { data, mutate, isValidating } = useConditionalSWR<BulkPackingsPrintingResponse>(
    [`/bulk-packings/search`, { ...queryParams, sort: 'id,desc' }],
    fetcher,
    hasSearched
  );

  const [shippingQueryParams, setShippingQueryParams] = useState<SearchQuery>({
    page: DEFAULT_PAGE,
    size: DEFAULT_SIZE,
  });

  const {
    data: shippingData,
    mutate: shippingMutate,
    error: shippingError,
  } = useSWR<BulkShippingResponse>(
    [
      `/packings?sort=carrier.code,desc&sort=trackingNumber,desc`,
      { ...shippingQueryParams, bulkPackingId: selectedBulkPackingId },
    ],
    fetcher
  );

  const bulkPackingStatusOptions = refCodeOptions?.bulkPackingStatus || [];
  const pickingGroupTypeOptions = refCodeOptions?.pickingGroupType || [];
  const pickingTypeOptions = pickingGroupTypeOptions.filter(
    option => option.value === 'DONATION' || option.value === 'BULK'
  );

  const makeRowIndex = (data: BulkPackingsPrintingResponse) => {
    const { content, totalElements, size, number } = data;

    return content.map((item: BulkPackingsPrinting, index: number) => ({
      ...item,
      rowIndex: totalElements - size * number - index,
      updatedUser: convertUser(item.updatedUsername, item.updatedLoginId),
    }));
  };

  const handleInitClick = async () => {
    setQueryParams({
      page: DEFAULT_PAGE,
      size: DEFAULT_SIZE,
      warehouseId: Number(globalWarehouse),
    });
    setSelectedPackingId(undefined);
    setSelectedBulkPackingId(null);
    await mutate();
  };

  const handleSearchClick = async (form: Form) => {
    const updatedForm = _.omitBy(form, o => o === defaultOption.value);
    delete updatedForm[`dateType`];

    setQueryParams(({ size }) => ({ ...updatedForm, size, page: DEFAULT_PAGE }));
    setSelectedPackingId(undefined);
    setSelectedBulkPackingId(null);
    !hasSearched && setHasSearched(true);
    await mutate();
  };

  const handleRowClick: GridEventListener<GridEvents.rowClick> = params => {
    setSelectedBulkPacking(params.row);
    setSelectedBulkPackingId(orderId => {
      return params.row.id === orderId ? null : params.row.id;
    });
  };

  const handlePrintingWaybills = async () => {
    // todo: checkbox 초기화
    if (!selectedPackingId) {
      showAlert({ message: '송장을 출력할 대량포장이 선택되지 않았습니다' });
      return;
    }

    const selectedItems = data?.content?.filter(item => selectedPackingId === item.id);
    const donations = selectedItems?.filter(item => item.pickingGroupType === 'DONATION') ?? [];

    if (donations.length > 0) {
      setIsPrintingDonationModalOpen(true);
      return;
    }

    const printableBulkOrDonationPackings = data?.content?.filter(
      item =>
        selectedPackingId === item.id &&
        item.bulkPackingStatus !== 'REGISTERED' &&
        item.bulkPackingStatus !== 'PRINTED'
    ).length as number;

    if (printableBulkOrDonationPackings != 0) {
      showAlert({ message: '대량송장발행완료 또는 대량송장출력완료인 대량포장을 선택해 주세요.' });
      return;
    }

    await connectPrint();
    await printBulkWaybills(selectedPackingId);
  };

  const handlePrintingDonationWaybills = async () => {
    if (donationBoxCount === undefined || donationBoxCount < 1) {
      showAlert({ message: '박스 수량은 1 이상 입력해 주세요. ' });
      return;
    }
    setIsPrinting(true);

    await connectPrint();

    if (!selectedPackingId) {
      return;
    }

    try {
      // eslint-disable-next-line
      for (const _ of new Array(donationBoxCount).fill(0)) {
        const response = await getPackingIdsToPrintWaybill(selectedPackingId);
        if (response?.status !== 200) {
          setIsPrinting(false);
          showErrorDialog({
            title: '운송장 출력 실패',
            errorMessage: response?.data.errorMessage,
            buttons: [{ text: '확인' }],
          });
          return;
        }
        const packingIds = response?.data;
        await printWaybillSync(packingIds, selectedPackingId);
      }
      setIsPrinting(false);
      handlePrintingDonationModalClose();
    } catch (e) {
      setIsPrinting(false);
      const errorMessage = `송장출력 에러 : ${e instanceof Error ? e.message : String(e)}`;
      // e가 객체인 경우에만 datadogRum.addAction에 전달
      if (typeof e === 'object' && e !== null) {
        datadogRum.addAction(errorMessage, e);
      } else {
        // e가 객체가 아닌 경우, 예를 들어 문자열이나 숫자인 경우
        datadogRum.addAction(errorMessage);
      }
      showAlert({
        message: errorMessage,
        onClick: () => {
          setIsPrinting(false);
        },
      });
    }
  };

  const connectPrint = async () => {
    try {
      const isConnected = await checkPrinterConnection();
      setIsPrinterConnected(isConnected);
    } catch (e) {
      const errorMessage = `운송장 프린터 연결 에러 : ${
        e instanceof Error ? e.message : String(e)
      }`;
      // e가 객체인 경우에만 datadogRum.addAction에 전달
      if (typeof e === 'object' && e !== null) {
        datadogRum.addAction(errorMessage, e);
      } else {
        // e가 객체가 아닌 경우, 예를 들어 문자열이나 숫자인 경우
        datadogRum.addAction(errorMessage);
      }
      showAlert({
        message: errorMessage,
        onClick: () => {
          setIsPrinterConnected(false);
        },
      });
    }
  };

  const printBulkWaybills = async (selectedPackingId: number) => {
    try {
      setIsPrinting(true);

      const response = await getPackingIdsToPrintWaybill(selectedPackingId);
      if (response?.status !== 200) {
        setIsPrinting(false);
        showErrorDialog({
          title: '운송장 출력 실패',
          errorMessage: response?.data.errorMessage,
          buttons: [{ text: '확인' }],
        });
        return;
      }

      const packingIds = response?.data;
      await printWaybillSync(packingIds, selectedPackingId);
      setIsPrinting(false);
    } catch (e) {
      setIsPrinting(false);
      const errorMessage = `송장출력 에러 : ${e instanceof Error ? e.message : String(e)}`;
      // e가 객체인 경우에만 datadogRum.addAction에 전달
      if (typeof e === 'object' && e !== null) {
        datadogRum.addAction(errorMessage, e);
      } else {
        // e가 객체가 아닌 경우, 예를 들어 문자열이나 숫자인 경우
        datadogRum.addAction(errorMessage);
      }
      showAlert({
        message: errorMessage,
        onClick: () => {
          setIsPrinting(false);
        },
      });
    }
  };

  const printWaybillSync = async (packingIds: number[], bulkPackingId: number) => {
    const pieces = _.chunk(packingIds, 20);
    for (const piece of pieces) {
      const thisPrintingPackingIds = piece?.map((item: any) => item['packingId']);
      await requestWaybillImage(bulkPackingId, thisPrintingPackingIds);
    }
    await mutate();
  };

  const requestWaybillImage = async (bulkPackingId: number, packingIds: number[]) => {
    const imageResponses = await printWaybill(bulkPackingId, packingIds);
    if ((imageResponses?.status ?? 0) >= 200 && (imageResponses?.status ?? 0) < 300) {
      const imageResponse: bulkPackingPrintResponse[] = imageResponses?.data;
      let images = '';
      imageResponse.forEach((item: bulkPackingPrintResponse) => {
        if (_.isNil(item.packingId) || _.isNil(item.base64Image)) {
          throw new Error('포장 정보 이상 : ' + JSON.stringify(item));
        }
        images += item.base64Image;
      });
      try {
        await printImage(images);
        await updatePackingToPrinted(bulkPackingId, packingIds);
        datadogRum.addAction(`운송장 프린터 출력 성공 : ${packingIds}`);
      } catch (e) {
        datadogRum.addAction(`운송장 프린터 출력 실패 : ${packingIds}`);
        throw new Error(`운송장 프린터 출력 실패 : ${packingIds}`);
      }
    } else {
      datadogRum.addAction(
        `운송장 이미지 요청 실패 : ${imageResponses?.data?.errorMessage}`,
        imageResponses?.data
      );
      throw new Error(`운송장 이미지 요청 실패 : ${imageResponses?.data?.errorMessage}`);
    }
  };
  const printImage = (image: string) =>
    new Promise((resolve, reject) => {
      printer.send(`${image}`, resolve, reject);
    });

  const getPrinterConnectionGuide = () => {
    return (
      <DiagnosisDialogContent
        guides={diagnosisGuides.retryPrinterConnect}
        onClick={() =>
          checkPrinterConnection()
            .then(isConnected => {
              setIsPrinterConnected(isConnected);
            })
            .catch(() => {
              setIsPrinterConnected(false);
              showAlert({ message: '운송장 프린터 연결 에러' });
            })
        }
      />
    );
  };

  const handleCompleteBulkPacking = async () => {
    if (!selectedPackingId) {
      showAlert({ message: '대량포장을 1건 선택해주세요' });
      return;
    }

    const responseOfPackingIds = await getPackingIdsToCompleted(selectedPackingId);
    if (responseOfPackingIds?.status !== 200) {
      showAlert({ message: responseOfPackingIds?.data?.errorMessage });
      return;
    }

    const resultOfMatching = await getUnmatchedBulkPackingItems(selectedPackingId);
    if (resultOfMatching?.status !== 200) {
      showAlert({ message: resultOfMatching?.data?.errorMessage });
      return;
    }

    if (!resultOfMatching?.data?.isMatched) {
      setNotMatchedItems(resultOfMatching?.data);
      return;
    }

    setIsCompleted(true);
    const packingIds = responseOfPackingIds?.data;
    setTotalCount(packingIds?.length);

    const limit = pLimit(10);
    const completeBulkPackingByPackingId: number[] = packingIds?.map(
      (item: { packingId: number }) =>
        limit(() => onCompletedPacking(selectedPackingId, item['packingId']))
    );
    const result = await Promise.all(completeBulkPackingByPackingId);

    await completeBulkPacking(selectedPackingId);
    setIsCompleted(false);

    const totalCount = result.length;
    const successCount = result.filter(item => item === 200).length;
    const failCount = totalCount - successCount;
    setCompletedPackingResult({
      status: 200,
      data: {
        total: totalCount,
        successCount,
        failCount,
      },
    });
    setTotalCount(0);
    setRequestCount(0);
    setProgress(0);
    await mutate();
    await shippingMutate();
  };

  const handleClose = () => {
    setCompletedPackingResult(defaultCompletedPackingResult);
  };

  const handlePrintingDonationModalClose = () => {
    setDonationBoxCount(1);
    setIsPrintingDonationModalOpen(false);
  };

  const onCompletedPacking = async (bulkPackingId: number, packingId: number) => {
    const { status } = await completePackingOfBulkPacking(bulkPackingId, packingId);
    setRequestCount(prevProgress => prevProgress + 1);
    return status;
  };

  const handleExceptPackings = async () => {
    if (!selectedPackingId) {
      showAlert({ message: '대량포장을 1건 선택해주세요' });
      return;
    }
    setIsExceptingPackings(true);
    const response = await exceptErrorPackings(selectedPackingId);
    if (response?.status === 200) {
      await mutate();
      await shippingMutate();
      showSnackbar({ message: '포장오류 제외가 완료되었습니다', severity: 'info' });
    } else {
      showErrorDialog({
        title: '오류포장 제외처리 실패',
        errorMessage: response?.data?.errorMessage,
        buttons: [{ text: '확인' }],
      });
    }
    setIsExceptingPackings(false);
  };

  return (
    <Page>
      <Typography variant="h2" sx={pageTitle}>
        대량/기증 포장 집품그룹 관리
      </Typography>
      <Typography variant="h2" sx={pageTitle}></Typography>
      <Filter gridTemplateColumns={gridTemplateColumns}>
        <Filter.Select
          label="창고"
          field="warehouseId"
          options={[defaultOption, ...warehouseOption]}
          labelGridColumn="1/2"
          selectGridColumn="2/4"
          defaultValue={globalWarehouse}
        />
        <Filter.Select
          label="집품구분"
          field="pickingGroupType"
          options={[defaultOption, ...pickingTypeOptions]}
          labelGridColumn="4/5"
          selectGridColumn="5/6"
        />
        <Filter.Select
          label="대량포장상태"
          field="bulkPackingStatus"
          options={[defaultOption, ...bulkPackingStatusOptions]}
          labelGridColumn="6/7"
          selectGridColumn="7/10"
        />
        <Filter.Select
          label="송장오류내역"
          field="errorWaybill"
          options={[defaultOption, ...errorWaybillOptions]}
          labelGridColumn="10/12"
          selectGridColumn="12/13"
        />
        <Filter.DateRangePickerWithSelect
          label="기간"
          rangeTypeOptions={dateRangeTypeOptions}
          gridRow={2}
          labelGridColumn="1/2"
          selectGridColumn="2/4"
          dateRangePickerGridColumn="4/6"
          rangeAmountSelectGridColumn="6/8"
        />
        <Filter.Search
          label="집품그룹ID"
          field="pickingGroupSerialNumber"
          gridRow={2}
          labelGridColumn="8/10"
          inputGridColumn="10/13"
          defaultValue={pickingGroupSerialNumber ?? ''}
        />
        <Divider sx={styles.divider} />
        <Filter.DefaultButtonGroup
          gridColumn="1/-1"
          gridRow={5}
          onInitClick={handleInitClick}
          onLookupClick={handleSearchClick}
          sx={{ display: 'flex', justifyContent: 'center' }}
        />
      </Filter>
      <Stack spacing={1} sx={listBoardTheme.container}>
        <Box sx={listBoardTheme.header}>
          <Typography variant="h6" sx={{ color: 'text.primary' }}>
            대량포장 목록
          </Typography>
          <Box>
            <Button
              sx={listBoardTheme.headerButton}
              variant="outlined"
              suppressHydrationWarning
              onClick={handleExceptPackings}
            >
              포장오류 제외
            </Button>
            <LoadingButton
              sx={listBoardTheme.headerButton}
              variant="contained"
              suppressHydrationWarning
              onClick={handlePrintingWaybills}
              loading={isPrinting}
            >
              송장출력
            </LoadingButton>
            <LoadingButton
              sx={listBoardTheme.headerButton}
              variant="outlined"
              suppressHydrationWarning
              onClick={handleCompleteBulkPacking}
              loading={isCompleted}
            >
              대량포장 완료처리
            </LoadingButton>
          </Box>
        </Box>
        <Box sx={{ height: '280px' }}>
          <TightDataGridPro
            rows={hasSearched && data?.content ? makeRowIndex(data) : []}
            columns={gridColumns(selectedPackingId, setSelectedPackingId)}
            paginationMode="server"
            rowCount={hasSearched ? data?.totalElements ?? 0 : 0}
            rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            onRowClick={handleRowClick}
            onPageChange={page => setQueryParams(params => ({ ...params, page }))}
            pageSize={queryParams.size ?? DEFAULT_SIZE}
            onPageSizeChange={size => setQueryParams(params => ({ ...params, size }))}
            loading={isValidating}
            hasSearched={hasSearched}
            selectedPickingGroupId={selectedBulkPackingId}
          />
        </Box>
        {selectedBulkPackingId && (
          <>
            <Box sx={{ display: 'flex', justifyContent: 'center', padding: 1 }}>
              <ChevronDouble />
            </Box>
            <ShippingDataGrid
              data={shippingData}
              error={shippingError}
              queryParams={shippingQueryParams}
              setQueryParams={setShippingQueryParams}
              bulkPackingId={selectedBulkPackingId}
              request={selectedBulkPacking}
            />
          </>
        )}
      </Stack>
      <Dialog
        open={!isPrinterConnected}
        PaperProps={{ sx: styles.paperProps }}
        BackdropProps={{ sx: styles.backdropProps }}
      >
        <DialogTitle sx={styles.dialogTitle}>프린터 연결상태 확인</DialogTitle>
        <DialogContent sx={styles.dialogContent}>{getPrinterConnectionGuide()}</DialogContent>
      </Dialog>
      <FormModal
        open={completedPackingResult.data.total > 0}
        title="대량포장 완료"
        onClose={handleClose}
      >
        <CompletedPackingResultModal
          total={completedPackingResult.data.total}
          success={completedPackingResult.data.successCount}
          error={completedPackingResult.data.failCount}
          onClose={handleClose}
        />
      </FormModal>
      <FormModal open={isCompleted} title="대량포장 완료 진행률" onClose={handleClose}>
        <LinearWithValueLabel value={progress} />
      </FormModal>
      <LoadingModal isLoading={isPrinting} message={'송장 출력중입니다\n잠시만 기다려주세요'} />
      <LoadingModal
        isLoading={isExceptingPackings}
        message={'오류포장 제외 처리중입니다.\n잠시만 기다려주세요'}
      />
      <FormModal
        open={isPrintingDonationModalOpen}
        title={"배송방식 '기증' 박스 수량"}
        onClose={handlePrintingDonationModalClose}
      >
        <DonationBoxCountModal
          onClose={handlePrintingDonationModalClose}
          count={donationBoxCount}
          onCountChange={setDonationBoxCount}
          onConfirm={handlePrintingDonationWaybills}
          isPrinting={isPrinting}
        />
      </FormModal>
      {/*<LoadingModal*/}
      {/*  isLoading={isCompleted}*/}
      {/*  message={'대량포장 완료 처리중입니다.\n잠시만 기다려주세요'}*/}
      {/*/>*/}

      <FormModal
        open={!!notMatchedItems}
        title={'토트 잔여재고 안내'}
        onClose={() => setNotMatchedItems(undefined)}
      >
        <MatchingResultModal
          items={notMatchedItems}
          onClose={() => setNotMatchedItems(undefined)}
        />
      </FormModal>
    </Page>
  );
};

export default BulkPackings;

const styles = {
  divider: {
    gridRow: 4,
    gridColumn: '1/-1',
    pb: 1,
    mb: 1,
  },
  paperProps: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '520px',
    height: '460px',
  },
  backdropProps: {
    backgroundColor: COLORS.faintBlack,
  },
  dialogTitle: {
    paddingTop: '32px',
    textAlign: 'center',
    fontSize: '24px',
    fontWeight: '700',
    fontStyle: 'normal',
    lineHeight: '32px',
    letterSpacing: '-0.3px',
  },
  dialogSteps: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '76%',
  },
  otherStep: {
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '-0.4px',
  },
  currentStep: {
    color: COLORS.ktown4u,
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '-0.4px',
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-even',
    alignItems: 'center',
    width: '92%',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '-0.4px',
  },
  scaledWeightWrapper: {
    width: '100%',
    height: '24%',
    border: `1px solid ${COLORS.darkLine}`,
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    fontSize: 20,
    marginTop: 1,
  },
  scaledWeightTitle: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    fontSize: 20,
    pr: 2,
  },
  scaledWeightMessage: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    fontSize: 20,
    fontWeight: 'bold',
  },
};
