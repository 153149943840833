import React, { useState } from 'react';
import _ from 'lodash';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Page from 'components/common/Layout/Page';
import Filter, { Form } from 'components/common/Filter';
import TightDataGridPro from 'components/common/TightDataGridPro';
import { DEFAULT_PAGE, DEFAULT_SIZE } from '../../../../consts/common/pageAndSize';
import convertUser from '../../../../libs/common/convertUser';
import refCodeOptionsAtom from '../../../../store/outbound/refCode.recoil';
import ShippingDataGrid from './components/ShippingDataGrid';
import ChevronDouble from '../components/ChevronDouble';
import usePopup from 'hooks/usePopup';
import gridColumns from './gridColDef';
import fetcher from 'libs/common/fetcher';
import pageTitle from 'styles/pageTitle';
import { listBoardTheme } from 'styles/customedMuiTheme';
import { ROWS_PER_PAGE_OPTIONS } from 'consts/common/dataGrid';
import type { SummarizedPickingGroup } from 'types/outbound';
import type { Options } from 'types/form';
import PickingGroupForm from '../picking-groups/components/PickingGroupForm';
import FormModal from '../../../../components/common/FormModal';
import type { GridEventListener, GridEvents } from '@mui/x-data-grid-pro';
import { useRecoilValue } from 'recoil';
import { warehouseAtom, warehouseOptionsAtom } from '../../../../store/outbound/warehouse.recoil';
import { generateRemovePdas, removePickingGroup } from './services/pickingGroups';
import AssignPdaModal from './components/AssignPdaModal';
import useConditionalSWR from '../../components/useConditionalSwr';
import AssignSelectedPdaModal from './components/AssignSelectedPdaModal';

export type PickingGroups = {
  content: SummarizedPickingGroup[];
  totalElements: number;
  size: number;
  number: number;
};

type SearchQuery = {
  page?: number;
  size?: number;
  warehouseId?: number;
};

export type device = {
  deviceNumber: string;
  deviceType: string;
  jobType: string;
  workType: string;
  updatedAt?: string;
  status: string;
};

export type pickingGroupData = {
  warehouseFullCode?: string;
  type?: string;
  serialNumber?: string;
};

const gridTemplateColumns = [
  '90px',
  'minmax(55px, 0.6fr)',
  'minmax(55px, 0.6fr)',
  '80px',
  'minmax(45px, 0.6fr)',
  'minmax(45px, 0.6fr)',
  '40px',
  'minmax(45px, 0.6fr)',
  'minmax(45px, 0.6fr)',
  '50px',
  'minmax(55px, 1fr)',
  'minmax(55px, 1fr)',
].reduce((prevValue, labelWidth) => prevValue + labelWidth + ' ', '');

const defaultOption = { displayName: '전체', value: 'ALL' };

const dateRangeTypeOptions: Options = [
  {
    displayName: '집품그룹 생성일',
    field: 'createdAt',
  },
];

const searchKeywordOptions: Options = [
  {
    displayName: '집품그룹ID',
    field: 'serialNumber',
  },
];

const PickingGroups = () => {
  const { showDialog, showSnackbar, showErrorDialog } = usePopup();
  const [selectedPickingGroup, setSelectedPickingGroup] = useState<pickingGroupData>({});
  const [selectedPickingGroup1, setSelectedPickingGroup1] = useState<
    pickingGroupData[] | undefined
  >();
  const [selectedPickingGroupWithPda, setSelectedPickingGroupWithPda] = useState<number[]>();

  const globalWarehouse = useRecoilValue(warehouseAtom);
  const warehouseOption = useRecoilValue(warehouseOptionsAtom);
  const [queryParams, setQueryParams] = useState<SearchQuery>({
    page: DEFAULT_PAGE,
    size: DEFAULT_SIZE,
    warehouseId: Number(globalWarehouse),
  });
  const [hasSearched, setHasSearched] = useState(false);
  const [isPdaAssignModalOpen, setIsPdaAssignModalOpen] = useState(false);
  const [isSelectedPdaAssignModalOpen, setIsSelectedPdaAssignModalOpen] = useState(false);
  const refCodeOptions = useRecoilValue(refCodeOptionsAtom);
  const { data, mutate, isValidating } = useConditionalSWR<PickingGroups>(
    [`/picking-groups`, { ...queryParams, sort: 'id,desc' }],
    fetcher,
    hasSearched
  );

  const pickingGroupStatus = refCodeOptions?.pickingGroupStatus || [];
  const pickingGroupType = refCodeOptions?.pickingGroupType || [];

  const makeRowIndex = (data: PickingGroups) => {
    const { content, totalElements, size, number } = data;

    return content.map((item: SummarizedPickingGroup, index: number) => {
      function getDevice(item: SummarizedPickingGroup) {
        if (item.devices.length <= 1) return item.devices;
        if (item.devices.length > 1) {
          return item.devices.sort((a: device, b: device) => {
            // @ts-ignore
            return new Date(b.updatedAt) - new Date(a.updatedAt);
          });
        }
      }

      return {
        ...item,
        rowIndex: totalElements - size * number - index,
        updatedAt: getDevice(item)?.filter((item: device) => item.status === 'ACTIVE')[0]
          ?.updatedAt,
        updatedUser: getDevice(item)?.[0]
          ? convertUser(
              getDevice(item)?.[0].updatedUserName,
              getDevice(item)?.[0].updatedUserLoginId
            )
          : '',
      };
    });
  };

  const handleInitClick = async () => {
    setQueryParams({
      page: DEFAULT_PAGE,
      size: DEFAULT_SIZE,
      warehouseId: Number(globalWarehouse),
    });
    await mutate();
  };

  const handleSearchClick = async (form: Form) => {
    const updatedForm = _.omitBy(form, o => o === defaultOption.value);
    delete updatedForm[`dateType`];

    setQueryParams(({ size }) => ({ ...updatedForm, size, page: DEFAULT_PAGE }));
    setSelectedPickingGroupId(null);
    !hasSearched && setHasSearched(true);
    await mutate();
  };

  const [selectedPickingGroupId, setSelectedPickingGroupId] = useState(null);

  const handleRowClick: GridEventListener<GridEvents.rowClick> = params => {
    setSelectedPickingGroup(() => ({
      ...params.row,
      type: pickingGroupType.filter(v => v.value == params.row.type).map(v => v.displayName)[0],
    }));
    setSelectedPickingGroupId(orderId => {
      return params.row.id === orderId ? null : params.row.id;
    });
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  function handleSelectedPickingGroups(ids: number[]) {
    setSelectedPickingGroupWithPda(ids);
  }

  const handleRemovePickingGroup = async () => {
    if (selectedPickingGroupWithPda?.length !== 1 || selectedPickingGroupWithPda === undefined) {
      showDialog({
        message: '해제할 집품그룹을 선택해 주세요.',
        buttons: [
          {
            text: '확인',
          },
        ],
      });
      return;
    }
    showDialog({
      message: '선택한 집품그룹을 해제하시겠습니까?',
      buttons: [
        {
          text: '취소',
        },
        {
          text: '확인',
          marked: true,
          onClick: async () => {
            const response = await removePickingGroup(selectedPickingGroupWithPda[0]);
            if (response?.status === 200) {
              showSnackbar({
                message: '집품그룹 해제가 완료되었습니다.',
                severity: 'success',
              });
              await mutate();
            } else {
              showErrorDialog({
                title: '집품그룹 해제 실패',
                errorMessage: response?.data?.errorMessage,
                buttons: [{ text: '확인' }],
              });
            }
          },
        },
      ],
    });
  };

  const handleRemovePdaWithSelectedPickingGroup = () => {
    const length = selectedPickingGroupWithPda?.length;
    const assignedPda = data?.content
      .filter(pickingGroup => selectedPickingGroupWithPda?.includes(pickingGroup.id))
      .filter(s => s.status !== 'ASSIGNED_PDA').length as number;

    const request = {
      pickingGroupIds: selectedPickingGroupWithPda,
    };

    if (length === 0 || length === undefined) {
      showDialog({
        message: 'PDA 배정을 해제할 집품그룹을 선택해 주세요.',
        buttons: [
          {
            text: '확인',
          },
        ],
      });
    } else if (assignedPda > 0) {
      showDialog({
        message: '집품그룹상태 ‘PDA배정완료‘만 선택해 주세요.',
        buttons: [
          {
            text: '확인',
          },
        ],
      });
    } else {
      showDialog({
        message: '선택한 집품그룹의 PDA를 해제하시겠습니까?',
        buttons: [
          {
            text: '취소',
          },
          {
            text: '확인',
            marked: true,
            onClick: async () => {
              const response = await generateRemovePdas(request);
              if (response?.status === 200) {
                showSnackbar({
                  message: 'PDA 해제가 완료되었습니다.',
                  severity: 'success',
                });
                await mutate();
              } else {
                showErrorDialog({
                  title: 'PDA 해제 실패',
                  errorMessage: response?.data?.errorMessage,
                  buttons: [{ text: '확인' }],
                });
              }
            },
          },
        ],
      });
    }
  };

  const handleCreateSelectedPickingGroups = () => {
    const selectedPickingGroups = data?.content.filter(item =>
      selectedPickingGroupWithPda?.includes(item.id)
    );

    setSelectedPickingGroup1(selectedPickingGroups);

    const length = selectedPickingGroupWithPda?.length;

    // 선택된 집풉그룹
    const selectedPickingGroup = data?.content.filter(pickingGroup =>
      selectedPickingGroupWithPda?.includes(pickingGroup.id)
    );

    // 선택된 집품그룹 중 OPEN 상태가 아닌 집품그룹의 갯수
    const invalidStatusPickingGroup = selectedPickingGroup?.filter(
      pickingGroup => pickingGroup.status !== 'OPEN'
    ).length as number;

    // 선택된 집품그룹 중 대량집품그룹 갯수
    const bulkTypePickingGroup = selectedPickingGroup?.filter(
      pickingGroup => pickingGroup.type === 'BULK'
    ).length as number;

    // 선택된 집품그룹 중 개별집품그룹 갯수
    const singleTypePickingGroup = selectedPickingGroup?.filter(
      pickingGroup => pickingGroup.type === 'SINGLE'
    ).length as number;

    if (length === 0 || length === undefined) {
      showDialog({
        message: 'PDA 배정을 진행할 집품그룹을 선택해 주세요.',
        buttons: [
          {
            text: '확인',
          },
        ],
      });
    } else if (bulkTypePickingGroup > 0 && singleTypePickingGroup > 0) {
      showDialog({
        message: '동일한 집품구분의 집품그룹을 선택해주세요.',
        buttons: [
          {
            text: '확인',
          },
        ],
      });
    } else if (invalidStatusPickingGroup > 0) {
      showDialog({
        message: '집품그룹상태 ‘집품그룹 생성완료‘만 선택해 주세요. ',
        buttons: [
          {
            text: '확인',
          },
        ],
      });
    } else {
      setIsSelectedPdaAssignModalOpen(true);
    }
  };

  const handleCreatePickingGroups = () => {
    if (!hasSearched) {
      showDialog({
        message: `조회 조건 입력 후 '조회' 버튼을 클릭하신 후, 집품그룹 생성을 진행해 주세요.`,
        buttons: [
          {
            text: '확인',
          },
        ],
      });

      return;
    }

    showDialog({
      message: '집품그룹 전체 대상으로 PDA를 자동배정하시겠습니까?',
      buttons: [
        {
          text: '취소',
        },
        {
          text: '확인',
          marked: true,
          onClick: async () => {
            handleRegistration();
          },
        },
      ],
    });
  };

  const handleRegistration = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleClosePdaModal = () => {
    setIsPdaAssignModalOpen(false);
  };

  const handleCloseSelectedPdaModal = () => {
    setIsSelectedPdaAssignModalOpen(false);
  };

  return (
    <Page>
      <Typography variant="h2" sx={pageTitle}>
        집품그룹 관리
      </Typography>
      <Filter gridTemplateColumns={gridTemplateColumns}>
        <Filter.Select
          label="창고"
          field="warehouseId"
          options={[defaultOption, ...warehouseOption]}
          labelGridColumn="1/2"
          selectGridColumn="2/4"
          defaultValue={globalWarehouse}
        />
        <Filter.Select
          label="집풉그룹상태"
          field="status"
          options={[defaultOption, ...pickingGroupStatus]}
          labelGridColumn="4/5"
          selectGridColumn="5/10"
        />
        <Filter.Select
          label="집품구분"
          field="type"
          options={[defaultOption, ...pickingGroupType]}
          labelGridColumn="10/11"
          selectGridColumn="11/13"
        />
        <Filter.DateRangePickerWithSelect
          label="집품그룹 생성일"
          rangeTypeOptions={dateRangeTypeOptions}
          hasTypeSelect={false}
          gridRow={2}
          labelGridColumn="1/2"
          dateRangePickerGridColumn="2/5"
          rangeAmountSelectGridColumn="5/7"
        />
        <Filter.SearchWithSelect
          label="검색어"
          gridRow={2}
          searchOptions={searchKeywordOptions}
          labelGridColumn="7/8"
          selectGridColumn="8/10"
          inputGridColumn="10/13"
        />
        <Divider
          sx={{
            gridRow: 3,
            gridColumn: '1/-1',
            pb: 1,
            mb: 1,
          }}
        />
        <Filter.DefaultButtonGroup
          gridColumn="1/-1"
          gridRow={4}
          onInitClick={handleInitClick}
          onLookupClick={handleSearchClick}
          sx={{ display: 'flex', justifyContent: 'center' }}
        />
      </Filter>
      <Stack spacing={1} sx={listBoardTheme.container}>
        <Box sx={listBoardTheme.header}>
          <Typography variant="h6" sx={{ color: 'text.primary' }}>
            집품그룹 목록
          </Typography>
          <Box>
            <Button
              sx={listBoardTheme.headerButton}
              variant="outlined"
              suppressHydrationWarning
              onClick={handleRemovePickingGroup}
            >
              {`집품그룹 해제`}
            </Button>
            <Button
              sx={listBoardTheme.headerButton}
              variant="outlined"
              suppressHydrationWarning
              onClick={handleRemovePdaWithSelectedPickingGroup}
            >
              {`PDA배정 해제`}
            </Button>
            <Button
              sx={listBoardTheme.headerButton}
              variant="outlined"
              suppressHydrationWarning
              onClick={handleCreateSelectedPickingGroups}
            >
              {`PDA 선택배정 등록`}
            </Button>
            <Button
              sx={listBoardTheme.headerButton}
              variant="contained"
              suppressHydrationWarning
              onClick={handleCreatePickingGroups}
            >
              {`PDA 자동배정 등록`}
            </Button>
          </Box>
        </Box>
        <Box sx={{ height: '280px' }}>
          <TightDataGridPro
            rows={hasSearched ? (data?.content ? makeRowIndex(data) : []) : []}
            columns={gridColumns(selectedPickingGroupId, setIsPdaAssignModalOpen)}
            paginationMode="server"
            rowCount={hasSearched ? data?.totalElements ?? 0 : 0}
            rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            onRowClick={handleRowClick}
            onPageChange={page => setQueryParams(params => ({ ...params, page }))}
            pageSize={queryParams.size ?? DEFAULT_SIZE}
            onPageSizeChange={size => setQueryParams(params => ({ ...params, size }))}
            checkboxSelection
            disableSelectionOnClick
            selectionModel={selectedPickingGroupWithPda}
            onSelectionModelChange={ids => handleSelectedPickingGroups(ids as number[])}
            loading={isValidating}
            hasSearched={hasSearched}
            selectedPickingGroupId={selectedPickingGroupId}
          />
        </Box>
        {selectedPickingGroupId && (
          <>
            <Box sx={{ display: 'flex', justifyContent: 'center', padding: 1 }}>
              <ChevronDouble />
            </Box>
            <ShippingDataGrid
              pickingGroupId={selectedPickingGroupId}
              request={selectedPickingGroup}
            />
          </>
        )}
      </Stack>
      <FormModal open={isModalOpen} title={'PDA 자동배정 등록'} onClose={handleCloseModal}>
        <PickingGroupForm onClose={handleCloseModal} mutate={mutate} />
      </FormModal>
      <FormModal
        open={isSelectedPdaAssignModalOpen}
        title={'PDA 선택배정 관리'}
        onClose={handleCloseSelectedPdaModal}
      >
        <AssignSelectedPdaModal
          pickingGroupId={selectedPickingGroupWithPda}
          pickingGroups={selectedPickingGroup1}
          warehouseId={queryParams.warehouseId}
          warehouseOption={warehouseOption}
          onClose={handleCloseSelectedPdaModal}
          onMutate={mutate}
        />
      </FormModal>
      <FormModal
        open={isPdaAssignModalOpen}
        title={'PDA 배정변경 관리'}
        onClose={handleClosePdaModal}
      >
        <AssignPdaModal
          pickingGroupId={selectedPickingGroupId}
          pickingGroup={selectedPickingGroup}
          warehouseId={queryParams.warehouseId}
          warehouseOption={warehouseOption}
          onClose={handleClosePdaModal}
          onMutate={mutate}
        />
      </FormModal>
    </Page>
  );
};

export default PickingGroups;
