import { useQuery } from '@tanstack/react-query';
import fetcher from '../libs/common/fetcher';

export interface Center {
  id: number;
  code: string;
  name: string;
}

export const useCenter = () => {
  return useQuery<Center[]>({
    queryKey: ['centers'],
    queryFn: () => fetcher('/companies/centers', { sort: 'id,desc' }),
    // TODO: 현재는 리로드하기 전까지 한번 가져온 데이터가 유지됨. 적절한 캐싱 시간 설정 필요
    staleTime: Infinity,
  });
};
