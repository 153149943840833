import React, { KeyboardEvent, useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import _ from 'lodash';
import { datadogRum } from '@datadog/browser-rum';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import { inspectionAtom } from 'store/outbound/inspection.recoil';
import { barcodeAtom } from 'store/common/barcode.recoil';
import { stepAtom } from 'store/outbound/packing.recoil';
import { inputAutoFocusAtom } from 'store';
import type { InspectionItems } from 'types/outbound';
import { InspectionError, InspectionGift } from 'types/outbound';
import FormModal from 'components/common/FormModal';
import { isZpl } from '../../../../../../libs/common/isZpl';
import TimelineItem from '../../../components/TimelineItem';
import type { Row } from '../../../components/ListTable';
import usePopup from 'hooks/usePopup';
import { COLORS } from 'consts/common/colors';
import { INSPECTION, PACKING } from 'consts/outbound/messages';
import { BARCODE_SCAN_ACTION } from 'consts/outbound/barcodeScanAction';
import { beforeProgressTitle, inProgressTitle } from 'styles/timelineItemTitle';
import { koreaFilter, makeColumns, makeGiftInspectionItems, makeInspectionItems } from './utils';
import DuplicateBarcodeModal from '../DuplicateBarcodeModal';
import ErrorModal from '../ErrorModal';
import CompletedBottom from '../CompletedBottom';
import { fetchWrongPickedItem, finishInspection, updateCompletedInspection } from '../../services';
import Barcode from '../../../../../../components/common/Barcode';
import GiftModal from '../GiftModal';
import { isPrintingAtom, printerAtom } from '../../../../../../store/common/printer.recoil';
import getInspectionImage from '../getInspectionImage';
import { WAYBILL_FORMAT } from '../../../../../../consts/outbound/waybillFormat';
import ListTable from './ListTable';
import VisibleInput from '../../../components/VisibleInput';
import ImageZoomModal from '../../../../../../components/ImageZoomModal';
import { getFinalUrl } from '../../../../../../consts/outbound/imageUrl';

type Cell = {
  rowId: number;
  cell: string;
};

const defaultCell: Cell = {
  rowId: -1,
  cell: '',
};

const ItemListTitle = () => {
  return <Typography sx={beforeProgressTitle}>{INSPECTION.scanItems}</Typography>;
};

export type GiftDetails = {
  type: InspectionGift | null;
  items: InspectionItems[];
};

const defaultGiftDetails = {
  type: null,
  items: [],
};

export type ErrorDetails = {
  type: InspectionError | null;
  items: InspectionItems[];
};

const defaultErrorDetails = {
  type: null,
  items: [],
};

export const errorTitle = {
  WRONG: '오집품 아이템',
  MISSING: '미집품 아이템',
  DEFECT: '불량 아이템',
  EXPIRATION: '불량 아이템',
};

const ItemListTableWithBarcodeBtn = () => {
  const printer = useRecoilValue(printerAtom);
  const barcode = useRecoilValue(barcodeAtom);
  const inspection = useRecoilValue(inspectionAtom);
  const resetInspection = useResetRecoilState(inspectionAtom);
  const resetStep = useResetRecoilState(stepAtom);
  const [scannedBarcode, setScannedBarcode] = useState('');
  const [wrongItemBarcode, setWrongItemBarcode] = useState('');
  const [sameBarcodeItems, setSameBarcodeItems] = useState<InspectionItems[]>([]);
  const [selectedCell, setSelectedCell] = useState<Cell>(defaultCell);
  const [errorDetails, setErrorDetails] = useState<ErrorDetails>(defaultErrorDetails);
  const [giftDetails, setGiftDetails] = useState<GiftDetails>(defaultGiftDetails);
  const { showAlert, showSnackbar, showErrorDialog } = usePopup();
  const [inspectionItems, setInspectionItems] = useState<InspectionItems[]>([]);
  const [selectedInspectionItem, setSelectedInspectionItem] = useState<InspectionItems | null>();
  const [isPrinting, setIsPrinting] = useRecoilState(isPrintingAtom);
  const [inputAutoFocus, setInputAutoFocus] = useRecoilState(inputAutoFocusAtom);
  const [isInspectionCompleted, setIsInspectionCompleted] = useState(false);
  const [selectedImageUrl, setSelectedImageUrl] = useState('');

  useEffect(() => {
    const isAutoFocusOn =
      sameBarcodeItems.length === 0 &&
      selectedCell.cell !== 'checkedQuantity' &&
      errorDetails.type === null &&
      giftDetails.type === null;
    setInputAutoFocus(isAutoFocusOn);

    return () => {
      setInputAutoFocus(true);
    };
  }, [sameBarcodeItems, setInputAutoFocus, selectedCell, errorDetails, giftDetails]);

  useEffect(() => {
    if (!inspection) {
      return;
    }
    setInspectionItems(makeInspectionItems(inspection));
  }, [inspection]);

  const giftInspectionItems = makeGiftInspectionItems(inspection);

  const totalQuantity = inspectionItems.reduce((prev, item) => prev + item.orderQuantity, 0);

  const totalCheckedQuantity = inspectionItems.reduce(
    (prev, item) => prev + item.inspectedQuantity,
    0
  );
  const totalRemainingQuantity = inspectionItems.reduce(
    (prev, item) => prev + item.remainingQuantity,
    0
  );

  const handleStartNextInspection = async () => {
    datadogRum.addAction(`다음 검수시작 버튼 클릭`);
    // todo: resetInspection, resetStep 항상 같이 호출되므로 합치는 방향 고려.
    resetInspection();
    resetStep();
  };

  const handleStopInspection = () => {
    const omittedItems = _.filter(inspectionItems, item => {
      return item.remainingQuantity > 0;
    });

    setErrorDetails({ type: 'MISSING', items: omittedItems });
  };

  const handleScannedBarcode = async (e: KeyboardEvent) => {
    if (e.key !== 'Enter') {
      return;
    }

    if (scannedBarcode.trim() === '') {
      showAlert({ message: '아이템바코드를 입력해 주세요.' });
      return;
    }

    if (e.key === 'Enter' && koreaFilter(scannedBarcode, showAlert)) {
      setScannedBarcode('');

      return;
    }

    if (scannedBarcode.toLowerCase() === BARCODE_SCAN_ACTION.finishGiftInspection) {
      setGiftDetails({ type: 'GIFT', items: giftInspectionItems });
      setScannedBarcode('');
      return;
    }

    if (scannedBarcode.toLowerCase() === BARCODE_SCAN_ACTION.startInspection) {
      handleStartNextInspection();
      setScannedBarcode('');
      return;
    }

    await checkInspectedItems();
  };

  const handleFinishInspection = async () => {
    const response = await finishInspection(barcode);

    if (response?.status === 200) {
      datadogRum.addAction(`수기 검수완료 성공: ${barcode}`);
      setIsInspectionCompleted(true);
    } else if (response?.status === 500) {
      datadogRum.addAction(`이미 검수완료된 주문: ${barcode}`);
      showAlert({
        message: INSPECTION.inspectionCompleteFailure,
        onClick: async () => {
          //TODO: Maximum call stack size exceeded 에러를 해결하기 위해 임시방편으로 await를 붙임.
          await resetInspection();
          resetStep();
        },
      });
    } else {
      datadogRum.addError(`수기 검수완료 실패: ${barcode}`, {
        errorMessage: response?.data.errorMessage as string,
      });
      showAlert({
        message: response?.data?.errorMessage as string,
        onClick: async () => {
          //TODO: Maximum call stack size exceeded 에러를 해결하기 위해 임시방편으로 await를 붙임.
          await resetInspection();
          resetStep();
        },
      });
    }
  };

  const handleFinishGiftInspection = async () => {
    await setGiftDetails({ type: 'GIFT', items: giftInspectionItems });
  };

  const makeGiftInspection = async () => {
    const giftShippingItemIds = giftInspectionItems.map(item => item.shippingItemId);
    const updatedInspectionItems = inspectionItems.map(inspectionItem =>
      giftShippingItemIds.includes(inspectionItem.shippingItemId)
        ? {
            ...inspectionItem,
            inspectedQuantity: inspectionItem.orderQuantity,
            checkedQuantity: inspectionItem.orderQuantity,
            remainingQuantity: 0,
          }
        : inspectionItem
    );
    setGiftDetails(defaultGiftDetails);
    setInspectionItems(updatedInspectionItems);
    await inspectionCompleted(updatedInspectionItems);
  };

  const checkInspectedItems = async () => {
    const items = inspectionItems.filter(v => {
      return v.onlineBarcode == scannedBarcode || v.offlineBarcode == scannedBarcode;
    });
    if (items.length === 1) {
      setSelectedInspectionItem(items[0]);
      await increaseInspectedQuantity(items[0]);
    } else if (items.length > 1) {
      setSameBarcodeItems(items);
      setWrongItemBarcode(scannedBarcode);
      datadogRum.addAction(`상품바코드 중복: ${items[0].shippingItemId}`);
    } else if (items.length === 0) {
      const wrongPickedItem = await fetchWrongPickedItem(scannedBarcode);
      const length = wrongPickedItem.length;
      let item;
      if (length === 1) {
        item = wrongPickedItem[0];
      }
      const wrongPickedItemWidBarcode = { ...item, barcode: scannedBarcode };
      setErrorDetails({ type: 'WRONG', items: [wrongPickedItemWidBarcode] });
    }

    setScannedBarcode('');
  };

  const handleWrongItem = async () => {
    const wrongPickedItem = await fetchWrongPickedItem(wrongItemBarcode);
    const length = wrongPickedItem.length;
    let item;
    if (length === 1) {
      item = wrongPickedItem[0];
    }
    const wrongPickedItemWidBarcode = { ...item, barcode: wrongItemBarcode };
    setErrorDetails({ type: 'WRONG', items: [wrongPickedItemWidBarcode] });
    setScannedBarcode('');
  };

  const selectDuplicatedBarcode = async (item: Row | null) => {
    setSameBarcodeItems([]);

    if (!item) return;

    const selectDuplicatedItem = inspectionItems.filter(v => {
      return v.shippingItemId === item.shippingItemId;
    });

    // currentItem(정상수량 인풋창을 활성화 시키기 위함)에 해당아이템으로 설정
    setSelectedInspectionItem(selectDuplicatedItem[0]);
    await increaseInspectedQuantity(selectDuplicatedItem[0]);
  };

  const increaseInspectedQuantity = async (item: InspectionItems) => {
    const { orderQuantity, inspectedQuantity, itemCode, shippingItemId } = item;
    if (orderQuantity <= inspectedQuantity) {
      datadogRum.addAction(`검수 수량 초과: ${itemCode}`, {
        shippingItemId,
        quantity: inspectedQuantity,
      });
      showAlert({
        message: '최대 입력수량은 아이템수량 기준입니다. 입력수량을 확인 후 다시 입력해 주세요.',
      });
      setSelectedCell(defaultCell);
      return;
    }

    const newInspectionItems = inspectionItems.map(inspectionItem =>
      inspectionItem.shippingItemId == shippingItemId
        ? {
            ...inspectionItem,
            inspectedQuantity: inspectionItem.inspectedQuantity + 1,
            checkedQuantity: inspectionItem.inspectedQuantity + 1,
            remainingQuantity: inspectionItem.remainingQuantity - 1,
          }
        : inspectionItem
    );
    setInspectionItems(newInspectionItems);

    await inspectionCompleted(newInspectionItems);

    datadogRum.addAction(`상품검수 바코드 스캔 : ${shippingItemId}`);
  };

  const handleClickCell = async (row: Row, cell: string) => {
    setSelectedCell({ rowId: row.shippingItemId as number, cell });

    if (cell === 'checkFaulty') {
      !row.checkFaulty &&
        setErrorDetails({ type: 'DEFECT', items: [row as unknown as InspectionItems] });
      return;
    }

    if (cell === 'imageUrl') {
      typeof row.imageUrl === 'string' && setSelectedImageUrl(getFinalUrl(row.imageUrl));
      return;
    }

    if (selectedInspectionItem?.shippingItemId === row.shippingItemId) {
      if (cell === 'checkedQuantity') return;

      await increaseInspectedQuantity(row as unknown as InspectionItems);
    }
  };

  const onChangeInput = async (quantity: string) => {
    if (selectedInspectionItem) {
      const quantityAsNumber = parseInt(quantity);
      if (quantityAsNumber > selectedInspectionItem.orderQuantity) {
        showAlert({
          message: '최대 입력수량은 아이템수량 기준입니다. 입력수량을 확인 후 다시 입력해 주세요.',
        });
        setSelectedCell(defaultCell);
        return;
      }
      const newInspectionItems = inspectionItems.map(inspectionItem =>
        inspectionItem.shippingItemId == selectedInspectionItem?.shippingItemId
          ? {
              ...inspectionItem,
              inspectedQuantity: isNaN(quantityAsNumber) ? 0 : quantityAsNumber,
              checkedQuantity: quantity,
              remainingQuantity:
                inspectionItem.orderQuantity - (isNaN(quantityAsNumber) ? 0 : quantityAsNumber),
            }
          : inspectionItem
      );
      const updatedInspectionItem = newInspectionItems.filter(
        inspectionItem => inspectionItem.shippingItemId == selectedInspectionItem?.shippingItemId
      );

      if (
        updatedInspectionItem.length == 1 &&
        selectedInspectionItem.orderQuantity == updatedInspectionItem[0]?.inspectedQuantity
      ) {
        onBlurInput();
      }

      setInspectionItems(newInspectionItems);
      await inspectionCompleted(newInspectionItems);
    }
  };

  const onBlurInput = () => {
    setSelectedInspectionItem(null);
    setSelectedCell(defaultCell);
  };

  const printImage = async (image: string) => {
    if (isZpl(image)) {
      printer.send(
        `${image}`,
        () => setIsPrinting(false),
        (error: string) => {
          showErrorDialog({
            title: '출고건 출력 실패',
            top: '출고건 출력을 실패하였습니다',
            errorMessage: error,
            buttons: [{ text: '확인' }],
          });
          setIsPrinting(false);
        }
      );
    } else {
      printer.convertAndSendFile(
        `data:image/${WAYBILL_FORMAT};base64,${image}`,
        () => setIsPrinting(false),
        (error: string) => {
          showErrorDialog({
            title: '출고건 출력 실패',
            top: '출고건 출력을 실패하였습니다',
            errorMessage: error,
            buttons: [{ text: '확인' }],
          });
          setIsPrinting(false);
        }
      );
    }
  };

  async function inspectionCompleted(newInspectionItems: InspectionItems[]) {
    const totalInspectedQuantity = newInspectionItems.reduce(
      (prev, item) => prev + item.inspectedQuantity,
      0
    );

    const requestPrint = async () => {
      if (!printer) {
        showAlert({ message: PACKING.printNotPrepared });
        return;
      }

      setIsPrinting(true);

      const response = await getInspectionImage(inspection.inspectionId);
      if (response?.status === 200) {
        setIsPrinting(false);
        printImage(response.data?.base64Image);
      } else {
        setIsPrinting(false);
        showErrorDialog({
          title: '이미지 요청 실패',
          errorMessage: response?.data?.errorMessage,
          buttons: [{ text: '확인' }],
        });
      }
    };

    if (totalQuantity === totalInspectedQuantity) {
      const response = await updateCompletedInspection({
        inspectionId: inspection.inspectionId,
        inspectionItems: newInspectionItems,
      });

      if (response?.status === 200) {
        datadogRum.addAction(`상품 검수완료 및 이력저장 성공: ${barcode}`);
        showSnackbar({ message: '검수 이력이 저장되었습니다.', severity: 'success' });
        requestPrint();
      } else {
        datadogRum.addError(`상품 검수완료 후, 검수이력 저장 실패: ${barcode}`, {
          errorMessage: response?.data.errorMessage as string,
        });
        showAlert({
          message: '검수 이력이 저장되지 않았습니다.',
        });
      }
    }
  }

  const onKeyDownInput = (key: string) => {
    if (key === 'Enter') {
      setSelectedCell(defaultCell);
    }
  };

  const closeErrorModal = () => {
    setErrorDetails(defaultErrorDetails);
  };

  return (
    <>
      <Typography sx={inProgressTitle}>{INSPECTION.scanItems}</Typography>
      <Typography
        variant="h6"
        sx={{
          display: 'flex',
          margin: '10px 0 12px 0',
          ...styles.subTitle,
        }}
      >
        {INSPECTION.inspectItemsInfo}
        <VisibleInput
          value={scannedBarcode}
          onChange={e => setScannedBarcode(e.target.value)}
          onKeyUp={handleScannedBarcode}
          data-testid={'itemListLine-invisible-input'}
        />
      </Typography>
      <ListTable
        rows={inspectionItems.map((item, index) => ({
          ...item,
          id: item.shippingItemId,
          rowIndex: index + 1,
        }))}
        columns={makeColumns(
          totalQuantity,
          totalCheckedQuantity,
          totalRemainingQuantity,
          onChangeInput,
          onKeyDownInput,
          onBlurInput,
          selectedInspectionItem
        )}
        total={totalQuantity}
        isQuantitiesFooter={true}
        selectedShippingItemId={selectedInspectionItem?.shippingItemId}
        onClickCell={handleClickCell}
        sx={styles.listTable}
      />
      {totalQuantity === totalCheckedQuantity || isInspectionCompleted ? (
        <CompletedBottom
          totalQuantity={totalQuantity}
          totalCheckedQuantity={totalCheckedQuantity}
          onStartInspection={handleStartNextInspection}
          totalToteBarcodes={inspection.toteBarcodes}
        />
      ) : (
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <Box sx={styles.barcodeButtonContainer}>
            <Button
              onClick={handleStopInspection}
              size="large"
              variant="contained"
              sx={styles.button}
            >
              {INSPECTION.stopInspection}
            </Button>
            {(inspection.type === 'SINGLE_BULK' || inspection.type === 'INVENTORY_TRANSFER') && (
              <Button
                onClick={handleFinishInspection}
                size="large"
                variant="outlined"
                sx={styles.button}
              >
                {INSPECTION.finishInspection}
              </Button>
            )}
          </Box>
          <Box sx={styles.barcodeButtonContainer}>
            <Button
              onClick={handleFinishGiftInspection}
              size="large"
              variant="outlined"
              sx={styles.button}
            >
              {INSPECTION.finishGiftInspection}
            </Button>
          </Box>
        </Box>
      )}
      <FormModal
        open={sameBarcodeItems.length > 0}
        title={'바코드 동일 아이템'}
        onClose={() => {
          setSameBarcodeItems([]);
        }}
      >
        <DuplicateBarcodeModal
          items={sameBarcodeItems}
          select={selectDuplicatedBarcode}
          isWrongItem={handleWrongItem}
        />
      </FormModal>
      <FormModal
        open={!!giftDetails.type}
        title={'특전/포스터 등 수량자동입력 아이템'}
        onClose={() => setGiftDetails(defaultGiftDetails)}
      >
        <GiftModal
          giftDetails={giftDetails}
          onClose={() => setGiftDetails(defaultGiftDetails)}
          giftInspection={makeGiftInspection}
          selectedImageUrl={selectedImageUrl}
          setSelectedImageUrl={setSelectedImageUrl}
        />
      </FormModal>
      <FormModal
        open={!!errorDetails.type}
        title={errorTitle[errorDetails.type as keyof typeof errorTitle]}
        onClose={closeErrorModal}
        canCloseByBackground={false}
      >
        <ErrorModal
          inspectionId={inspection.inspectionId}
          errorDetails={errorDetails}
          onClose={closeErrorModal}
          resetStep={resetStep}
        />
      </FormModal>
      <ImageZoomModal imageUrl={selectedImageUrl} onClose={() => setSelectedImageUrl('')} />
    </>
  );
};

const ItemListLine = () => {
  return (
    <>
      <TimelineItem
        isLast
        step={1}
        beforeProgressComponent={<ItemListTitle />}
        inProgressComponent={<ItemListTableWithBarcodeBtn />}
        afterProgressComponent={null}
      />
    </>
  );
};

export default ItemListLine;

const styles = {
  subTitle: {
    fontWeight: 700,
    color: COLORS.black,
    fontSize: '20px',
  },
  listTable: {
    maxHeight: '400px',
    mb: 3,
    '& .MuiTableCell-root.MuiTableCell-head': {
      fontSize: '15px',
    },
    '& .MuiTableCell-root.MuiTableCell-body': {
      fontSize: '16px',
    },
    '& .MuiTableCell-root.MuiTableCell-footer': {
      fontSize: '16px',
      height: '40px',
    },
  },
  barcodeButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
  },
  button: { height: '40px', fontSize: '17px' },
};
