import { Dispatch, SetStateAction } from 'react';
import { useRecoilValue } from 'recoil';
import Box from '@mui/system/Box';
import Radio from '@mui/material/Radio';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { Warehouse } from '../../../../store/outbound/warehouse.recoil';
import convertWarehouseFullCode from '../../../../libs/warehouse/convertWarehouseFullCode';
import refCodeOptionsAtom from '../../../../store/outbound/refCode.recoil';
import AutoContainImage from '../../../../components/common/Image/AutoContainImage';
import { getFinalUrl } from '../../../../consts/outbound/imageUrl';

const gridColumns = (
  warehouseOption: Warehouse[],
  selected: number | undefined,
  setSelected: Dispatch<SetStateAction<number | undefined>>
) => {
  const refCodeOptions = useRecoilValue(refCodeOptionsAtom);
  const inventoryLpnStatusOptions = refCodeOptions?.inventoryLpnStatus || [];
  const binTypeOptions = refCodeOptions?.binType || [];
  const columns: GridColDef[] = [
    {
      field: 'select',
      headerName: '',
      headerAlign: 'center',
      align: 'center',
      width: 50,
      editable: false,
      renderCell: (params: GridRenderCellParams) => (
        <Radio
          size={'small'}
          checked={selected === params.id}
          onChange={() => {
            setSelected(Number(params.id));
          }}
        />
      ),
    },
    {
      field: 'rowIndex',
      headerName: 'NO',
      minWidth: 20,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'warehouseId',
      headerName: '창고',
      minWidth: 120,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) => {
        return convertWarehouseFullCode(value, warehouseOption);
      },
    },
    {
      field: 'binType',
      headerName: '로케이션구분',
      minWidth: 120,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) => binTypeOptions?.find(v => v.value === value)?.displayName,
    },
    {
      field: 'locationCode',
      headerName: '로케이션',
      minWidth: 170,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'lpnSerialNumber',
      headerName: 'LPN',
      minWidth: 120,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'expirationDate',
      headerName: '유효기한',
      minWidth: 100,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'status',
      headerName: '상태',
      minWidth: 50,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) =>
        inventoryLpnStatusOptions?.find(v => v.value === value)?.displayName,
    },
    {
      field: 'itemCode',
      headerName: '아이템코드',
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'imageUrl',
      headerName: '이미지',
      minWidth: 50,
      maxWidth: 50,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: GridRenderCellParams) =>
        params.value && (
          <Box
            sx={{ display: 'flex', alignItems: 'center', padding: 0.5, justifyContent: 'center' }}
          >
            <AutoContainImage src={getFinalUrl(params.value)} imageContainerSize={30} />
          </Box>
        ),
    },
    {
      field: 'itemName',
      headerName: '아이템명',
      minWidth: 100,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'barcode',
      headerName: '바코드',
      minWidth: 120,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'offlineBarcode',
      headerName: '오프라인바코드',
      minWidth: 120,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'quantity',
      headerName: '로케이션가용수량',
      minWidth: 120,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'pendingQuantity',
      headerName: '로케이션진행수량',
      minWidth: 120,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
  ];
  return columns;
};

export default gridColumns;
