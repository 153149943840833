import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import _ from 'lodash';
import React, { ChangeEvent, useEffect, useState } from 'react';
import Box from '@mui/system/Box';
import Typography from '@mui/material/Typography';
import usePopup from 'hooks/usePopup';
import SaveButtonGroup from '../../../components/SaveButtonGroup';
import Table, { TableCell, TableRow } from '../../../components/Table2';
import { FONT_SIZE } from '../../../../../consts/common/typography';
import { SelectChangeEvent } from '@mui/material';
import {
  createItemOption,
  CreateItemOptionRequest,
  getItemOption,
  updateItemOption,
  UpdateItemOptionRequest,
} from '../services/excelDownload';

const modalWidth = 550;
const modalFontSize = FONT_SIZE.medium;

export type ItemOption = {
  id: number;
  itemName: string;
  itemCode: string;
  imageUrl: string;
  barcode: string;
  offlineBarcode: string;
  weight: number;
  width: number;
  height: number;
  length: number;
};

type Props = {
  itemOptionId: number;
  onClose: () => void;
  onMutate: () => void;
};
export const initialState: ItemOption = {
  id: -1,
  itemName: '',
  itemCode: '',
  imageUrl: '',
  barcode: '',
  offlineBarcode: '',
  weight: 0,
  width: 0,
  height: 0,
  length: 0,
};

const ItemOptionForm = (props: Props) => {
  const { onClose, onMutate, itemOptionId } = props;
  const { showErrorDialog, showSnackbar, showAlert } = usePopup();
  const [itemOption, setItemOption] = useState<ItemOption>(initialState);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (itemOptionId < 0) {
      return;
    }
    const getItemOptionInfo = async () => {
      const response = await getItemOption(itemOptionId);
      if (response?.status === 200) {
        setItemOption(response.data);
        return;
      }
    };
    itemOptionId != itemOption.id && getItemOptionInfo();
  });

  const handleCreate = async () => {
    setIsLoading(true);

    if (!isValidateForCreateAndUpdate()) return;

    const request: CreateItemOptionRequest = {
      goodsCode: itemOption.itemCode,
      weight: itemOption.weight,
      widthInMillimeters: itemOption.width,
      heightInMillimeters: itemOption.height,
      lengthInMillimeters: itemOption.length,
    };

    const response = await createItemOption(request);
    if (response?.status === 200) {
      showSnackbar({ message: '아이템옵션 등록이 완료되었습니다.', severity: 'success' });
      setItemOption(initialState);
      await onMutate();
      onClose();
    } else {
      showErrorDialog({
        title: '아이템옵션 등록 실패',
        errorMessage: response?.data.errorMessage,
        buttons: [{ text: '확인' }],
      });
    }
    setIsLoading(false);
  };

  const isValidateForCreateAndUpdate = () => {
    if (itemOption.itemCode === '') {
      showAlert({ message: '아이템코드를 입력해 주세요.' });
      setIsLoading(false);
      return false;
    } else if (
      isNullOrZero(itemOption.weight) ||
      isNullOrZero(itemOption.width) ||
      isNullOrZero(itemOption.height) ||
      isNullOrZero(itemOption.length)
    ) {
      showAlert({ message: '부피/무게정보를 입력해 주세요.' });
      setIsLoading(false);
      return false;
    }
    return true;
  };

  // value === null || value === 0 || value === ''
  const isNullOrZero = (value: number) => _.isNil(value) || value === 0;

  const handleUpdate = async () => {
    setIsLoading(true);

    if (!isValidateForCreateAndUpdate()) return;

    const request: UpdateItemOptionRequest = {
      itemOptionId: itemOption.id,
      weight: itemOption.weight,
      widthInMillimeters: itemOption.width,
      heightInMillimeters: itemOption.height,
      lengthInMillimeters: itemOption.length,
    };

    const response = await updateItemOption(request);
    if (response?.status === 200) {
      showSnackbar({ message: '아이템옵션 수정이 완료되었습니다.', severity: 'success' });
      setItemOption(initialState);
      await onMutate();
      onClose();
    } else {
      showErrorDialog({
        title: '아이템옵션 수정 실패',
        errorMessage: response?.data.errorMessage,
        buttons: [{ text: '확인' }],
      });
    }
    setIsLoading(false);
  };

  const handleChange = (
    event: SelectChangeEvent | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name } = event.target;
    setItemOption({ ...itemOption, [name]: makeValue(event.target) });
  };

  return (
    <Box width={modalWidth}>
      <Box>
        <Typography sx={styles.modalSubTitle}>아이템옵션 기본정보</Typography>
      </Box>
      <Table>
        <TableRow>
          <TableCell label={'아이템코드'} labelSx={styles.label} required={itemOptionId == -1}>
            {itemOptionId !== -1 ? (
              <Typography fontSize={modalFontSize} fontWeight={'bold'}>
                {itemOption.itemCode ?? ''}
              </Typography>
            ) : (
              <FormControl fullWidth>
                <OutlinedInput
                  name={'itemCode'}
                  value={itemOption.itemCode}
                  onChange={handleChange}
                  sx={styles.input}
                  type={'text'}
                />
              </FormControl>
            )}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            label={'아이템명'}
            sx={styles.itemCell}
            labelSx={styles.label}
            valueSx={styles.itemValue}
            required={!itemOptionId}
          >
            <Typography fontSize={modalFontSize} fontWeight={'bold'}>
              {itemOption.itemName}
            </Typography>
          </TableCell>
        </TableRow>
      </Table>
      <Box>
        <Typography sx={styles.modalSubTitle}>아이템옵션 부피/무게정보</Typography>
      </Box>
      <Table>
        <TableRow>
          <TableCell label={'실제무게(g)'} labelSx={styles.label} required={!!itemOptionId}>
            <FormControl fullWidth>
              <OutlinedInput
                name={'weight'}
                value={itemOption.weight}
                onChange={handleChange}
                sx={styles.input}
                type={'text'}
              />
            </FormControl>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell label={'가로(mm)'} labelSx={styles.label} required={!!itemOptionId}>
            <FormControl fullWidth>
              <OutlinedInput
                name={'width'}
                value={itemOption.width}
                onChange={handleChange}
                sx={styles.input}
                type={'text'}
              />
            </FormControl>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell label={'세로(mm)'} labelSx={styles.label} required={!!itemOptionId}>
            <FormControl fullWidth>
              <OutlinedInput
                name={'length'}
                value={itemOption.length}
                onChange={handleChange}
                sx={styles.input}
                type={'text'}
              />
            </FormControl>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell label={'높이(mm)'} labelSx={styles.label} required={!!itemOptionId}>
            <FormControl fullWidth>
              <OutlinedInput
                name={'height'}
                value={itemOption.height}
                onChange={handleChange}
                sx={styles.input}
                type={'text'}
              />
            </FormControl>
          </TableCell>
        </TableRow>
      </Table>
      <SaveButtonGroup
        onSaveClick={itemOptionId !== -1 ? handleUpdate : handleCreate}
        onCloseClick={onClose}
        isLoading={isLoading}
      />
    </Box>
  );
};

export default ItemOptionForm;

type Target =
  | (EventTarget & (HTMLInputElement | HTMLTextAreaElement))
  | (EventTarget & { value: string; name: string });

const makeValue = (target: Target) => {
  if (target.name !== 'itemCode') {
    return Number(target.value);
  }
  return target.value;
};

const styles = {
  modalSubTitle: { fontSize: '20px', mb: 1.5 },
  label: { minHeight: 34, height: 'auto', minWidth: 120, maxWidth: 120, fontSize: modalFontSize },
  itemCell: { alignItems: 'stretch' },
  itemValue: { display: 'flex', alignItems: 'center', my: 1 },
  input: { height: 28, width: 420, ml: -1.5, fontSize: modalFontSize },
};
