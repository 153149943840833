import React, { useRef, useState } from 'react';
import _ from 'lodash';
import { useRecoilValue } from 'recoil';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import { GridEventListener, GridEvents } from '@mui/x-data-grid-pro';
import Page from '../../../../../components/common/Layout/Page';
import Filter, { Form } from '../../../../../components/common/Filter';
import TightDataGridPro from '../../../../../components/common/TightDataGridPro';
import { warehouseAtom } from '../../../../../store/outbound/warehouse.recoil';
import { ROWS_PER_PAGE_OPTIONS } from '../../../../../consts/common/dataGrid';
import { DEFAULT_PAGE, DEFAULT_SIZE } from '../../../../../consts/common/pageAndSize';
import refCodeOptionsAtom from '../../../../../store/outbound/refCode.recoil';
import fetcher from '../../../../../libs/common/fetcher';
import pageTitle from '../../../../../styles/pageTitle';
import { listBoardTheme } from '../../../../../styles/customedMuiTheme';
import { Options } from '../../../../../types/form';
import TopBoard from '../../../components/TopBoard';
import useConditionalSWR from '../../../components/useConditionalSwr';
import { defaultOption } from '../../../info/devices/index.page';
import makeGridColDef from './gridColDef';

const earlyOrderTypeOption = [
  { displayName: '선주문', value: 'earlyOrder' }, //TODO: value 확인요망
  { displayName: '일반', value: 'normal' }, //TODO: value 확인요망
];

const dateRangeTypeOptions: Options = [
  { displayName: '입고반품일', field: 'receivedDate' },
  { displayName: '검수일', field: 'inspectionAt' }, //TODO: field 확인요망
];

const searchKeywordOptions: Options = [
  { displayName: '입고명세서', field: 'receivingName' },
  { displayName: '공급업체', field: 'supplierName' }, //TODO: field 확인요망
];

const gridTemplateColumns = [
  '75px',
  'minmax(45px, 0.5fr)',
  'minmax(45px, 0.5fr)',
  '50px',
  'minmax(55px, 2fr)',
  'minmax(55px, 1.4fr)',
  '70px',
  'minmax(55px, 0.6fr)',
  'minmax(55px, 0.6fr)',
  '50px',
  'minmax(55px, 1.0fr)',
  'minmax(55px, 1.0fr)',
].reduce((prevValue, labelWidth) => prevValue + labelWidth + ' ', '');

type SearchQuery = {
  page?: number;
  size?: number;
  status?: string;
  warehouseId?: number;
  step?: string;
};

type InboundReceiptConfirmContent = {
  warehouseId: number;
  receivedDate: string;
  inspectionAt: string;
  earlyOrderType: string;
  status: string;
  receivingId: string;
  supplierName: string;
  sku: number;
  quantity: number;
  inspectedCount: number;
  totalCost: number;
};

type InboundReceiptConfirm = {
  content: InboundReceiptConfirmContent[];
  totalElements: number;
  size: number;
  number: number;
};

const limitReceivingStatusOptions = (
  options: { displayName: string; value: string }[] | undefined
) => {
  const availableStatus = ['REINSPECTING', 'INSPECTED', 'COMPLETED'];
  return options?.filter(option => availableStatus.includes(option.value));
};

const STEP = 'CONFIRM';

const ReturnReceiptsConfirms = () => {
  const globalWarehouse = useRecoilValue(warehouseAtom);
  const refCodeOptions = useRecoilValue(refCodeOptionsAtom);
  const receivingStatusOptions = limitReceivingStatusOptions(refCodeOptions?.receivingStatus) ?? [];

  const initButtonRef = useRef<{ handleClick: () => void }>();
  const [hasSearched, setHasSearched] = useState(false);
  const [queryParams, setQueryParams] = useState<SearchQuery>({
    page: DEFAULT_PAGE,
    size: DEFAULT_SIZE,
    warehouseId: Number(globalWarehouse),
    step: STEP,
  });

  const navigate = useNavigate();

  const { data, isValidating, mutate } = useConditionalSWR<InboundReceiptConfirm>(
    [`receivings/returns/search`, { ...queryParams, sort: 'id,desc' }],
    fetcher,
    hasSearched
  );

  const topBoard = [
    {
      title: '입고확정대기',
      value: 0,
      field: 'confirmedYet',
    },
    {
      title: '재검수 요청',
      value: 0,
      field: 'rejected',
    },
  ];

  const makeRowIndex = (data: InboundReceiptConfirm) => {
    const { content, totalElements, size, number } = data;

    return content.map((item: InboundReceiptConfirmContent, index: number) => ({
      ...item,
      id: item.receivingId,
      rowIndex: totalElements - size * number - index,
      inspectedCount: item.quantity, //TODO: 정상수량이 생성될 때까지 입고등록 수량을 정상수량으로 대체함.
    }));
  };

  const handleInitClick = async () => {
    setQueryParams({
      page: DEFAULT_PAGE,
      size: DEFAULT_SIZE,
      warehouseId: Number(globalWarehouse),
      step: STEP,
    });
    await pageMutate();
  };

  const handleSearchClick = async (form: Form) => {
    const updatedForm = _.omitBy(form, o => o === defaultOption.value);
    delete updatedForm[`dateType`];

    setQueryParams(({ size }) => ({
      ...updatedForm,
      warehouseId: Number(globalWarehouse),
      step: STEP,
      size,
      page: DEFAULT_PAGE,
    }));
    !hasSearched && setHasSearched(true);
    await pageMutate();
  };

  const pageMutate = async () => {
    await mutate();
    // await countMutate();
  };

  const handleClickTopBoardCount = async (field: string) => {
    initButtonRef.current && initButtonRef.current?.handleClick();
    !hasSearched && setHasSearched(true);

    setQueryParams({
      page: DEFAULT_PAGE,
      size: DEFAULT_SIZE,
      status: field,
      warehouseId: Number(globalWarehouse),
      step: STEP,
    });
  };

  const handleExcelDownload = async () => {
    return;
  };

  const handleRowClick: GridEventListener<GridEvents.rowClick> = params => {
    navigate(`/gms/inbound/return/confirm-receiving/confirm`, {
      state: { receivingId: params.row.receivingId },
    });
  };

  return (
    <Page>
      <Typography variant="h2" sx={pageTitle}>
        입고반품 확정 관리
      </Typography>
      <TopBoard data={topBoard} onClick={handleClickTopBoardCount} />
      <Filter gridTemplateColumns={gridTemplateColumns}>
        <Filter.Select
          label="입고확정상태"
          field="status"
          options={[defaultOption, ...receivingStatusOptions]}
          labelGridColumn="1/2"
          selectGridColumn="2/4"
          defaultValue={queryParams.status ?? 'ALL'}
        />
        <Filter.Select
          label="선주문구분"
          field="orderType"
          options={[defaultOption, ...earlyOrderTypeOption]}
          labelGridColumn="7/8"
          selectGridColumn="8/10"
        />
        <Filter.DateRangePickerWithSelect
          label="기간"
          rangeTypeOptions={dateRangeTypeOptions}
          gridRow={2}
          labelGridColumn="1/2"
          selectGridColumn="2/4"
          dateRangePickerGridColumn="4/6"
          rangeAmountSelectGridColumn="6/7"
        />
        <Filter.SearchWithSelect
          label="검색어"
          gridRow={2}
          searchOptions={searchKeywordOptions}
          labelGridColumn="7/8"
          selectGridColumn="8/10"
          inputGridColumn="10/13"
        />
        <Divider sx={styles.divider} />
        <Filter.DefaultButtonGroup
          initRef={initButtonRef}
          gridColumn="1/-1"
          gridRow={5}
          onInitClick={handleInitClick}
          onLookupClick={handleSearchClick}
          sx={{ display: 'flex', justifyContent: 'center' }}
        />
      </Filter>
      <Stack spacing={1} sx={listBoardTheme.container}>
        <Box sx={listBoardTheme.header}>
          <Typography variant="h6" sx={{ color: 'text.primary' }}>
            입고확정 목록
          </Typography>
          <Box>
            <LoadingButton
              sx={styles.gridBorderTopButton}
              variant="outlined"
              suppressHydrationWarning
              onClick={handleExcelDownload}
              disabled={true}
            >
              {`엑셀다운로드`}
            </LoadingButton>
          </Box>
        </Box>
        <TightDataGridPro
          rows={hasSearched && data?.content ? makeRowIndex(data) : []}
          columns={makeGridColDef(receivingStatusOptions)}
          paginationMode="server"
          rowCount={hasSearched ? data?.totalElements ?? 0 : 0}
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          onRowClick={handleRowClick}
          onPageChange={page => setQueryParams(params => ({ ...params, page }))}
          pageSize={queryParams.size ?? DEFAULT_SIZE}
          onPageSizeChange={size => setQueryParams(params => ({ ...params, size }))}
          loading={isValidating}
          hasSearched={hasSearched}
        />
      </Stack>
    </Page>
  );
};

export default ReturnReceiptsConfirms;

const styles = {
  divider: {
    gridRow: 4,
    gridColumn: '1/-1',
    pb: 1,
    mb: 1,
  },
  gridBorderTopButton: {
    marginRight: 1,
  },
};
