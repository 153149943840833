import { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { makeRowIndex } from 'libs/common/makeRowIndex';
import InfoListSection from 'components/common/typography/InfoListSection';
import TightDataGridPro from 'components/common/TightDataGridPro';
import { ROWS_PER_PAGE_OPTIONS } from 'consts/common/dataGrid';
import { DEFAULT_SIZE } from 'consts/common/pageAndSize';
import gridColumns from './gridColDef';
import { useRecoilValue } from 'recoil';
import { warehouseOptionsAtom } from '../../../../../../store/outbound/warehouse.recoil';
import usePopup from '../../../../../../hooks/usePopup';
import { createWaybillImage, generateShippingExcelDownload } from '../../services/bulkPackings';
import LoadingButton from '@mui/lab/LoadingButton';

type RequestQuery = {
  warehouseId?: number;
  pickingGroupSerialNumber?: string;
};

type Props = {
  data: any;
  error: any;
  queryParams: SearchQuery;
  setQueryParams: (params: (params: SearchQuery) => SearchQuery) => void;
  bulkPackingId: number;
  request: RequestQuery;
};

type SearchQuery = {
  page?: number;
  size?: number;
};

const ShippingDataGrid = ({
  data,
  error,
  queryParams,
  setQueryParams,
  bulkPackingId,
  request,
}: Props) => {
  const { showErrorDialog, showSnackbar } = usePopup();
  const warehouseOption = useRecoilValue(warehouseOptionsAtom);
  const [isDownload, setIsDownload] = useState(false);
  const strings = warehouseOption.find(v => v.value === request.warehouseId)?.displayName;

  const infoList = [
    { name: '창고', value: strings ?? '' },
    { name: '집품구분', value: '대량집품' },
    { name: '집품그룹 ID', value: request.pickingGroupSerialNumber },
  ];

  const requestShippingExcelDownload = async () => {
    const response = await generateShippingExcelDownload(bulkPackingId);
    if (response?.status === 200) {
      showSnackbar({ message: '엑셀 다운로드가 완료되었습니다.', severity: 'success' });
      setIsDownload(false);
    } else {
      setIsDownload(false);
      showErrorDialog({
        title: '엑셀 다운로드 실패',
        errorMessage: response?.errorMessage,
        buttons: [{ text: '확인' }],
      });
    }
  };

  const handleShippingExcelDownload = () => {
    setIsDownload(true);
    requestShippingExcelDownload();
  };

  const handleCreateWaybillImage = async () => {
    const response = await createWaybillImage(bulkPackingId);
    if (response?.status === 200) {
      showSnackbar({ message: '송장이 재생성 중입니다.', severity: 'success' });
    } else {
      showErrorDialog({
        title: '송장 재생성 요청 실패',
        errorMessage: response?.data.errorMessage,
        buttons: [{ text: '확인' }],
      });
    }
  };

  return (
    <>
      <Box sx={styles.gridBorderTop}>
        <Typography variant="h6" sx={{ color: 'text.primary' }}>
          출고 목록
        </Typography>
        <Box>
          <LoadingButton
            sx={styles.gridBorderTopButton}
            variant="outlined"
            suppressHydrationWarning
            loading={isDownload}
            onClick={handleCreateWaybillImage}
          >
            {`송장이미지 재생성`}
          </LoadingButton>
          <LoadingButton
            sx={styles.gridBorderTopButton}
            variant="outlined"
            suppressHydrationWarning
            loading={isDownload}
            onClick={handleShippingExcelDownload}
          >
            {`엑셀다운로드`}
          </LoadingButton>
        </Box>
      </Box>
      <InfoListSection infoList={infoList} hideValue={!data || error} />
      <Box sx={{ height: '460px' }}>
        <TightDataGridPro
          rows={data?.content ? makeRowIndex(data) : []}
          columns={gridColumns()}
          paginationMode="server"
          rowCount={data?.totalElements ?? 0}
          onPageChange={page => setQueryParams(params => ({ ...params, page }))}
          pageSize={queryParams.size ?? DEFAULT_SIZE}
          onPageSizeChange={size => setQueryParams(params => ({ ...params, size }))}
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          loading={!data && !error}
        />
      </Box>
    </>
  );
};

export default ShippingDataGrid;

const styles = {
  gridBorderTop: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    spacing: 1,
  },
  gridBorderTopButton: {
    marginRight: 1,
  },
};
