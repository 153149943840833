import React, { useRef, useState } from 'react';
import _ from 'lodash';
import { useRecoilValue } from 'recoil';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Page from '../../../../components/common/Layout/Page';
import Filter, { Form } from '../../../../components/common/Filter';
import refCodeOptionsAtom from '../../../../store/outbound/refCode.recoil';
import { warehouseAtom, warehouseOptionsAtom } from '../../../../store/outbound/warehouse.recoil';
import { ROWS_PER_PAGE_OPTIONS } from '../../../../consts/common/dataGrid';
import { DEFAULT_PAGE, DEFAULT_SIZE } from '../../../../consts/common/pageAndSize';
import fetcher from '../../../../libs/common/fetcher';
import pageTitle from '../../../../styles/pageTitle';
import { listBoardTheme } from '../../../../styles/customedMuiTheme';
import { Options } from '../../../../types/form';
import useConditionalSWR from '../../components/useConditionalSwr';
import makeGridColDef from './gridColDef';
import { RANGE } from '../../../../components/common/Filter/DateRangePickerWithSelect/RangeSelect';
import TightDataGridSum from '../../../../components/common/TightDataGridSum';
import { useSupplier } from 'queries/useSupplier';

const dateRangeTypeOptions: Options = [
  { displayName: '입고확정일', field: 'receivingCompletedAt' },
];

export const defaultOption = {
  displayName: '전체',
  value: 'ALL',
};

const gridTemplateColumns = [
  '75px',
  'minmax(45px, 0.5fr)',
  'minmax(45px, 0.5fr)',
  '50px',
  'minmax(55px, 2fr)',
  'minmax(55px, 1.4fr)',
  '70px',
  'minmax(55px, 0.6fr)',
  'minmax(55px, 0.6fr)',
  '50px',
  'minmax(55px, 1.0fr)',
  'minmax(55px, 1.0fr)',
].reduce((prevValue, labelWidth) => prevValue + labelWidth + ' ', '');

export type SearchQuery = {
  page?: number;
  size?: number;
  warehouseId?: number;
  dateType?: string;
  receivingCompletedAtFrom?: string;
  receivingCompletedAtTo?: string;
  receivingType?: string;
};

type InboundRegisteredReceivingContent = {
  warehouseId: number;
  supplierId: string;
  supplierName: string;
  totalQty: number;
  totalPrice: number;
  receivingType: string;
};

type InboundRegisteredReceiving = {
  content: InboundRegisteredReceivingContent[];
  totalElements: number;
  size: number;
  number: number;
};

const SpecificationOfCompletedReceivingStatus = () => {
  const globalWarehouse = useRecoilValue(warehouseAtom);
  const [queryParams, setQueryParams] = useState<SearchQuery>({
    page: DEFAULT_PAGE,
    size: DEFAULT_SIZE,
    warehouseId: Number(globalWarehouse),
  });
  const initButtonRef = useRef<{ handleClick: () => void }>();
  const [hasSearched, setHasSearched] = useState(false);
  const warehouseOption = useRecoilValue(warehouseOptionsAtom);
  const { data: supplierOption = [] } = useSupplier();
  const rangeSelectRef = useRef<{ selectRange: (value: string) => void }>();

  const refCodeOptions = useRecoilValue(refCodeOptionsAtom);
  const receivingTypeOptions = refCodeOptions?.receivingType || [];

  const { data, isValidating, mutate } = useConditionalSWR<InboundRegisteredReceiving>(
    [`/receivings/specification/status/search`, { ...queryParams, sort: 'id,desc' }],
    fetcher,
    hasSearched
  );

  const makeRowIndex = (data: InboundRegisteredReceiving) => {
    const { content } = data;

    const summary = {
      id: 'total',
      rowIndex: 'total',
      itemId: '합계',
      barcode: '',
      itemName: '',
      totalQty: content.reduce(
        (acc, cur) =>
          acc +
          (cur.receivingType === 'RETURN' || cur.receivingType === 'DEFECT_RETURN'
            ? -cur.totalQty
            : cur.totalQty),
        0
      ),
      totalPrice: content.reduce(
        (acc, cur) =>
          acc +
          (cur.receivingType === 'RETURN' || cur.receivingType === 'DEFECT_RETURN'
            ? -cur.totalPrice
            : cur.totalPrice),
        0
      ),
    };

    return [
      ...content.map((row, index) => ({
        ...row,
        id: index,
        rowIndex: index,
      })),
      summary,
    ];
  };

  const handleInitClick = async () => {
    rangeSelectRef.current?.selectRange(RANGE.today);

    const date = new Date();
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');

    const today = `${year}-${month}-${day}`;

    setQueryParams({
      page: DEFAULT_PAGE,
      size: DEFAULT_SIZE,
      warehouseId: Number(globalWarehouse),
      receivingCompletedAtFrom: today,
      receivingCompletedAtTo: today,
    });
    await pageMutate();
  };

  const handleSearchClick = async (form: Form) => {
    const updatedForm = _.omitBy(form, o => o === defaultOption.value);

    setQueryParams(({ size }) => ({
      ...updatedForm,
      size,
      page: DEFAULT_PAGE,
    }));
    !hasSearched && setHasSearched(true);
    await pageMutate();
  };

  const pageMutate = async () => {
    await mutate();
    // await countMutate();
  };

  return (
    <Page>
      <Typography variant="h2" sx={pageTitle}>
        거래명세서 현황 조회
      </Typography>
      <Filter gridTemplateColumns={gridTemplateColumns}>
        <Filter.Select
          label="창고"
          field="warehouseId"
          options={[...warehouseOption]}
          labelGridColumn="1/2"
          selectGridColumn="2/4"
          defaultValue={globalWarehouse}
        />
        <Filter.Select
          label="입고구분"
          field="receivingType"
          options={[defaultOption, ...receivingTypeOptions]}
          labelGridColumn="4/5"
          selectGridColumn="5/6"
          defaultValue={queryParams.receivingType ?? 'ALL'}
        />
        <Filter.Select
          label="공급업체"
          field="supplierId"
          options={[defaultOption, ...supplierOption]}
          labelGridColumn="6/8"
          selectGridColumn="8/10"
        />
        <Filter.DateRangePickerWithSelect
          label="기간"
          rangeTypeOptions={dateRangeTypeOptions}
          gridRow={2}
          labelGridColumn="1/2"
          selectGridColumn="2/4"
          rangeDefaultValue={RANGE.today}
          dateRangePickerGridColumn="4/6"
          rangeAmountSelectGridColumn="6/7"
        />

        <Divider sx={styles.divider} />
        <Filter.DefaultButtonGroup
          initRef={initButtonRef}
          gridColumn="1/-1"
          gridRow={5}
          onInitClick={handleInitClick}
          onLookupClick={handleSearchClick}
          sx={{ display: 'flex', justifyContent: 'center' }}
        />
      </Filter>
      <Stack spacing={1} sx={listBoardTheme.container}>
        <Box sx={listBoardTheme.header}>
          <Typography variant="h6" sx={{ color: 'text.primary' }}>
            거래명세서 목록
          </Typography>
        </Box>
        <TightDataGridSum
          rows={hasSearched && data?.content ? makeRowIndex(data) : []}
          columns={makeGridColDef(queryParams)}
          paginationMode="server"
          rowCount={hasSearched ? data?.totalElements ?? 0 : 0}
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          onPageChange={page => setQueryParams(params => ({ ...params, page }))}
          pageSize={queryParams.size ?? DEFAULT_SIZE}
          onPageSizeChange={size => setQueryParams(params => ({ ...params, size }))}
          loading={isValidating}
          hasSearched={hasSearched}
        />
      </Stack>
    </Page>
  );
};

export default SpecificationOfCompletedReceivingStatus;

const styles = {
  divider: {
    gridRow: 4,
    gridColumn: '1/-1',
    pb: 1,
    mb: 1,
  },
  gridBorderTopButton: {
    marginRight: 1,
  },
};
