import { ChangeEvent, KeyboardEvent, useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { inputAutoFocusAtom } from 'store';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import KeyboardIcon from '@mui/icons-material/Keyboard';
import InputAdornment from '@mui/material/InputAdornment';
import { COLORS } from '../../../../consts/common/colors';
import NumberKeyboard from '../../components/NumberKeyboard';

type Props = {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onKeyUp?: (e: KeyboardEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void;
  value: string;
  'data-testid'?: string;
};

const VisibleInput = (props: Props) => {
  const { onChange, onKeyUp, onKeyDown, value, 'data-testid': dataTestId } = props;
  const inputRef = useRef<HTMLInputElement>(null);
  const autoFocus = useRecoilValue(inputAutoFocusAtom);
  const [showKeyboard, setShowKeyboard] = useState(false);

  useEffect(() => {
    if (autoFocus) {
      inputRef.current?.focus();
    }
  }, [autoFocus]);

  const handleNumberKeyboard = (input: string) => {
    if (input === 'enter') {
      if (onKeyUp) {
        onKeyUp({
          key: 'Enter',
          currentTarget: inputRef.current,
        } as unknown as KeyboardEvent<HTMLInputElement>);
      }
      setShowKeyboard(false);
      return;
    }

    const newValue = input === 'del' ? value.slice(0, -1) : value + input;
    onChange({ target: { value: newValue } } as ChangeEvent<HTMLInputElement>);
  };

  return (
    <div
      style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto', marginBottom: '10px' }}
    >
      <span style={{ marginRight: '10px' }}>아이템 바코드</span>
      <TextField
        onChange={onChange}
        onKeyUp={onKeyUp}
        onKeyDown={onKeyDown}
        autoFocus
        onBlur={e => {
          autoFocus && e.target.focus();
        }}
        ref={inputRef}
        value={value}
        data-testid={dataTestId}
        sx={{
          height: '30px',
          width: '220px',
          display: 'flex',
        }}
        InputProps={{
          sx: { height: 32 },
          endAdornment: (
            <InputAdornment position="end" onClick={() => setShowKeyboard(show => !show)}>
              <KeyboardIcon fontSize={'large'} sx={{ color: COLORS.black }} />
            </InputAdornment>
          ),
        }}
      />
      {showKeyboard && (
        <Box sx={{ position: 'relative', right: -74, bottom: 20 }}>
          <NumberKeyboard handleButtonClick={handleNumberKeyboard} />
        </Box>
      )}
      <Button
        variant={'outlined'}
        sx={{ backgroundColor: 'white', marginLeft: '10px', height: '32px' }}
        onClick={() => {
          if (onKeyUp) {
            onKeyUp({
              key: 'Enter',
              currentTarget: inputRef.current,
            } as unknown as React.KeyboardEvent<HTMLInputElement>);
          }
        }}
      >
        확인
      </Button>
    </div>
  );
};

export default VisibleInput;
