import { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { PACKING } from 'consts/outbound/messages';
import { MIN_WEIGHT, SCALE_MIN_AVAILABLE_WEIGHT } from 'consts/outbound/weights';
import { BARCODE_SCAN_ACTION } from 'consts/outbound/barcodeScanAction';
import getWeightByVolume from 'libs/outbound/getWeightByVolume';
import {
  boxAtom,
  boxCodeAtom,
  packingAtom,
  stepAtom,
  stopModalOpenAtom,
  weightByScaleAtom,
} from 'store/outbound/packing.recoil';
import { afterProgressTitle, beforeProgressTitle, inProgressTitle } from 'styles/timelineItemTitle';
import usePopup from 'hooks/usePopup';
import Table, { TableCell, TableRow } from '../../../components/Table';
import TimelineItem from '../../components/TimelineItem';
import { BOX_STEP } from './BoxLine';
import WeightInput from '../../components/WeightInput';

export const WEIGHT_STEP = 2;
const WeightLine = () => {
  const [weightByScale, setWeightByScale] = useRecoilState(weightByScaleAtom);
  const setBoxCode = useSetRecoilState(boxCodeAtom);
  const setStep = useSetRecoilState(stepAtom);
  const setStopModalOpen = useSetRecoilState(stopModalOpenAtom);
  const box = useRecoilValue(boxAtom);
  const packing = useRecoilValue(packingAtom);
  const [input, setInput] = useState('');
  const { showSnackbar } = usePopup();
  const volumetricDivisor = packing?.volumetricDivisor ?? 5000;
  const weightByVolume = box ? getWeightByVolume(box, volumetricDivisor) : undefined;

  useEffect(() => {
    setInput('');
  }, [weightByScale]);

  const gotoNextStep = () => {
    setWeightByScale(input);
  };

  const goBackToBoxLine = () => {
    setBoxCode('');
    setStep(BOX_STEP);
    setInput('');
  };

  const stopPacking = () => {
    setStopModalOpen(true);
    setInput('');
  };

  const handleLessThanMinWeight = () => {
    showSnackbar({ message: PACKING.lessThanMinWeight, severity: 'warning' });
  };

  const handleMoreThanScaleMinAvailableWeight = () => {
    showSnackbar({ message: PACKING.shouldUseScale, severity: 'warning' });
  };

  const handleInvalidInput = () => {
    showSnackbar({ message: PACKING.invalidWeight, severity: 'warning' });
  };

  const handleWeightInput = () => {
    if (Number(input) <= MIN_WEIGHT) {
      handleLessThanMinWeight();
    } else if (Number(input) < SCALE_MIN_AVAILABLE_WEIGHT) {
      gotoNextStep();
    } else {
      handleMoreThanScaleMinAvailableWeight();
    }
  };

  const handleSubmit = () => {
    const isNumber = !isNaN(Number(input));

    if (isNumber) {
      handleWeightInput();
    } else if (input.toLowerCase() === BARCODE_SCAN_ACTION.changeBox) {
      goBackToBoxLine();
    } else if (input.toLowerCase() === BARCODE_SCAN_ACTION.stopPacking) {
      stopPacking();
    } else {
      handleInvalidInput();
    }
  };

  return (
    <TimelineItem
      isLast
      step={WEIGHT_STEP}
      beforeProgressComponent={<Typography sx={beforeProgressTitle}>{PACKING.weight}</Typography>}
      inProgressComponent={
        <>
          <Typography sx={inProgressTitle}>{PACKING.weight}</Typography>
          {
            <Table>
              <TableRow>
                <TableCell
                  sx={styles.tableCell}
                  label="포장실제중량무게(g)"
                  value={
                    <WeightInput
                      value={weightByScale ? weightByScale : input}
                      onChange={e => setInput(e.target.value)}
                      onSubmit={handleSubmit}
                      inputSxProps={styles.tableCell}
                    />
                  }
                  valueProps={valueCellStyle}
                />
                <TableCell
                  sx={styles.tableCell}
                  label="포장실제부피무게(g)"
                  value={Number(weightByVolume).toLocaleString()}
                />
              </TableRow>
            </Table>
          }
        </>
      }
      afterProgressComponent={
        <>
          <Typography sx={afterProgressTitle}>{PACKING.weight}</Typography>
          {weightByScale && (
            <Table>
              <TableRow>
                <TableCell
                  sx={styles.tableCell}
                  label="포장실제중량무게(g)"
                  value={Number(weightByScale).toLocaleString()}
                />
                <TableCell
                  sx={styles.tableCell}
                  label="포장실제부피무게(g)"
                  value={Number(weightByVolume).toLocaleString()}
                />
              </TableRow>
            </Table>
          )}
        </>
      }
    />
  );
};

export default WeightLine;

const styles = {
  tableCell: { fontSize: '16px' },
};

const valueCellStyle = {
  sx: { display: 'flex', alignItems: 'center', flex: 1, gap: 0.5, px: 1 },
};
