import { useEffect, useState } from 'react';
import { useSetRecoilState, useRecoilState } from 'recoil';
import { datadogRum } from '@datadog/browser-rum';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { isPrinterConnectedAtom } from 'store/outbound/diagnosis.recoil';
import { inputAutoFocusAtom } from 'store';
import { printerAtom } from 'store/common/printer.recoil';
import { COLORS } from 'consts/common/colors';
import diagnosisGuides from 'pages/gms/outbound/packing/diagnosisGuides';
import checkPrinterConnection from 'libs/outbound/checkPrinterConnection';
import DiagnosisDialogContent from './DiagnosisDialogContent';
import usePopup from 'hooks/usePopup';

type PrinterDialogProps = {
  isOpenPrinterDialog: boolean;
  setIsOpenPrinterDialog: (isOpen: boolean) => void;
};

const DiagnosisDialog = (props: PrinterDialogProps) => {
  const { isOpenPrinterDialog, setIsOpenPrinterDialog } = props;
  const [printer, setPrinter] = useRecoilState(printerAtom);
  const [isPrinterConnected, setIsPrinterConnected] = useRecoilState(isPrinterConnectedAtom);
  const [hasPrinterTriedToConnect, setHasPrinterTriedToConnect] = useState(false);
  const { showSnackbar } = usePopup();
  const setInputAutoFocus = useSetRecoilState(inputAutoFocusAtom);
  useEffect(() => {
    setInputAutoFocus(!isOpenPrinterDialog);
  }, [isOpenPrinterDialog]);

  useEffect(() => {
    if (!isPrinterConnected) {
      return;
    }
    if (isOpenPrinterDialog && isPrinterConnected && !!printer) {
      startDiagnosis();
      setIsOpenPrinterDialog(false);
      return;
    }
  }, [isPrinterConnected, isOpenPrinterDialog]);
  const startDiagnosis = async () => {
    if (!printer) {
      return;
    }

    const handlePrintCompleted = () => {
      if (isPrinterConnected) {
        setIsOpenPrinterDialog(false);
        showSnackbar({ message: '연결상태 진단이 완료되었습니다.', severity: 'info' });
        return;
      }
    };

    const handlePrintError = (error: Error) => {
      setPrinter(undefined);
      setIsPrinterConnected(false);
      setIsOpenPrinterDialog(true);
      showSnackbar({ message: '프린터 인쇄 실패', severity: 'error' });
      datadogRum.addError(`프린터 인쇄 실패: ${error}`);
    };

    const diagnosisSuccessMessage = `^XA^FO50,50^ADN,36,20^FD${'Printer works well!!'}^FS^XZ`;
    await printer.send(diagnosisSuccessMessage, handlePrintCompleted, handlePrintError);
  };

  const getPrinterConnectionGuide = () => {
    if (isPrinterConnected) {
      return null;
    }
    const hasConnectionTryFailed = hasPrinterTriedToConnect && !isPrinterConnected;
    if (hasConnectionTryFailed) {
      return (
        <DiagnosisDialogContent
          guides={diagnosisGuides.retryPrinterConnect}
          onClick={handleReloadPage}
        />
      );
    }

    return (
      <DiagnosisDialogContent
        guides={diagnosisGuides.printerConnect}
        onClick={handleCheckPrinterConnection}
      />
    );
  };

  const handleReloadPage = () => {
    window.location.reload();
  };

  // 프린터연결 상태 확인
  const handleCheckPrinterConnection = () => {
    checkPrinterConnection()
      .then(isConnected => {
        isConnected && !!printer
          ? setIsPrinterConnected(isConnected)
          : setHasPrinterTriedToConnect(true);
      })
      .catch(() => {
        setHasPrinterTriedToConnect(true);
        datadogRum.addError(`프린터 연결 에러`);
      });
  };

  const shouldDiagnosisDialogOpen = !isPrinterConnected && isOpenPrinterDialog;

  return (
    <Dialog
      open={shouldDiagnosisDialogOpen}
      PaperProps={{ sx: styles.paperProps }}
      BackdropProps={{ sx: styles.backdropProps }}
    >
      <DialogTitle sx={styles.dialogTitle}>프린터 연결상태 확인</DialogTitle>
      <DialogContent sx={styles.dialogContent}>{getPrinterConnectionGuide()}</DialogContent>
    </Dialog>
  );
};

export default DiagnosisDialog;

const styles = {
  paperProps: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '520px',
    height: '460px',
  },
  backdropProps: {
    backgroundColor: COLORS.faintBlack,
  },
  dialogTitle: {
    paddingTop: '32px',
    textAlign: 'center',
    fontSize: '24px',
    fontWeight: '700',
    fontStyle: 'normal',
    lineHeight: '32px',
    letterSpacing: '-0.3px',
  },
  dialogSteps: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  otherStep: {
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '-0.4px',
  },
  currentStep: {
    color: COLORS.ktown4u,
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '-0.4px',
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-even',
    alignItems: 'center',
    width: '92%',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '-0.4px',
  },
};
