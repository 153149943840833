import useSWR from 'swr';
import { useEffect } from 'react';

function useConditionalSWR<Data>(
  url: [string] | [string, Record<string, string | number | boolean | null>],
  fetcher: (path: string, params?: Record<string, string>) => Promise<Data>,
  hasSearch: boolean
) {
  const finalUrl = hasSearch ? url : null;

  const { data, isValidating, mutate, error } = useSWR<Data>(finalUrl, fetcher);

  useEffect(() => {
    if (hasSearch) {
      mutate();
    }
  }, [hasSearch, mutate]);

  return { data, isValidating, mutate, error };
}

export default useConditionalSWR;
