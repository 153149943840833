import { useEffect, useRef } from 'react';

type Props = {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  value: string;
  'data-testid'?: string;
  isAutoFocus?: boolean;
};

const InvisibleInput2 = (props: Props) => {
  const { onChange, onKeyUp, onKeyDown, value, 'data-testid': dataTestId, isAutoFocus } = props;
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isAutoFocus) {
      inputRef.current?.focus();
    }
  }, [isAutoFocus]);

  return (
    <input
      onChange={onChange}
      onKeyUp={onKeyUp}
      onKeyDown={onKeyDown}
      autoFocus={isAutoFocus}
      onBlur={() => {
        if (isAutoFocus) {
          setTimeout(() => {
            inputRef.current?.focus();
          }, 0);
        }
      }}
      ref={inputRef}
      value={value}
      data-testid={dataTestId}
      style={{
        position: 'absolute',
        border: 'none',
        outline: 'none',
        backgroundColor: 'transparent',
        color: 'transparent',
        caretColor: 'transparent',
      }}
    />
  );
};

export default InvisibleInput2;
