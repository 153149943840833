import React, { ChangeEvent, useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { shouldMountAppBarAtom, shouldMountDrawerAtom } from 'store';
import { COLORS } from 'consts/common/colors';
import { BULK_PACKING_ID, FAILED_CARRIERS, PICKING_GROUP_ID } from '../index.page';
import { axiosV2 } from '../../../../../libs/common/fetcher';
import TightDataGridPro from 'components/common/TightDataGridPro';
import { GridColDef } from '@mui/x-data-grid-pro';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import LoadingButton from '@mui/lab/LoadingButton';
import { AxiosResponse } from 'axios';
import carrierChangeAtom, { carriersAtom } from '../../../../../store/outbound/carrier.recoil';
import usePopup from '../../../../../hooks/usePopup';

const CarrierChangeForFailed = () => {
  const setShouldMountAppBar = useSetRecoilState(shouldMountAppBarAtom);
  const setShouldMountDrawer = useSetRecoilState(shouldMountDrawerAtom);
  const [pickingGroupId, setPickingGroupId] = useState('');
  const [bulkPackingId, setBulkPackingId] = useState('');
  const carrierChange = useRecoilValue(carrierChangeAtom);
  const [carriers, setCarriers] = useState<{ id: number; name: string }[]>([]);
  const { showAlert } = usePopup();
  const rows = carriers.map((carrier, i) => ({
    id: carrier.id,
    no: i + 1,
    originalCarrier: carrier.name,
    originalFeePerUnit: undefined,
    carrierToChange: '',
    feePerUnitToChange: '',
  }));
  useEffect(() => {
    setShouldMountAppBar(false);
    setShouldMountDrawer(false);

    return () => {
      setShouldMountAppBar(true);
      setShouldMountDrawer(true);
    };
  }, []);

  useEffect(() => {
    const pickingGroupId = localStorage.getItem(PICKING_GROUP_ID) ?? '';
    const bulkPackingId = localStorage.getItem(BULK_PACKING_ID) ?? '';
    const carriers = JSON.parse(localStorage.getItem(FAILED_CARRIERS) ?? '[]');
    setPickingGroupId(pickingGroupId);
    setBulkPackingId(bulkPackingId);
    setCarriers(carriers);
  }, []);

  const [isLoading, setIsLoading] = useState(false);
  const handleSave = async () => {
    setIsLoading(true);
    try {
      for (const originalCarrier of Object.keys(carrierChange)) {
        await axiosV2.patch(`/bulk-packings/${bulkPackingId}/packings/carriers`, {
          fromCarrierId: originalCarrier,
          toCarrierId: carrierChange[originalCarrier],
        });
      }
      showAlert({ message: '배송방식이 수정 완료되었습니다.', onClick: () => window.close() });
    } catch (e) {
      console.error((e as AxiosResponse).data);
    }
    setIsLoading(false);
  };

  return (
    <Stack spacing={3} sx={{ minHeight: '100vh', padding: '32px', backgroundColor: 'white' }}>
      <Stack spacing={1.5}>
        <Typography variant="h5" sx={{ fontSize: '28px', fontWeight: 700 }}>
          운송사 변경
        </Typography>
        <Box sx={{ display: 'flex', gap: 4, padding: '12px 24px', backgroundColor: COLORS.bgDeep }}>
          <Typography>{`집품그룹ID : ${pickingGroupId}`}</Typography>
        </Box>
      </Stack>
      <Stack spacing={1.5} sx={{ height: '100%' }}>
        <Typography variant="h6" sx={{ fontSize: '20px', fontWeight: 700 }}>
          운송사 목록
        </Typography>
        <TightDataGridPro columns={columns} rows={rows ?? []} autoHeight hideFooter />
        <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
          <Button size="small" variant="outlined" onClick={() => window.close()}>
            취소
          </Button>
          <LoadingButton size="small" variant="contained" onClick={handleSave} loading={isLoading}>
            저장
          </LoadingButton>
        </Box>
      </Stack>
    </Stack>
  );
};

export default CarrierChangeForFailed;

const columns: GridColDef[] = [
  {
    field: 'no',
    headerName: 'NO',
    flex: 1,
  },
  {
    field: 'originalCarrier',
    headerName: '운송사',
    flex: 3,
  },
  {
    field: 'originalFeePerUnit',
    headerName: '개당 배송비(원)',
    flex: 2,
  },
  {
    field: 'carrierToChange',
    headerName: '변경 운송사',
    flex: 3,
    renderCell: ({ id }) => <CarrierSelect id={id as string} />,
  },
  { field: 'feePerUnitToChange', headerName: '변경 개당 배송비(원)', flex: 2 },
];

const CarrierSelect = ({ id }: { id: string }) => {
  const [carrierChange, setCarrierChange] = useRecoilState(carrierChangeAtom);
  const carriers = useRecoilValue(carriersAtom);
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const carrierToChange = e.target.value;
    setCarrierChange(carrierChange => ({ ...carrierChange, [id]: carrierToChange }));
  };

  return (
    <TextField
      select
      fullWidth
      value={carrierChange[id] ?? ''}
      onChange={handleChange}
      sx={{ margin: 0 }}
      inputProps={{
        sx: {
          paddingY: 0.5,
        },
      }}
    >
      {carriers?.map(carrier => (
        <MenuItem key={carrier.id} value={carrier.id}>
          {carrier.name}
        </MenuItem>
      ))}
    </TextField>
  );
};
