import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import Box from '@mui/system/Box';
import AutoContainImage from '../../../../components/common/Image/AutoContainImage';
import { getFinalUrl } from '../../../../consts/outbound/imageUrl';

const gridColumns = () => {
  const columns: GridColDef[] = [
    {
      field: 'rowIndex',
      headerName: 'NO',
      minWidth: 10,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'itemCode',
      headerName: '아이템코드',
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'imageUrl',
      headerName: '이미지',
      minWidth: 50,
      maxWidth: 50,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: GridRenderCellParams) =>
        params.value && (
          <Box
            sx={{ display: 'flex', alignItems: 'center', padding: 0.5, justifyContent: 'center' }}
          >
            <AutoContainImage src={getFinalUrl(params.value)} imageContainerSize={30} />
          </Box>
        ),
    },
    {
      field: 'itemName',
      headerName: '아이템명',
      minWidth: 100,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'barcode',
      headerName: '바코드',
      minWidth: 120,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'offlineBarcode',
      headerName: '오프라인바코드',
      minWidth: 120,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'weight',
      headerName: '실제무게(g)',
      minWidth: 20,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'width',
      headerName: '가로(mm)',
      minWidth: 20,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'length',
      headerName: '세로(mm)',
      minWidth: 20,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'height',
      headerName: '높이(mm)',
      minWidth: 20,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'updatedUser',
      headerName: '작업자',
      minWidth: 20,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'updatedAt',
      headerName: '작업일시',
      minWidth: 180,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    // {
    //   field: 'pendingQuantity',
    //   headerName: '창고진행수량',
    //   minWidth: 20,
    //   headerAlign: 'center',
    //   align: 'center',
    //   editable: false,
    // },
  ];
  return columns;
};

export default gridColumns;
