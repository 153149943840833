import React, { useRef, useState } from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import fetcher from 'libs/common/fetcher';
import Page from 'components/common/Layout/Page';
import type { Form } from 'components/common/Filter';
import Filter from 'components/common/Filter';
import TightDataGridPro from 'components/common/TightDataGridPro';
import pageTitle from 'styles/pageTitle';
import { listBoardTheme } from 'styles/customedMuiTheme';
import { ROWS_PER_PAGE_OPTIONS } from 'consts/common/dataGrid';
import Button from '@mui/material/Button';
import { carrierOptionsAtom } from '../../../../store/outbound/carrier.recoil';
import TopBoard from '../../components/TopBoard';
import usePopup from 'hooks/usePopup';
import FormModal from '../../../../components/common/FormModal';
import PickingGroupForm from './components/PickingGroupForm';
import { useRecoilValue } from 'recoil';
import { warehouseAtom, warehouseOptionsAtom } from '../../../../store/outbound/warehouse.recoil';
import _ from 'lodash';
import { Options } from 'types/form';
import gridColumns from './gridColDef';
import useConditionalSWR from '../../components/useConditionalSwr';
import refCodeOptionsAtom from '../../../../store/outbound/refCode.recoil';

const dateRangeTypeOptions: Options = [
  {
    displayName: '출고지시일', // '출고생성일',
    field: 'shippingOrderCreatedAt',
  },
  {
    displayName: '출고기준일',
    field: 'shippingBasedAt',
  },
];

export type SearchQuery = {
  warehouseId: string | number;
  page?: number;
  size?: number;
  shippingOrderType: string;
};

type SummarizedPickingGroup = {
  keyId: number;
  sameShippingCount: number;
  sku: number;
  quantity: number;
  tubeQuantity: number;
  expectedWeight: number;
  warehouseFullCode: string;
};

export type PickingGroup = {
  content: SummarizedPickingGroup[];
  totalElements: number;
  size: number;
  number: number;
};

const searchWordOptions = [
  {
    displayName: '아이템코드',
    field: 'itemCodes',
  },
  {
    displayName: '바코드',
    field: 'itemBarcodes',
  },
  {
    displayName: '배송ID',
    field: 'deliverySeqs',
  },
  {
    displayName: '출고ID',
    field: 'shippingSerialNumbers',
  },
];

const defaultOption = { displayName: '전체', value: 'ALL' };
const gridTemplateColumns = [
  '70px',
  'minmax(30px, 0.6fr)',
  'minmax(30px, 0.6fr)',
  'minmax(30px, 0.4fr)',
  'minmax(30px, 0.4fr)',
  '70px',
  'minmax(30px, 2fr)',
  '50px',
  'minmax(30px, 1.2fr)',
  'minmax(30px, 1.2fr)',
  '40px',
  'minmax(30px, 3fr)',
].reduce((prevValue, labelWidth) => prevValue + labelWidth + ' ', '');

const tubeOptions = [
  defaultOption,
  { displayName: '있음', value: true },
  { displayName: '없음', value: false },
];

const defaultSize = 100;
const defaultPage = 0;
const shippingOrderType = 'SHIPPING';
const PickingGroup = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedShippings, setSelectedShippings] = useState<number[]>();
  const { showDialog } = usePopup();
  const globalWarehouse = useRecoilValue(warehouseAtom);
  const warehouseOption = useRecoilValue(warehouseOptionsAtom);
  const [queryParams, setQueryParams] = useState<SearchQuery>({
    warehouseId: globalWarehouse,
    page: defaultPage,
    size: defaultSize,
    shippingOrderType,
  });
  const [hasSearched, setHasSearched] = useState(false);
  const carrierOptions = useRecoilValue(carrierOptionsAtom);
  const multipleCarrierSelectRef = useRef<{ initialValues: () => void }>(null);
  const multipleSaleTypeSelectRef = useRef<{ initialValues: () => void }>(null);
  const refCodeOptions = useRecoilValue(refCodeOptionsAtom);
  const salesTypeByShopOptions = refCodeOptions?.salesTypeByShop || [];

  const donationCarrierId = '16';
  const customCarrierOptions = carrierOptions.filter(
    carrierOption => carrierOption.value != donationCarrierId
  );

  const { data: shippingCounts } = useConditionalSWR(
    [`/picking-groups/shippings/counts`, { warehouseId: globalWarehouse, shippingOrderType }],
    fetcher,
    hasSearched
  );

  const { data, mutate, isValidating } = useConditionalSWR<PickingGroup>(
    [`/picking-groups/same-shippings`, { ...queryParams, sort: 'id,desc' }],
    fetcher,
    hasSearched
  );

  const handleCreatePickingGroups = () => {
    if (!hasSearched) {
      showDialogForNotSearchedYet();
      return;
    }

    showDialog({
      message: '출고건 전체 대상으로 집품그룹을 생성하시겠습니까?',
      buttons: [
        {
          text: '취소',
        },
        {
          text: '확인',
          marked: true,
          onClick: async () => {
            setSelectedShippings([]);
            handleRegistration();
          },
        },
      ],
    });
  };

  const handleCreatePickingGroupsWithSelectedShipping = () => {
    if (!hasSearched) {
      showDialogForNotSearchedYet();
      return;
    }

    const length = selectedShippings?.length;
    if (length === 0 || length === undefined) {
      showDialog({
        message: '집품그룹 생성을 진행할 동일포장건수를 선택해 주세요.',
        buttons: [
          {
            text: '확인',
          },
        ],
      });
    } else {
      showDialog({
        message: '출고건 선택 대상으로 집품그룹을 생성하시겠습니까?',
        buttons: [
          {
            text: '취소',
          },
          {
            text: '확인',
            marked: true,
            onClick: async () => {
              handleRegistration();
            },
          },
        ],
      });
    }
  };

  const showDialogForNotSearchedYet = () => {
    showDialog({
      message: `조회 조건 입력 후 '조회' 버튼을 클릭하신 후, 집품그룹 생성을 진행해 주세요.`,
      buttons: [
        {
          text: '확인',
        },
      ],
    });
  };

  const handleRegistration = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleInitClick = async () => {
    multipleCarrierSelectRef.current?.initialValues();
    multipleSaleTypeSelectRef.current?.initialValues();

    setQueryParams({
      page: defaultPage,
      warehouseId: globalWarehouse,
      size: defaultSize,
      shippingOrderType,
    });
    await mutate();
  };

  const handleSearchClick = async (form: Form) => {
    const updatedForm = _.omitBy(form, o => o === defaultOption.value);
    delete updatedForm[`dateType`];
    const warehouseId: string | number = updatedForm.warehouseId ?? globalWarehouse;

    setQueryParams(({ size }) => ({
      ...updatedForm,
      warehouseId,
      size,
      page: defaultPage,
      shippingOrderType,
    }));
    !hasSearched && setHasSearched(true);
    await mutate();
  };

  function handleSelectedSameShippings(ids: number[]) {
    setSelectedShippings(ids);
  }

  const makeRowIndex = (data: PickingGroup) => {
    const { content, totalElements, size, number } = data;

    return content.map((item: SummarizedPickingGroup, index: number) => {
      return {
        ...item,
        rowIndex: totalElements - size * number - index,
        id: item.keyId,
      };
    });
  };

  const topBoard = [
    {
      title: '출고대기건',
      value: shippingCounts,
    },
  ];

  return (
    <Page>
      <Typography variant="h2" sx={pageTitle}>
        집품그룹 생성
      </Typography>
      <TopBoard data={topBoard} />
      <Filter gridTemplateColumns={gridTemplateColumns}>
        <Filter.DateRangePickerWithSelect
          label="기간"
          rangeTypeOptions={dateRangeTypeOptions}
          labelGridColumn="1/2"
          selectGridColumn="2/4"
          dateRangePickerGridColumn="4/7"
          rangeAmountSelectGridColumn="7/8"
        />
        <Filter.MultipleSelectPlaceholder
          ref={multipleCarrierSelectRef}
          label="배송방식"
          field="carrierIds"
          options={[defaultOption, ...customCarrierOptions]}
          labelGridColumn="8/9"
          selectGridColumn="9/11"
        />
        <Filter.VerticalSearchWithSelect
          label="검색어"
          labelGridRow="1/2"
          labelGridColumn="11/12"
          selectOptions={searchWordOptions}
          selectGridRow="1/2"
          selectGridColumn="12/13"
          textAreaGridRow="2/4"
          textAreaGridColumn="12/13"
          minRows={3}
          maxRows={3}
        />
        <Filter.MultipleSelectPlaceholder
          ref={multipleSaleTypeSelectRef}
          label="매출구분"
          field="salesTypeByShops"
          options={[defaultOption, ...salesTypeByShopOptions]}
          labelGridColumn="1/2"
          selectGridColumn="2/6"
          gridRow={2}
        />
        <Filter.Select
          label="지관통여부"
          field="hasTube"
          options={tubeOptions}
          labelGridColumn="6/7"
          selectGridColumn="7/8"
          gridRow={2}
        />
        <Divider
          sx={{
            gridRow: 4,
            gridColumn: '1/-1',
            pb: 1,
            mb: 1,
          }}
        />
        <Filter.DefaultButtonGroup
          gridColumn="1/-1"
          gridRow={5}
          onInitClick={handleInitClick}
          onLookupClick={handleSearchClick}
          sx={{ display: 'flex', justifyContent: 'center' }}
        />
      </Filter>
      <Stack spacing={1} sx={listBoardTheme.container}>
        <Box sx={listBoardTheme.header}>
          <Typography variant="h6" sx={{ color: 'text.primary' }}>
            집품그룹 생성대상 목록
          </Typography>
          <Box>
            <Button
              sx={listBoardTheme.headerButton}
              variant="outlined"
              suppressHydrationWarning
              onClick={handleCreatePickingGroupsWithSelectedShipping}
            >
              {`집품그룹 선택생성`}
            </Button>
            <Button
              sx={listBoardTheme.headerButton}
              variant="contained"
              suppressHydrationWarning
              onClick={handleCreatePickingGroups}
            >
              {`집품그룹 자동생성`}
            </Button>
            <Button
              sx={listBoardTheme.headerButton}
              variant="outlined"
              suppressHydrationWarning
              onClick={() => console.log(1)}
              disabled
            >
              {`엑셀다운로드`}
            </Button>
          </Box>
        </Box>
        <TightDataGridPro
          rows={hasSearched ? (data?.content ? makeRowIndex(data) : []) : []}
          columns={gridColumns(warehouseOption)}
          paginationMode="server"
          rowCount={hasSearched ? data?.totalElements ?? 0 : 0}
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          onPageChange={page => setQueryParams(params => ({ ...params, page }))}
          pageSize={queryParams.size ?? defaultSize}
          onPageSizeChange={size => setQueryParams(params => ({ ...params, size }))}
          checkboxSelection
          disableSelectionOnClick
          selectionModel={selectedShippings}
          onSelectionModelChange={ids => handleSelectedSameShippings(ids as number[])}
          loading={isValidating}
          hasSearched={hasSearched}
        />
      </Stack>
      <FormModal open={isModalOpen} title={'집품그룹 생성'} onClose={handleCloseModal}>
        <PickingGroupForm
          onClose={handleCloseModal}
          mutate={mutate}
          request={queryParams}
          keyIds={selectedShippings}
        />
      </FormModal>
    </Page>
  );
};

export default PickingGroup;
