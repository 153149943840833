import { Dispatch, SetStateAction } from 'react';
import { useRecoilValue } from 'recoil';
import Radio from '@mui/material/Radio';
import type { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import refCodeOptionsAtom from '../../../../store/outbound/refCode.recoil';
import { warehouseOptionsAtom } from '../../../../store/outbound/warehouse.recoil';
import convertWarehouseFullCode from '../../../../libs/warehouse/convertWarehouseFullCode';

const gridColumns = (
  selected: number | undefined,
  setSelected: Dispatch<SetStateAction<number | undefined>>
) => {
  const refCodeOptions = useRecoilValue(refCodeOptionsAtom);
  const bulkPackingStatusOptions = refCodeOptions?.bulkPackingStatus || [];
  const warehouseOption = useRecoilValue(warehouseOptionsAtom);
  const pickingGroupTypeOptions = refCodeOptions?.pickingGroupType || [];

  const columns: GridColDef[] = [
    {
      field: 'select',
      headerName: '',
      width: 30,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      renderCell: (params: GridRenderCellParams) => (
        <Radio
          size={'small'}
          checked={selected === params.id}
          onChange={() => {
            setSelected(Number(params.id));
          }}
        />
      ),
    },
    {
      field: 'rowIndex',
      headerName: 'NO',
      minWidth: 20,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'warehouseId',
      headerName: '창고',
      minWidth: 20,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) => {
        return convertWarehouseFullCode(value, warehouseOption);
      },
    },
    {
      field: 'bulkPackingStatus',
      headerName: '대량포장상태',
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) =>
        bulkPackingStatusOptions?.find(v => v.value === value)?.displayName,
    },
    {
      field: 'pickingGroupType',
      headerName: '집품구분',
      minWidth: 160,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) =>
        pickingGroupTypeOptions?.find(v => v.value === value)?.displayName,
    },
    {
      field: 'pickingGroupSerialNumber',
      headerName: '집품그룹ID',
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      // renderCell: params => (
      //   <Link
      //     onClick={() => openNewWindow(`/gms/outbound/packings/${params.row.id}/errors`)}
      //     sx={{
      //       overflow: 'hidden',
      //       textOverflow: 'ellipsis',
      //       cursor: 'pointer',
      //     }}
      //   >
      //     {params.value}
      //   </Link>
      // ),
    },
    {
      field: 'bulkInspectionCompletedAt',
      headerName: '검수완료일시',
      minWidth: 180,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'issuedAt',
      headerName: '송장발행완료일시',
      minWidth: 180,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'bulkPackingPrintedAt',
      headerName: '송장출력완료일시',
      minWidth: 180,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'bulkPackingCompletedAt',
      headerName: '포장완료일시',
      minWidth: 180,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'shippingCount',
      headerName: '출고건수',
      minWidth: 120,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'errorCount',
      headerName: '송장오류건수',
      minWidth: 120,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'toteBarcodes',
      headerName: '토트',
      minWidth: 120,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) => value?.join(', '),
    },
    {
      field: 'gramWeight',
      headerName: '포장실제중량무게(g)',
      minWidth: 140,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'boxCode',
      headerName: '박스명',
      minWidth: 120,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'widthInMillimeters',
      headerName: '가로(mm)',
      minWidth: 120,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'lengthInMillimeters',
      headerName: '세로(mm)',
      minWidth: 120,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'heightInMillimeters',
      headerName: '높이(mm)',
      minWidth: 120,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'updatedUser',
      headerName: '작업자',
      minWidth: 20,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'updatedAt',
      headerName: '작업일시',
      minWidth: 180,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
  ];
  return columns;
};

export default gridColumns;
