import { useRecoilValue } from 'recoil';
import type { GridColDef } from '@mui/x-data-grid-pro';
import { numberFormatter } from '../../../../../libs/common/unitConverters';
import refCodeOptionsAtom from '../../../../../store/outbound/refCode.recoil';

const makeGridColDef = () => {
  const refCodeOptions = useRecoilValue(refCodeOptionsAtom);
  const purchaseOrderStatusOptions = refCodeOptions?.gmsRegistedStatus || [];

  return [
    {
      field: 'rowIndex',
      headerName: 'NO',
      minWidth: 60,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'receivingETA',
      headerName: '입고반품예정일',
      minWidth: 60,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'purchaseOrderName',
      headerName: '발주서명',
      minWidth: 280,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'supplierName',
      headerName: '공급업체',
      minWidth: 120,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'sku',
      headerName: '발주품목수량',
      minWidth: 80,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) => numberFormatter(value),
    },
    {
      field: 'totalPrice',
      headerName: '발주금액',
      minWidth: 80,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) => numberFormatter(value),
    },
    {
      field: 'totalQuantity',
      headerName: '발주(반품)수량',
      minWidth: 80,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) => numberFormatter(value),
    },
    {
      field: 'status',
      headerName: '발주서상태',
      minWidth: 80,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) =>
        purchaseOrderStatusOptions?.find(v => v.value === value)?.displayName,
    },
  ] as GridColDef[];
};

export default makeGridColDef;
