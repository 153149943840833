import type { KeyboardEvent } from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import Table, { TableCell, TableRow } from '../../../components/Table';
import Barcode from '../../../../../components/common/Barcode';
import { BARCODE_SCAN_ACTION } from '../../../../../consts/outbound/barcodeScanAction';
import InvisibleInput from '../../components/InvisibleInput';
import Button from '@mui/material/Button';
import { INSPECTION } from '../../../../../consts/outbound/messages';
import { datadogRum } from '@datadog/browser-rum';
import { useResetRecoilState } from 'recoil';
import { inspectionAtom } from '../../../../../store/outbound/inspection.recoil';
import { stepAtom } from '../../../../../store/outbound/packing.recoil';

type Props = {
  value: string;
};

const BarcodeByProps = (props: Props) => {
  const { value } = props;
  const [scannedBarcode, setScannedBarcode] = useState('');
  const resetInspection = useResetRecoilState(inspectionAtom);
  const resetStep = useResetRecoilState(stepAtom);

  const handleScannedBarcode = async (e: KeyboardEvent) => {
    if (e.key !== 'Enter') return;

    if (scannedBarcode.toLowerCase() === BARCODE_SCAN_ACTION.startInspection) {
    }
    setScannedBarcode('');
  };

  const onStartInspection = async () => {
    datadogRum.addAction(`다음 검수시작 버튼 클릭`);
    resetInspection();
    resetStep();
  };

  return (
    <Box>
      <Box sx={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
        <Table>
          <TableRow>
            <TableCell label="토트" value={value} />
          </TableRow>
        </Table>
      </Box>
      <Box sx={styles.bodyLeftContainer}>
        <Box sx={styles.displayFlex}>
          <Barcode value={BARCODE_SCAN_ACTION.startInspection} height={40} />
          <InvisibleInput
            value={scannedBarcode}
            onChange={e => setScannedBarcode(e.target.value)}
            onKeyDown={handleScannedBarcode}
          />
          <Button onClick={onStartInspection} size="large" variant="contained" sx={styles.nextBtn}>
            {INSPECTION.startInspection}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default BarcodeByProps;

const styles = {
  bodyLeftContainer: {
    display: 'flex',
    justifyContent: 'center',
    mb: 1,
    mt: 2,
  },
  nextBtn: {
    height: '40px',
    fontSize: '17px',
    marginLeft: '10px',
  },
  displayFlex: { display: 'flex' },
};
