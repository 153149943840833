export async function enableMocking() {
  if (process.env.NODE_ENV !== 'development' || process.env.REACT_APP_API_MOCKING !== 'enabled') {
    return;
  }

  try {
    const registrations = await navigator.serviceWorker.getRegistrations();

    if (
      registrations.some(registration =>
        registration.active?.scriptURL.includes('mockServiceWorker.js')
      )
    ) {
      console.log('MSW Service Worker already registered');
      return;
    }

    const { worker } = await import('./browser');
    await worker.start({
      onUnhandledRequest: 'bypass',
      serviceWorker: {
        // TODO: 추후 v2 prefix 가 없어지면 여기서도 제거해야 함
        url: '/v2/mockServiceWorker.js',
      },
    });

    console.log('MSW Service Worker registered successfully');
  } catch (error) {
    console.error('Service Worker registration failed:', error);
  }
}
