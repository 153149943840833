import Box from '@mui/system/Box';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import AutoContainImage from '../../../../../../components/common/Image/AutoContainImage';
import { getFinalUrl } from '../../../../../../consts/outbound/imageUrl';

const gridColumns = () => {
  const columns: GridColDef[] = [
    {
      field: 'rowIndex',
      headerName: 'NO',
      minWidth: 60,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      valueFormatter: params => {
        if (params.value === 'total') {
          return '합계';
        }

        return params.value;
      },
    },
    {
      field: 'barcode',
      headerName: '바코드',
      minWidth: 100,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'offlineBarcode',
      headerName: '물류 바코드',
      minWidth: 100,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'itemCode',
      headerName: '아이템코드',
      minWidth: 100,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'imageUrl',
      headerName: '이미지',
      minWidth: 80,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      renderCell: (params: GridRenderCellParams) =>
        params.value && (
          <Box
            sx={{ display: 'flex', alignItems: 'center', padding: 0.5, justifyContent: 'center' }}
          >
            <AutoContainImage src={getFinalUrl(params.value)} imageContainerSize={30} />
          </Box>
        ),
    },
    {
      field: 'itemName',
      headerName: '아이템명',
      minWidth: 160,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      renderCell: ({ row }) => {
        return (
          <p style={{ textAlign: 'start' }}>
            <span>{row.itemName}</span>
            <span style={{ color: 'red' }}>{row.remark ? ` => ${row.remark}` : ''}</span>
          </p>
        );
      },
    },
    {
      field: 'receivedQty',
      headerName: '입고반품수량',
      minWidth: 90,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) => value?.toLocaleString(),
    },
    {
      field: 'cost',
      headerName: '입고공급단가(원)',
      minWidth: 110,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) => value?.toLocaleString(),
    },
    {
      field: 'totalReceivedCost',
      headerName: '소계(원)',
      minWidth: 90,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) => value?.toLocaleString(),
    },
  ];
  return columns;
};

export default gridColumns;
