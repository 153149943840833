type Content = Record<string, string | number | boolean>;
type Data = {
  content: Content[];
  totalElements: number;
  size: number;
  number: number;
};

export const makeRowIndex = (data: Data) => {
  const { content: contents, totalElements, size, number } = data;

  return contents.map((item: Content, index: number) => ({
    ...item,
    rowIndex: totalElements - size * number - index,
  }));
};
