import { ChangeEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Cookies from 'js-cookie';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import { ListItem } from '@mui/material';
import Ktown4uLogo from 'components/common/Ktown4uLogo';
import { COLORS } from 'consts/common/colors';
import { Auth } from '../../store/auth.recoil';
import { axiosV2 } from '../../libs/common/fetcher';
import Table, { TableCell, TableRow } from '../gms/components/Table2';
import { FONT_SIZE } from '../../consts/common/typography';
import OutlinedInput from '@mui/material/OutlinedInput';
import usePopup from '../../hooks/usePopup';
import { update, UpdateRequest } from './services';

const loginUserKey = 'loginUser';

const ChangePassword = () => {
  const [userId, setUserId] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmNewPassword, setConfirmNewPassword] = useState<string>('');
  const { showAlert } = usePopup();
  const navigate = useNavigate();

  useEffect(() => {
    const extracted = async () => {
      return await axiosV2.get<Auth>(`/auth/me`);
    };
    const fetchData = async () => {
      const axiosResponse = await extracted();
      const authData = axiosResponse.data;
      setUserId(authData.loginId);
    };
    fetchData();
  }, []);

  const onChangePasswd = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const onChangeNewPasswd = (event: ChangeEvent<HTMLInputElement>) => {
    setNewPassword(event.target.value);
  };

  const onChangeConformNewPasswd = (event: ChangeEvent<HTMLInputElement>) => {
    setConfirmNewPassword(event.target.value);
  };

  const onPressChangePassword = async () => {
    if (password.length === 0) {
      showAlert({ message: '현재비밀번호를 입력해 주세요.' });
      return;
    }

    if (newPassword.length === 0) {
      showAlert({ message: '새비밀번호를 입력해주세요.' });
      return;
    }

    if (confirmNewPassword.length === 0) {
      showAlert({ message: '새비밀번호 확인을 입력해주세요.' });
      return;
    }

    if (newPassword !== confirmNewPassword) {
      showAlert({
        message: '새비밀번호가 새비밀번호 확인과 일치하지 않습니다.\n' + '다시 입력해 주세요.',
      });
      return;
    }

    const requestBody: UpdateRequest = {
      password,
      newPassword,
    };

    const res = await update(requestBody);
    if (res?.status !== 200) {
      showAlert({ message: res?.data?.errorMessage });
      return;
    }

    localStorage.removeItem(loginUserKey);
    Cookies.remove('accessToken');

    showAlert({
      message: '비밀번호가 변경 완료되었습니다. 다시 로그인해 주세요.',
      onClick: () => navigate('/auth/signin', { replace: true }),
    });
  };

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>케타포 GMS 비밀번호 변경</title>
        </Helmet>
      </HelmetProvider>

      <Box sx={styles.container}>
        <Paper elevation={16} sx={styles.paper}>
          <Box sx={styles.title}>
            <Ktown4uLogo width={230} marginBottom={2} />
            <span style={{ fontSize: '30px', fontWeight: '600' }}>비밀번호 재설정</span>
          </Box>
          <Notice />
          <Table>
            <TableRow sx={styles.rowHeight}>
              <TableCell label={'아이디'} labelSx={styles.label}>
                <Typography fontSize={modalFontSize} fontWeight={'bold'}>
                  {userId}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow sx={styles.rowHeight}>
              <TableCell label={'현재비밀번호'} labelSx={styles.label} required>
                <FormControl fullWidth>
                  <OutlinedInput
                    name={'password'}
                    value={password}
                    onChange={onChangePasswd}
                    sx={styles.input}
                    type={'password'}
                    autoFocus
                    autoComplete={'new-password'}
                  />
                </FormControl>
              </TableCell>
            </TableRow>
            <TableRow sx={styles.rowHeight}>
              <TableCell label={'새비밀번호'} labelSx={styles.label} required>
                <FormControl fullWidth>
                  <OutlinedInput
                    name={'new-password'}
                    value={newPassword}
                    onChange={onChangeNewPasswd}
                    sx={styles.input}
                    type={'password'}
                  />
                </FormControl>
              </TableCell>
            </TableRow>
            <TableRow sx={styles.rowHeight}>
              <TableCell label={'새비밀번호 확인'} labelSx={styles.label} required>
                <FormControl fullWidth>
                  <OutlinedInput
                    name={'confirm-new-password'}
                    value={confirmNewPassword}
                    onChange={onChangeConformNewPasswd}
                    sx={styles.input}
                    type={'password'}
                  />
                </FormControl>
              </TableCell>
            </TableRow>
          </Table>
          <Button variant="contained" size="medium" onClick={onPressChangePassword}>
            저장
          </Button>
          {/*<Button variant="contained" size="large" sx={styles.button} onClick={goToSignIn}>*/}
          {/*  뒤로*/}
          {/*</Button>*/}
        </Paper>
        <Box>
          <Typography sx={styles.copyright}>
            Copyright ⓒ (주)케이타운포유 all rights reserved.
          </Typography>
        </Box>
      </Box>
    </>
  );
};
const modalFontSize = FONT_SIZE.medium;
export default ChangePassword;

const Notice = () => (
  <List sx={styles.list}>
    <ListItem sx={styles.listItem}>
      <ListItemIcon sx={styles.listItemIcon}>-</ListItemIcon>
      <ListItemText sx={styles.listItemText} primary="비밀번호 변경 완료 후 자동 로그아웃됩니다." />
    </ListItem>
    <ListItem sx={styles.listItem}>
      <ListItemIcon sx={styles.listItemIcon}>-</ListItemIcon>
      <ListItemText
        sx={styles.listItemText}
        primary="8~20자 내 영문대문자, 영문소문자, 숫자, 특수문자만 가능합니다."
      />
    </ListItem>
    <ListItem sx={styles.listItem}>
      <ListItemIcon sx={styles.listItemIcon}>-</ListItemIcon>
      <ListItemText
        sx={styles.listItemText}
        primary="영문대문자, 영문소문자, 숫자, 특수문자 중 3가지 이상을 조합해야 합니다."
      />
    </ListItem>
    <ListItem sx={styles.listItem}>
      <ListItemIcon sx={styles.listItemIcon}>-</ListItemIcon>
      <ListItemText
        sx={styles.listItemText}
        primary="현재 사용중인 비밀번호와 다르게 설정해야 합니다."
      />
    </ListItem>
  </List>
);

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
    bgcolor: '#033971',
  },
  paper: {
    p: 5,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    mb: 2,
  },
  title: { display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' },
  input: { height: 28, width: 344, ml: -1.5, fontSize: modalFontSize },
  formControl: { mb: 1 },
  warningTextContainer: { width: '100%', height: '36px' },
  warningText: { fontSize: '13px', color: COLORS.red },
  button: { width: '100%', mb: 2, py: 2, bgcolor: COLORS.ktown4u, fontSize: 19 },
  copyright: { fontSize: '13px', color: COLORS.white },
  inputLabel: { color: COLORS.darkLine },
  list: { display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%' },
  listItem: { alignItems: 'start', padding: 0, fontSize: 15 },
  listItemIcon: {
    width: '8px',
    minWidth: '8px',
    paddingTop: '1.25px',
  },
  rowHeight: { height: 36 },
  listItemText: { margin: 0, fontSize: '15px' },
  listItemText1: { margin: 0, marginLeft: '7px', fontSize: '15px' },
  label: { width: 128, fontSize: modalFontSize },
};
