import { useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import {
  isFetchingPackingAtom,
  packingAtom,
  stepAtom,
  stopModalOpenAtom,
} from 'store/outbound/packing.recoil';
import Dialog from 'components/common/Popup/Dialog';
import usePopup from 'hooks/usePopup';
import mapPackingErrorToMessage from 'libs/outbound/mapErrorToMessage';
import { PACKING } from 'consts/outbound/messages';
import { convertCarrierId } from '../../../../../libs/common/convertCarrier';
import { carriersAtom } from '../../../../../store/outbound/carrier.recoil';
import { Packing } from '../../../../../types/outbound';

import useBarcode from '../../hooks/useBarcode';
import useApiErrorDialog from '../../hooks/useApiErrorDialog';
import useInitialization from '../hooks/useInitialization';
import BarcodeInput from '../../components/BarcodeInput';
import TimelineItem from '../../components/TimelineItem';
import Table, { TableCell, TableRow } from '../../../components/Table';
import { getPacking, updatePacking, scannedPacking } from '../services/packing';
import { BOX_STEP } from './BoxLine';
import { afterProgressTitle, inProgressTitle } from 'styles/timelineItemTitle';

const firstStep = 0;

const ToteCodeLine = () => {
  const { barcode, setBarcode, isPackingBarcodeValid } = useBarcode();
  const [selectedStep, setSelectedStep] = useRecoilState(stepAtom);
  const [isLoading, setIsLoading] = useRecoilState(isFetchingPackingAtom);
  const [packing, setPacking] = useRecoilState(packingAtom);
  const [stopModalOpen, setStopModalOpen] = useRecoilState(stopModalOpenAtom);

  const { showAlert, showSnackbar, showErrorDialog } = usePopup();
  const showApiErrorDialog = useApiErrorDialog();
  const initializeStates = useInitialization();
  const carriers = useRecoilValue(carriersAtom);

  useEffect(() => {
    if (selectedStep === firstStep) {
      // 송장 출력이 끝난 후 패킹 페이지가 초기화 될 때 바코드 삭제
      setBarcode('');
    }
  }, [selectedStep, setBarcode]);

  const handleDeliveryIdSubmit = async () => {
    if (!isPackingBarcodeValid()) {
      return;
    }
    // const toteRegex = /Tote/i;
    // const shippingSerialNumberRegex = /GC/i;
    // const delivaryIdRegex = /^\d+$/;
    // if (!toteRegex.test(barcode) && !delivaryIdRegex.test(barcode)) {
    //   showErrorDialog({
    //     title: '포장 불가',
    //     errorMessage: '토트 바코드 또는 출고ID를 입력해주세요.',
    //     top: '개별포장 작업이 가능한 바코드 번호가 아닙니다. 책임자에게 문의해주세요.',
    //     buttons: [{ text: '확인' }],
    //   });
    //   setBarcode('');
    //   return;
    // }
    setIsLoading(true);
    // 포장 조회
    const response = await getPacking(barcode);
    if (response?.status !== 200) {
      const errorMessage = response?.data.errorMessage ?? '알수 없는 에러';
      await updatePackingErrorStatus(errorMessage, undefined);
      return;
    }
    const packing: Packing = response?.data;
    // 포장 상태 업데이트
    const updateResponse = await scannedPacking({ id: packing.id, status: 'PROCESSING' });
    if (updateResponse?.status !== 200) {
      const errorMessage = updateResponse?.data.errorMessage ?? '알수 없는 에러';
      await updatePackingErrorStatus(errorMessage, packing);
      return;
    }
    setPacking({ ...packing, status: 'PROCESSING' });
    setSelectedStep(BOX_STEP);
    setIsLoading(false);
  };

  const updatePackingErrorStatus = async (errorMessage: string, packing: Packing | undefined) => {
    if (errorMessage.includes('환불')) {
      await updatePackingStatusToRefundError(packing as Packing);
      showErrorDialog({
        title: '환불이력 출고건',
        errorMessage:
          '환불이력 출고건으로, ‘포장중지‘ 처리 되었습니다.\n' + '담당 매니저를 호출해 주세요.',
        top: PACKING.unavailableBarcode,
        buttons: [{ text: '확인' }],
      });
    } else {
      showErrorDialog({
        title: '포장 불가',
        errorMessage: errorMessage,
        top: PACKING.unavailableBarcode,
        buttons: [{ text: '확인' }],
      });
    }
    setBarcode('');
    setIsLoading(false);
  };

  const updatePackingStatusToRefundError = async (packing: Packing) => {
    if (!packing) {
      showAlert({ message: '패킹 정보 없음' });
      return;
    }
    try {
      await updatePacking({
        id: packing.id,
        status: 'ERROR',
        errorCode: '포장오류',
        errorMessage: '환불이력 존재.',
      });
      initializeStates();
    } catch (error) {
      const errorMessage = mapPackingErrorToMessage(error);
      showApiErrorDialog(errorMessage, PACKING.statusChangeFailure);
    }
  };

  const updatePackingStatusToError = async () => {
    if (!packing) {
      showAlert({ message: '패킹 정보 없음' });
      return;
    }

    try {
      await updatePacking({
        id: packing.id,
        status: 'ERROR',
        errorCode: '포장 중지',
        errorMessage: '작업자가 직접 포장을 중지함',
      });
      showSnackbar({ message: PACKING.changeToErrorStatus, severity: 'error' });
      initializeStates();
    } catch (error) {
      const errorMessage = mapPackingErrorToMessage(error);
      showApiErrorDialog(errorMessage, PACKING.statusChangeFailure);
    }
  };

  return (
    <>
      <TimelineItem
        step={0}
        inProgressComponent={
          <>
            <Typography sx={inProgressTitle}>{PACKING.toteCodeScan}</Typography>
            <BarcodeInput
              value={barcode}
              onSubmit={handleDeliveryIdSubmit}
              onChange={e => setBarcode(e.target.value)}
              label="바코드"
              isLoading={isLoading}
            />
          </>
        }
        afterProgressComponent={
          <>
            <Box sx={styles.titleContainer}>
              <Typography sx={afterProgressTitle}>{PACKING.toteCodeScan}</Typography>
              <Box sx={styles.barcodeButtonContainer}>
                <Button
                  onClick={() => setStopModalOpen(true)}
                  size="large"
                  variant="contained"
                  sx={styles.button}
                >
                  포장중지
                </Button>
              </Box>
            </Box>
            <Table>
              <TableRow>
                <TableCell sx={styles.tableCell} label="토트" value={barcode} />
                <TableCell sx={styles.tableCell} label="출고ID" value={packing?.serialNumber} />
              </TableRow>
              <TableRow>
                <TableCell
                  sx={styles.tableCell}
                  label="배송방식"
                  value={convertCarrierId(packing?.carrierId, carriers)}
                />
                <TableCell
                  sx={styles.tableCell}
                  label="최대무게제한(g)"
                  value={packing?.maxWeight?.toLocaleString()}
                />
              </TableRow>
              <TableRow>
                <TableCell sx={styles.tableCell} label="아이템수량" value={packing?.totalQty} />
                <TableCell
                  sx={styles.tableCell}
                  label="예상중량무게(g)"
                  value={packing?.estimatedWeight.toLocaleString()}
                />
              </TableRow>
              <TableRow>
                <TableCell
                  sx={styles.tableCell}
                  label="지관통 유무"
                  value={packing?.isTube === true ? 'O' : 'X'}
                />
                <TableCell
                  sx={styles.tableCell}
                  label="증정품 유무"
                  value={packing?.isGift === true ? 'O' : 'X'}
                />
              </TableRow>
            </Table>
          </>
        }
      />

      <Dialog
        open={stopModalOpen}
        buttons={[
          { text: '취소' },
          {
            text: '확인',
            onClick: () => {
              updatePackingStatusToError();
              setBarcode('');
            },
            marked: true,
          },
        ]}
        onClose={() => setStopModalOpen(false)}
      >
        {PACKING.stopConfirm}
      </Dialog>
    </>
  );
};

export default ToteCodeLine;

const styles = {
  titleContainer: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' },
  barcodeButtonContainer: { display: 'flex', alignItems: 'center', gap: '8px' },
  button: { height: '40px', fontSize: '17px' },
  tableCell: { fontSize: '16px' },
};
